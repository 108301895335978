import { Map, fromJS, List } from "immutable";
import { inputFormatConstants } from "../constants/constants";
import administrationConstants from "../constants/administrationConstants";
import tempAdministrationConstants from "../constants/tempAdministrationConstants";
import { customAdjustmentConstants } from "../components/administration/automatisation-constants/single-view/helper";

/**
 *  Administration reducer redux
 */

const initialState = Map({
	actionModal: false,
	requestModal: false,
	action: false,
	request: false,
	errorMessage: "",
	successMessage: "",
	types: Map(),
	inputFormat: Map(),
	deliveryFormats: Map(),
	deliveryFormat: Map(),
	disciplines: Map(),
	projectLods: Map(),
	projectLodValues: List(),
	buildingComponents: Map(),
	repetitivenessAdjustments: Map(),
	modelingData: Map(),
	modelingTimes: Map(),
	lodAdjustments: Map(),
	priceAdjustment: Map(),
	normalisationManuals: Map(),
	customisationManuals: Map(),
	qaManuals: Map(),
	packageCategories: Map(),
	termsAndConditions: Map(),
	specificationParameters: Map(),
	specificationCategories: Map(),
	componentCategoryContent: Map(),
	families: Map(),
	symbolFunctions: Map(),
	customisationTime: Map(),
	exportsAdjustment: Map(),
	tempExportsAdjustment: Map(),
	servicesAdjustment: Map(),
	tempServicesAdjustment: Map(),
	DXFServerSettings: Map(),
	DXFModelProperties: List(),
	disciplineComponents: Map(),
	revitTemplate: Map(),
	country: List(),
	getFailure: "",
});

export const getAdjustmentTypeAndValue = (item) => {
	let type = null;
	let value = null;

	if (item.percentageAdjustment) {
		type = customAdjustmentConstants.percentage;
		value = item.percentageAdjustment;
	} else if (item.timeAdjustment) {
		type = customAdjustmentConstants.time;
		value = item.timeAdjustment;
	} else if (item.velocityAdjustment) {
		type = customAdjustmentConstants.velocity;
		value = item.velocityAdjustment;
	}

	return { type, value };
};

export const getAdjustmentsByInputFormat = (inputFormat) => {
	switch (inputFormat) {
		case inputFormatConstants.CAD:
			return "cadAdjustment";
		case inputFormatConstants.POINT_CLOUD:
			return "pointCloudAdjustment";
		case inputFormatConstants.IMAGE:
			return "imageAdjustment";

		default:
			break;
	}
};

export const getTimeAdjustmentByInputFormat = (inputFormat) => {
	switch (inputFormat) {
		case inputFormatConstants.CAD:
			return "cadTimeAdjustment";
		case inputFormatConstants.POINT_CLOUD:
			return "pointCloudTimeAdjustment";
		case inputFormatConstants.IMAGE:
			return "imageTimeAdjustment";

		default:
			break;
	}
};

export default (state = initialState, action) => {
	const getModeling = ({ disciplines, modelingData }) => {
		const tempModeling = [];

		disciplines &&
			disciplines.map((discipline) => {
				const disciplineId = discipline?.id;
				const disciplineComponentCategories =
					discipline?.disciplineComponentCategories;

				return (
					disciplineComponentCategories &&
					disciplineComponentCategories.map((dcc) => {
						let data = {};
						modelingData &&
							modelingData.map((modeling) => {
								const mpDisciplineId =
									modeling?.disciplineComponentCategory?.discipline?.id;
								const mpComponentCategoryId =
									modeling?.disciplineComponentCategory?.componentCategory?.id;

								if (
									mpDisciplineId === disciplineId &&
									mpComponentCategoryId === dcc?.componentCategory?.id
								) {
									data[
										`${getAdjustmentsByInputFormat(modeling?.inputFormat.type)}`
									] = modeling?.adjustment;
									data[
										`${getTimeAdjustmentByInputFormat(
											modeling?.inputFormat.type
										)}`
									] = modeling?.timeAdjustment;

									data[`type_${modeling?.inputFormat.id}`] =
										modeling?.inputFormat.type;

									return data;
								}
								return "";
							});

						return tempModeling.push({
							id: dcc.id,
							category: dcc?.componentCategory?.type,
							discipline: discipline?.type,
							...data,
						});
					})
				);
			});

		// if (timeModeling) {
		// 	return state.set("modelingTimes", fromJS(tempModeling));
		// }

		return state.set("modelingData", fromJS(tempModeling));
	};

	const getBuildingCategoryAdjustments = (buildingCategoryAdjustments) => {
		const tempPriceAdjustment = [];

		buildingCategoryAdjustments &&
			buildingCategoryAdjustments.map((buildingCategoryAdjustment) => {
				const adjustments =
					buildingCategoryAdjustment?.buildingTypeInputFormatAdjustments;

				// let data = {};

				// adjustments &&
				// 	adjustments.map((item) => {
				// 		data["adjustment"] = +item?.adjustment * 100;

				// 		return data;
				// 	});

				return tempPriceAdjustment.push({
					id: buildingCategoryAdjustment.id,
					type: buildingCategoryAdjustment.type,
					adjustment: +adjustments[0]?.adjustment * 100 || 0,
					timeAdjustment: +adjustments[0]?.timeAdjustment * 100 || 0,
				});
			});

		return state.set(
			"buildingCategoryAdjustments",
			fromJS(tempPriceAdjustment)
		);
	};

	const getLODAdjustments = (lodAdjustments) => {
		const tempLODAdjustment = [];

		lodAdjustments &&
			lodAdjustments.map((lodAdjustment) => {
				const adjustments = lodAdjustment?.lodInputFormatAdjustments;

				//let data = {};

				// adjustments &&
				// 	adjustments.map((item) => {
				// 		data["adjustment"] = +item?.adjustment * 100;

				// 		return data;
				// 	});

				return tempLODAdjustment.push({
					id: lodAdjustment.id,
					type: lodAdjustment.type,
					adjustment: +adjustments[0]?.adjustment * 100,
					timeAdjustment: +adjustments[0]?.timeAdjustment * 100,
				});
			});

		return state.set("lodAdjustments", fromJS(tempLODAdjustment));
	};

	//---------------------COMPONENT_CATEGORY_CONTENT------------------------

	const getComponentCategoryContent = (componentCategoryContent) => {
		const { componentCategory, disciplineComponentCategoryLodsDescription } =
			componentCategoryContent;

		//Filter and sort lods
		let disciplineComponentCategoryLodsDescriptionSorted =
			disciplineComponentCategoryLodsDescription
				.filter((item) => item?.lod && +item?.lod?.type !== +0)
				.sort((a, b) => a.lod?.sortOrder - b.lod?.sortOrder);

		let images3D = [];
		let images2D = [];
		let summaries = [];

		let lodParameterCategories = [];

		const parameterCategories =
			disciplineComponentCategoryLodsDescriptionSorted[0]
				?.parameterCategories || [];

		//Set all categories
		for (const pc of parameterCategories) {
			if (!pc?.parameterCategory?.parent && !!pc?.parameterCategory) {
				lodParameterCategories.push({
					id: pc?.parameterCategory.id,
					name: pc?.parameterCategory.name,
					sortOrder: pc?.parameterCategory.sortOrder,
					lodParameters: [],
				});
			}
		}
		lodParameterCategories = lodParameterCategories?.sort(
			(a, b) => a.sortOrder - b.sortOrder
		);

		let lods = [];
		let disciplineComponentCategoryLodsDescriptionIds = [];

		//---------------------------------------------
		disciplineComponentCategoryLodsDescriptionSorted &&
			disciplineComponentCategoryLodsDescriptionSorted.forEach((item) => {
				if (item.lod && +item.lod?.type !== +0) {
					disciplineComponentCategoryLodsDescriptionIds.push({
						id: item.id,
						lodId: item.lod.id,
					});

					//Set all 3d images
					images3D.push({
						id: item.id,
						lodId: item.lod.id,
						lodType: item.lod.type,
						sortOrder: item.lod.sortOrder,
						value: item.threeDFile?.publicUrl || null,
						fileId: item.threeDFile?.id || null,
						tempFileId: item.threeDFile?.id || null,
					});

					//Set all 2d images
					images2D.push({
						id: item.id,
						lodId: item.lod.id,
						sortOrder: item.lod.sortOrder,
						lodType: item.lod.type,
						value: item.twoDFile?.publicUrl || null,
						fileId: item.twoDFile?.id || null,
						tempFileId: item.twoDFile?.id || null,
					});

					//Set all 2d summaries
					summaries.push({
						id: item.id,
						lodId: item.lod.id,
						lodType: item.lod.type,
						sortOrder: item.lod.sortOrder,
						summary: item?.description || null,
					});

					const parameterCategories =
						item?.parameterCategories?.sort(
							(a, b) => a.sortOrder - b.sortOrder
						) || [];

					//Set all children parameter categories
					parameterCategories &&
						parameterCategories.forEach((pc) => {
							if (!!pc.parameterCategory?.parent && !!pc?.parameterCategory) {
								lods.push({
									id: pc.id,
									disciplineComponentCategoryLodDescriptionId: item.id,
									txtValue: pc.txtValue,
									boolValue: pc.boolValue,
									parameterCategoryId: pc.parameterCategory.id,
									parameterCategoryName: pc.parameterCategory.name,
									inputFieldType: pc.parameterCategory.inputFieldType,
									parameterCategoryParentId: pc.parameterCategory.parent?.id,
									lodId: item.lod.id,
									sortOrder: pc.sortOrder,
									lodType: item.lod.type,
								});
							}
						});
				}
			});

		//Go through all categories and set all parameters and lods
		lodParameterCategories.forEach((lpc) => {
			let parameters = [];
			let lodParameters = [];

			//Filter all lods by category and sort them
			const filter = lods
				.filter((l) => l.parameterCategoryParentId === lpc.id)
				?.sort((a, b) => a.sortOrder - b.sortOrder);

			//Set all parameters
			filter &&
				filter.forEach((f) => {
					if (!parameters.includes(f.parameterCategoryId))
						parameters.push(f.parameterCategoryId);
				});

			//Go through all parameters and set all lods
			parameters &&
				parameters.forEach((p) => {
					const filterParameters = filter?.filter(
						(item) => item.parameterCategoryId === p
					);
					let values = {
						name: filterParameters[0]?.parameterCategoryName,
						id: filterParameters[0]?.parameterCategoryId,
						deleteId: filterParameters[0]?.id,
						lods: [],
					};

					//Set lods for each parameter
					let tempLods = [];
					filterParameters &&
						filterParameters.forEach((fp) => {
							tempLods.push({
								disciplineComponentCategoryLodDescriptionId:
									fp.disciplineComponentCategoryLodDescriptionId,
								txtValue: fp.txtValue,
								boolValue: fp.boolValue,
								lodId: fp.lodId,
								lodType: fp.lodType,
								inputFieldType: fp.inputFieldType,
								id: fp.id,
							});
						});

					//set all lods to parameter
					values.lods = tempLods;

					lodParameters.push(values);
				});
			//set all parameters to category
			lpc.lodParameters = lodParameters;
		});

		let lodContent = { images3D, images2D, summaries };

		return state
			.setIn(
				["componentCategoryContent", "componentCategory"],
				fromJS(componentCategory)
			)
			.setIn(["componentCategoryContent", "lodContent"], fromJS(lodContent))
			.setIn(
				["componentCategoryContent", "lodParameterCategories"],
				fromJS(lodParameterCategories)
			)
			.setIn(
				[
					"tempComponentCategoryContent",
					"disciplineComponentCategoryLodsDescriptionIds",
				],
				fromJS(disciplineComponentCategoryLodsDescriptionIds)
			)
			.setIn(["tempComponentCategoryContent", "lodContent"], fromJS(lodContent))
			.setIn(
				["tempComponentCategoryContent", "lodParameterCategories"],
				fromJS(lodParameterCategories)
			);
	};

	//---------------------------------------------

	const addNewTempParameter = (data) => {
		const { categoryId, parameters } = data;
		const lods = state.get("lods");
		const specificationParameters = state.get("specificationParameters");
		const disciplineComponentCategoryLodsDescriptionIds =
			state
				.getIn([
					"tempComponentCategoryContent",
					"disciplineComponentCategoryLodsDescriptionIds",
				])
				?.toJS() || [];

		const findSpecificationParameters =
			specificationParameters &&
			specificationParameters.filter((sp) => parameters.includes(sp.get("id")));

		const tempLodParameterCategories =
			state
				.getIn(["tempComponentCategoryContent", "lodParameterCategories"])
				?.toJS() || null;

		const categoryIndex =
			tempLodParameterCategories &&
			tempLodParameterCategories.findIndex((lpc) => lpc.id === categoryId);

		const lodParameters =
			tempLodParameterCategories[categoryIndex]?.lodParameters;

		let tempLODParameters = lodParameters;
		let tempLods = [];

		//Go through all parameters
		findSpecificationParameters &&
			findSpecificationParameters.map((sp) => {
				tempLods = [];

				//Go through all lods and set them to parameter
				lods &&
					lods.forEach((lod) => {
						if (+lod.get("type") !== +0) {
							//Find VALUES from disciplineComponentCategoryLodsDescriptionIds for specific lod
							const findLOD =
								disciplineComponentCategoryLodsDescriptionIds.find(
									(fl) => +fl.lodId === +lod.get("id")
								);

							tempLods.push({
								txtValue: null,
								boolValue: null,
								disciplineComponentCategoryLodDescriptionId: findLOD
									? findLOD?.id
									: null,
								inputFieldType: sp.get("inputFieldType"),
								id: null,
								lodId: lod.get("id"),
								lodType: lod.get("type"),
							});
						}
					});
				return tempLODParameters.push({
					id: sp.get("id"),
					name: sp.get("name"),
					deleteId: null,
					lods: tempLods,
				});
			});

		return state.setIn(
			[
				"tempComponentCategoryContent",
				"lodParameterCategories",
				categoryIndex,
				"lodParameters",
			],
			fromJS(tempLODParameters)
		);
	};

	const removeTempParameter = (data) => {
		const { parameterId, categoryId } = data;

		const tempLodParameterCategories =
			state
				.getIn(["tempComponentCategoryContent", "lodParameterCategories"])
				?.toJS() || null;

		const lodParameterCategories =
			state
				.getIn(["componentCategoryContent", "lodParameterCategories"])
				?.toJS() || null;

		const tempCategoryIndex =
			tempLodParameterCategories &&
			tempLodParameterCategories.findIndex((lpc) => lpc.id === categoryId);

		const categoryIndex =
			tempLodParameterCategories &&
			tempLodParameterCategories.findIndex((lpc) => lpc.id === categoryId);

		const tempLodParameters =
			tempLodParameterCategories[tempCategoryIndex]?.lodParameters;

		const lodParameters = lodParameterCategories[categoryIndex]?.lodParameters;

		const filterTempLodParameters = tempLodParameters.filter(
			(p) => p.id !== parameterId
		);

		const filterLodParameters = lodParameters.filter(
			(p) => p.id !== parameterId
		);

		return state
			.setIn(
				[
					"tempComponentCategoryContent",
					"lodParameterCategories",
					tempCategoryIndex,
					"lodParameters",
				],
				fromJS(filterTempLodParameters)
			)
			.setIn(
				[
					"componentCategoryContent",
					"lodParameterCategories",
					categoryIndex,
					"lodParameters",
				],
				fromJS(filterLodParameters)
			);
	};

	const resetLodParameterCategories = () => {
		const lodParameterCategories = state.getIn([
			"componentCategoryContent",
			"lodParameterCategories",
		]);

		return state.setIn(
			["tempComponentCategoryContent", "lodParameterCategories"],
			fromJS(lodParameterCategories)
		);
	};

	const updateLodParameterCategories = (data) => {
		const { fieldKey, value, lodId, parameterId, categoryId } = data;

		const tempLodParameterCategories =
			state
				.getIn(["tempComponentCategoryContent", "lodParameterCategories"])
				?.toJS() || null;

		const categoryIndex =
			tempLodParameterCategories &&
			tempLodParameterCategories.findIndex((lpc) => lpc.id === categoryId);

		const lodParameters =
			tempLodParameterCategories[categoryIndex]?.lodParameters;

		const lodParametersIndex =
			lodParameters && lodParameters.findIndex((lp) => lp.id === parameterId);

		const parameterLods = lodParameters[lodParametersIndex]?.lods;

		const lodIndex =
			parameterLods && parameterLods.findIndex((lod) => lod.lodId === lodId);

		return state.setIn(
			[
				"tempComponentCategoryContent",
				"lodParameterCategories",
				categoryIndex,
				"lodParameters",
				lodParametersIndex,
				"lods",
				lodIndex,
				fieldKey,
			],
			value
		);
	};

	const sortLodParameterCategories = (data) => {
		const { dragId, overId, categoryId } = data;

		const lodParameterCategories = state.getIn([
			"tempComponentCategoryContent",
			"lodParameterCategories",
		]);
		let tempLODParameterCategories = lodParameterCategories
			? lodParameterCategories?.toJS()
			: [];

		const categoryIndex =
			tempLODParameterCategories &&
			tempLODParameterCategories.findIndex((lpc) => lpc.id === categoryId);

		const tempLODParameters =
			tempLODParameterCategories[categoryIndex]?.lodParameters;

		const findIndexOver = tempLODParameters.findIndex(
			(lodParameter) => lodParameter?.id === overId
		);
		const findIndexDrag = tempLODParameters.findIndex(
			(lodParameter) => lodParameter?.id === dragId
		);

		if (findIndexDrag !== -1 && findIndexOver !== -1)
			if (findIndexOver > findIndexDrag) {
				tempLODParameters.splice(
					findIndexOver + 1,
					0,
					tempLODParameters[findIndexDrag]
				);
				tempLODParameters.splice(findIndexDrag, 1);
			} else if (findIndexOver < findIndexDrag) {
				tempLODParameters.splice(
					findIndexOver,
					0,
					tempLODParameters[findIndexDrag]
				);
				tempLODParameters.splice(findIndexDrag + 1, 1);
			}

		return state.setIn(
			[
				"tempComponentCategoryContent",
				"lodParameterCategories",
				categoryIndex,
				"lodParameters",
			],
			fromJS(tempLODParameters)
		);
	};

	const setContentImageTempFile = (data) => {
		const { publicUrl, id, fileId, threeDFile, twoDFile } = data;

		const tempLodContent =
			state.getIn(["tempComponentCategoryContent", "lodContent"])?.toJS() ||
			null;

		const images3D = tempLodContent?.images3D;
		const images2D = tempLodContent?.images2D;
		let findIndex = null;
		let content = null;

		if (threeDFile) {
			findIndex = images3D.findIndex((i) => i.id === id);
			content = "images3D";
		}

		if (twoDFile) {
			findIndex = images2D.findIndex((i) => i.id === id);
			content = "images2D";
		}

		return state
			.setIn(
				[
					"tempComponentCategoryContent",
					"lodContent",
					content,
					findIndex,
					"value",
				],
				publicUrl
			)
			.setIn(
				[
					"tempComponentCategoryContent",
					"lodContent",
					content,
					findIndex,
					"fileId",
				],
				fileId
			);
	};

	const deleteContentTempImage = (data) => {
		const { lodId, threeDFile, twoDFile } = data;

		const tempLodContent =
			state.getIn(["tempComponentCategoryContent", "lodContent"])?.toJS() ||
			null;

		const images3D = tempLodContent?.images3D;
		const images2D = tempLodContent?.images2D;
		let findIndex = null;
		let content = null;

		if (threeDFile) {
			findIndex = images3D.findIndex((i) => i.lodId === lodId);
			content = "images3D";
		}

		if (twoDFile) {
			findIndex = images2D.findIndex((i) => i.lodId === lodId);
			content = "images2D";
		}

		return state
			.setIn(
				[
					"tempComponentCategoryContent",
					"lodContent",
					content,
					findIndex,
					"value",
				],
				null
			)
			.setIn(
				[
					"tempComponentCategoryContent",
					"lodContent",
					content,
					findIndex,
					"fileId",
				],
				null
			)
			.setIn(
				[
					"tempComponentCategoryContent",
					"lodContent",
					content,
					findIndex,
					"file",
				],
				null
			);
	};

	const resetTempLodContent = () => {
		const lodContent = state.getIn(["componentCategoryContent", "lodContent"]);

		return state.setIn(
			["tempComponentCategoryContent", "lodContent"],
			fromJS(lodContent)
		);
	};

	const resetLodContent = () => {
		const lodContent = state.getIn([
			"tempComponentCategoryContent",
			"lodContent",
		]);

		return state.setIn(
			["componentCategoryContent", "lodContent"],
			fromJS(lodContent)
		);
	};

	const updateTempLodContentSummary = (data) => {
		const { lodId, summary } = data;

		const tempLodContent =
			state.getIn(["tempComponentCategoryContent", "lodContent"])?.toJS() ||
			null;

		const summaries = tempLodContent?.summaries;
		const findIndex = summaries.findIndex((i) => i.lodId === lodId);

		return state.setIn(
			[
				"tempComponentCategoryContent",
				"lodContent",
				"summaries",
				findIndex,
				"summary",
			],
			summary
		);
	};

	//------------------------------------------------------------------------

	//----------------------------CUSTOMISATION TIME--------------------------

	const setCustomisationTimeByInputFormat = (data) => {
		const { inputFormatId, result } = data;

		const newResult =
			result &&
			result.map((item) => {
				return {
					id: item.id,
					componentCategory:
						item.disciplineComponentCategory?.componentCategory?.type || null,
					componentCategoryId:
						item.disciplineComponentCategory?.componentCategory?.id || null,
					discipline:
						item.disciplineComponentCategory?.discipline?.type || null,
					disciplineId:
						item.disciplineComponentCategory?.discipline?.id || null,
					lodAdjustments:
						item.lodAdjustments.map((lod) => {
							return {
								...lod,
								adjustment: lod.adjustment * 100,
							};
						}) || [],
				};
			});

		return state
			.setIn(
				["customisationTime", `inputFormat_${inputFormatId}`],
				fromJS(newResult)
			)
			.setIn(
				["tempCustomisationTime", `inputFormat_${inputFormatId}`],
				fromJS(newResult)
			);
	};

	const updateTempCustomisationTimeByInputFormat = (data) => {
		const { inputFormatId, disciplineId, categoryId, lodId, value } = data;
		const customisationTime = state.getIn([
			"tempCustomisationTime",
			`inputFormat_${inputFormatId}`,
		]);

		const findIndex = customisationTime.findIndex(
			(item) =>
				item.get("disciplineId") === disciplineId &&
				item.get("componentCategoryId") === categoryId
		);
		const lodAdjustments = customisationTime.getIn([
			findIndex,
			"lodAdjustments",
		]);
		const findLodIndex = lodAdjustments.findIndex(
			(item) => item.get("lodId") === lodId
		);

		return state.setIn(
			[
				"tempCustomisationTime",
				`inputFormat_${inputFormatId}`,
				findIndex,
				"lodAdjustments",
				findLodIndex,
				"adjustment",
			],
			value
		);
	};

	const resetTempCustomisationTimeByInputFormat = (data) => {
		const { inputFormatId } = data;

		return state.setIn(
			["tempCustomisationTime", `inputFormat_${inputFormatId}`],
			state.getIn(["customisationTime", `inputFormat_${inputFormatId}`])
		);
	};

	//------------------------------------------------------------------------

	//----------------------------EXPORTS ADJUSTMENT--------------------------
	const setExportsAdjustmentByInputFormat = (data) => {
		let newResult = {};

		data &&
			data.map((item) => {
				const { type, value } = getAdjustmentTypeAndValue(item);

				if (newResult[item?.export?.id]) {
					newResult[item?.export?.id].inputFormats.push({
						id: item?.id || null,
						inputFormatId: item?.inputFormat?.id || null,
						inputFormatType: item?.inputFormat?.type || null,
						value,
					});
				} else {
					newResult[item?.export?.id] = {
						exportId: item?.export?.id,
						exportType: item?.export?.type || null,
						type,
						inputFormats: [
							{
								id: item?.id || null,
								inputFormatId: item?.inputFormat?.id || null,
								inputFormatType: item?.inputFormat?.type || null,
								value,
							},
						],
					};
				}

				return newResult;
			});

		return state
			.set("exportsAdjustment", fromJS(newResult))
			.set("tempExportsAdjustment", fromJS(newResult));
	};

	const updateTempExportsAdjustmentInputFormat = (data) => {
		const { fieldKey, id = null, value, exportId } = data;

		const inputFormats = state.getIn([
			"tempExportsAdjustment",
			String(exportId),
			"inputFormats",
		]);

		if (!!id) {
			const findIndex =
				inputFormats &&
				inputFormats.findIndex((item) => +item.get("id") === +id);

			return state.setIn(
				[
					"tempExportsAdjustment",
					String(exportId),
					"inputFormats",
					findIndex,
					fieldKey,
				],
				value
			);
		}
		return state.setIn(
			["tempExportsAdjustment", String(exportId), fieldKey],
			value
		);
	};

	const resetTempExportsAdjustmentInputFormat = () => {
		return state.set("tempExportsAdjustment", state.get("exportsAdjustment"));
	};

	//------------------------------------------------------------------------

	//----------------------------SERVICES ADJUSTMENT--------------------------
	const setServicesAdjustmentInputFormat = (data) => {
		let newResult = {};

		data &&
			data.map((item) => {
				const { type, value } = getAdjustmentTypeAndValue(item);

				if (newResult[item?.manual?.id]) {
					newResult[item?.manual?.id].inputFormats.push({
						id: item?.id || null,
						inputFormatId: item?.inputFormat?.id || null,
						inputFormatType: item?.inputFormat?.type || null,
						value,
					});
				} else {
					newResult[item?.manual?.id] = {
						manualId: item?.manual?.id,
						manualTitle: item?.manual?.title || null,
						type,
						inputFormats: [
							{
								id: item?.id || null,
								inputFormatId: item?.inputFormat?.id || null,
								inputFormatType: item?.inputFormat?.type || null,
								value,
							},
						],
					};
				}

				return newResult;
			});

		return state
			.set("servicesAdjustment", fromJS(newResult))
			.set("tempServicesAdjustment", fromJS(newResult));
	};

	const updateTempServicesAdjustmentByInputFormat = (data) => {
		const { fieldKey, id = null, value, manualId } = data;

		const inputFormats = state.getIn([
			"tempServicesAdjustment",
			String(manualId),
			"inputFormats",
		]);

		if (!!id) {
			const findIndex =
				inputFormats &&
				inputFormats.findIndex((item) => +item.get("id") === +id);

			return state.setIn(
				[
					"tempServicesAdjustment",
					String(manualId),
					"inputFormats",
					findIndex,
					fieldKey,
				],
				value
			);
		}
		return state.setIn(
			["tempServicesAdjustment", String(manualId), fieldKey],
			value
		);
	};

	const resetTempServicesAdjustmentByInputFormat = () => {
		return state.set("tempServicesAdjustment", state.get("servicesAdjustment"));
	};

	//------------------------------------------------------------------------

	//----------------------------DXF SERVER---------------------------------

	/**
	 * GET DISCIPLINE COMPONENT CATEGORIES
	 *
	 * @param {object} data - response data
	 * @param {number} data.disciplineId - unique discipline identifier
	 * @param {array} data.result - response data
	 */
	const getAllDisciplineComponents = (data) => {
		const { disciplineId, result } = data;

		return state.setIn(
			["disciplineComponents", `discipline_${disciplineId}`, "componentsList"],
			fromJS(result)
		);
	};

	/**
	 * GET DXF server components per discipline
	 *
	 * @param {object} data - response data
	 * @param {number} data.disciplineId - unique discipline identifier
	 * @param {array} data.result - response data
	 */
	const getDXFServerComponents = (data) => {
		const { disciplineId, result } = data;

		const components = [];

		result &&
			result.map((item) => {
				components.push({
					id: item.disciplineComponentCategory?.id || null,
					componentCategory:
						item.disciplineComponentCategory?.componentCategory?.type || null,
					componentCategoryId:
						item.disciplineComponentCategory?.componentCategory?.id || null,
				});
				return components;
			});

		return state.setIn(
			["DXFServerSettings", `discipline_${disciplineId}`, "components"],
			fromJS(components)
		);
	};

	/**
	 * GET DXF server component parameters
	 *
	 * @param {object} data - response data
	 * @param {number} data.disciplineId - unique discipline identifier
	 * @param {number} data.componentId - unique component identifier
	 * @param {array} data.result - response data
	 */
	const getDXFServerComponentParameters = (data) => {
		const { disciplineId, componentId, result } = data;

		const componentParameters = [];

		result &&
			result.map((item) => {
				const id = item?.parameterCategory?.id || null;
				const name = item?.parameterCategory?.name || null;
				const dxModelProperties = item?.dxModelProperties || [];

				const parameterOptions = [];

				//Get all parameter options for each parameter (Min, Max, etc.)
				dxModelProperties.map((dxfModel) => {
					parameterOptions.push({
						optionId: dxfModel?.id,
						id: dxfModel?.dxfModelProperty?.id,
						value: dxfModel?.value,
						type: dxfModel?.dxfModelProperty?.name,
					});

					return parameterOptions;
				});

				//sort parameterOptions min, max, etc.
				parameterOptions.sort((a, b) => {
					return -a.type.localeCompare(b.type);
				});

				//Set all parameters with options
				componentParameters.push({
					id,
					parameter: name,
					parameterOptions: parameterOptions,
				});

				return componentParameters;
			});

		return state.setIn(
			[
				"DXFServerSettings",
				`discipline_${disciplineId}`,
				`componentParameters_${componentId}`,
			],
			fromJS(componentParameters)
		);
	};

	//------------------------------------------------------------------------

	switch (action.type) {
		case administrationConstants.ACTION_GET_FAILURE:
			return state.set("getFailure", fromJS(action.data));

		case administrationConstants.GET_BUILDING_TYPE_CHILDRENS:
			return state.set("buildingTypeChildrens", fromJS(action.data));

		case administrationConstants.GET_BUILDING_TYPE_PARENTS:
			return state.set("buildingTypeParents", fromJS(action.data));

		case administrationConstants.GET_BUILDING_TYPES:
			const types = action.data;
			const buildingTypes = [];
			types &&
				types.forEach((type) => {
					if (type.children.length > 0) {
						buildingTypes.push({
							id: type.id,
							type: type.type,
							children: type.children,
						});
					}
				});
			return state.set("types", fromJS(buildingTypes));

		case administrationConstants.GET_DRAWING_REFERENCES:
			return state.set("inputFormat", fromJS(action.data));

		case administrationConstants.GET_SETTINGS:
			return state.set("settings", fromJS(action.data));

		case administrationConstants.GET_DELIVERY_FORMATS:
			return state.set("deliveryFormats", fromJS(action.data));

		case administrationConstants.GET_DELIVERY_FORMAT:
			return state.set("deliveryFormat", fromJS(action.data));

		case administrationConstants.GET_LODS:
			return state.set("lods", fromJS(action.data));

		case administrationConstants.GET_DISCIPLINES:
			return state.set("disciplines", fromJS(action.data));

		case administrationConstants.GET_EXPORTS:
			return state.set("exports", fromJS(action.data));

		case administrationConstants.GET_BUILDING_COMPONENTS:
			return state.set("buildingComponents", fromJS(action.data));

		case administrationConstants.GET_MODELING_DATA:
			const { modelingPrices } = action.data;

			return getModeling({
				disciplines: action.data?.disciplines,
				modelingData: modelingPrices,
			});

		case administrationConstants.GET_LOD_ADJUSTMENTS:
			return getLODAdjustments(action.data);
		// case administrationConstants.GET_LOD_TIME_ADJUSTMENTS:
		// 	return getLODAdjustments(action.data, true);

		case administrationConstants.GET_BUILDING_CATEGORY_ADJUSTMENTS:
			return getBuildingCategoryAdjustments(action.data);
		// case administrationConstants.GET_BUILDING_CATEGORY_TIME_ADJUSTMENTS:
		// 	return getBuildingCategoryAdjustments(action.data, true);

		case administrationConstants.GET_NORMALISATION_MANUAL:
			return state.set("normalisationManuals", fromJS(action.data));

		case administrationConstants.GET_REPETITIVENESS_ADJUSTMENTS:
			return state.set("repetitivenessAdjustments", fromJS(action.data));

		case administrationConstants.GET_CUSTOMISATION_MANUAL:
			return state.set("customisationManuals", fromJS(action.data));

		case administrationConstants.GET_QA_MANUAL:
			return state.set("qaManuals", fromJS(action.data));

		case administrationConstants.GET_PACKAGE_CATEGORIES:
			return state.set("packageCategories", fromJS(action.data));

		case administrationConstants.CLEAR_MANUALS:
			return state
				.set("normalisationManuals", Map())
				.set("qaManuals", Map())
				.set("customisationManuals", Map());

		case administrationConstants.GET_TERMS_AND_CONDITION:
			return state.set("termsAndConditions", fromJS(action.data));

		case administrationConstants.GET_SPECIFICATION_PARAMETERS:
			return state.set("specificationParameters", fromJS(action.data));

		case administrationConstants.GET_SPECIFICATION_CATEGORIES:
			return state.set("specificationCategories", fromJS(action.data));

		case administrationConstants.GET_FIELD_TYPES:
			return state.set("parameterFieldTypes", fromJS(action.data));

		case administrationConstants.GET_COMPONENT_CATEGORY_CONTENT:
			return getComponentCategoryContent(action.data);

		case administrationConstants.GET_ADMIN_FAMILIES:
			return state.set("families", fromJS(action.data));

		case administrationConstants.GET_SYMBOL_FUNCTIONS:
			return state.set("symbolFunctions", fromJS(action.data));

		//------------------------CUSTOMISATION_TIME-------------------------------------------------
		case administrationConstants.GET_CUSTOMISATION_TIME_BY_INPUT_FORMAT:
			return setCustomisationTimeByInputFormat(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_CUSTOMISATION_TIME_BY_INPUT_FORMAT:
			return updateTempCustomisationTimeByInputFormat(action.data);
		case tempAdministrationConstants.RESET_TEMP_CUSTOMISATION_TIME_BY_INPUT_FORMAT:
			return resetTempCustomisationTimeByInputFormat(action.data);

		//------------------------EXPORTS_ADJUSTMENT-------------------------------------------------
		case administrationConstants.GET_EXPORTS_ADJUSTMENT_BY_INPUT_FORMAT:
			return setExportsAdjustmentByInputFormat(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_EXPORT_ADJUSTMENT_INPUT_FORMAT:
			return updateTempExportsAdjustmentInputFormat(action.data);
		case tempAdministrationConstants.RESET_TEMP_EXPORT_ADJUSTMENT_INPUT_FORMAT:
			return resetTempExportsAdjustmentInputFormat();

		//------------------------SERVICES_ADJUSTMENT-------------------------------------------------
		case administrationConstants.GET_SERVICES_ADJUSTMENT_INPUT_FORMAT:
			return setServicesAdjustmentInputFormat(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_SERVICES_ADJUSTMENT_INPUT_FORMAT:
			return updateTempServicesAdjustmentByInputFormat(action.data);
		case tempAdministrationConstants.RESET_TEMP_SERVICES_ADJUSTMENT_INPUT_FORMAT:
			return resetTempServicesAdjustmentByInputFormat();

		//------------------------DXF_SERVER---------------------

		case administrationConstants.GET_DXF_MODEL_PROPERTIES:
			return state.set("DXFModelProperties", fromJS(action.data));

		case administrationConstants.GET_ALL_DISCIPLINE_COMPONENTS:
			return getAllDisciplineComponents(action.data);

		case administrationConstants.GET_DXF_SERVER_COMPONENTS:
			return getDXFServerComponents(action.data);

		case administrationConstants.GET_DXF_SERVER_COMPONENT_PARAMETERS:
			return getDXFServerComponentParameters(action.data);

		case administrationConstants.CLEAR_DXF_SERVER_COMPONENTS:
			return state.set("DXFModelProperties", Map());

		//---------------------------------------------
		case tempAdministrationConstants.ADD_NEW_TEMP_PARAMETER:
			return addNewTempParameter(action.data);

		case tempAdministrationConstants.REMOVE_TEMP_PARAMETER:
			return removeTempParameter(action.data);

		case tempAdministrationConstants.RESET_LOD_PARAMETER_CATEGORIES:
			return resetLodParameterCategories();

		case tempAdministrationConstants.UPDATE_LOD_PARAMETER_CATEGORIES:
			return updateLodParameterCategories(action.data);

		case tempAdministrationConstants.SORT_LOD_PARAMETER_CATEGORIES:
			return sortLodParameterCategories(action.data);

		case tempAdministrationConstants.SET_CONTENT_IMAGE_TEMP_FILE:
			return setContentImageTempFile(action.data);

		case tempAdministrationConstants.DELETE_CONTENT_IMAGE_TEMP_FILE:
			return deleteContentTempImage(action.data);

		case tempAdministrationConstants.RESET_TEMP_LOD_CONTENT:
			return resetTempLodContent();

		case tempAdministrationConstants.RESET_LOD_CONTENT:
			return resetLodContent();

		case tempAdministrationConstants.UPDATE_TEMP_LOD_CONTENT_SUMMARY:
			return updateTempLodContentSummary(action.data);

		case tempAdministrationConstants.CLEAR_COMPONENT_CATEGORY_CONTENT:
			return state
				.set("componentCategoryContent", Map())
				.set("tempComponentCategoryContent", Map());

		//---------------------------------------------

		case administrationConstants.GET_NUMBER_OF_COMPONENT_CATEGORIES_BY_PARAMETER_CATEGORY_ID:
			return state.set(
				"numberOfComponentCategoriesByParameterId",
				fromJS(action.data)
			);

		//-------------------COUNTRY--------------------------

		case administrationConstants.GET_ALL_COUNTRY:
			return state.set("country", fromJS(action.data));

		//---------------------------------------------

		case administrationConstants.CLEAR_ADMINISTRATION_REQUEST_STATE:
			return state
				.set("action", false)
				.set("request", false)
				.set("errorMessage", "")
				.set("successMessage", "");

		case administrationConstants.CLEAR_ADMINISTRATION_MODAL_REQUEST_STATE:
			return state
				.set("actionModal", false)
				.set("requestModal", false)
				.set("errorMessage", "")
				.set("successMessage", "");

		case administrationConstants.ADMINISTRATION_ACTION_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorMessage", fromJS(action.data));

		case administrationConstants.ADMINISTRATION_MODAL_ACTION_FAILURE:
			return state
				.set("actionModal", false)
				.set("requestModal", true)
				.set("errorMessage", fromJS(action.data));

		case administrationConstants.ADMINISTRATION_ACTION_SUCCESS:
			return state
				.set("successMessage", fromJS(action.data))
				.set("action", true)
				.set("request", true);

		default:
			return state;
	}
};
