export const LocKeys = {
	OTHER: "OTHER",
	ENGINEERING_DICIPLINES: "ENGINEERING_DICIPLINES",
	ARCHITECTURE: "ARCHITECTURE",
	STRUCTURE: "STRUCTURE",
	TWOFA_CODE_MESSAGE: "TWOFA_CODE_MESSAGE",
	SEARCH_FOR_BUILIDNG: "SEARCH_FOR_BUILIDNG",
	ENTER_NAME: "ENTER_NAME",
	TICKET: "TICKET",
	COMPANY_ADMIN_REQUIRED: "COMPANY_ADMIN_REQUIRED",
	ALL_ROLES: "ALL_ROLES",
	JOB_INFO: "JOB_INFO",
	PRICE_ADJUSTMENT_LABEL: "PRICE_ADJUSTMENT_LABEL",
	VAT_NUMBER: "VAT_NUMBER",
	COMPANY_ADMIN_FIRST_ASSIGNMENT: "COMPANY_ADMIN_FIRST_ASSIGNMENT",
	STANDARD_DISCOUNT: "STANDARD_DISCOUNT",
	DELIVERY_INFO: "DELIVERY_INFO",
	TOTAL_CUSTOMERS: "TOTAL_CUSTOMERS",
	CLIENT_HANDLES_ORDERS: "CLIENT_HANDLES_ORDERS",
	ORDER_INFO: "ORDER_INFO",
	SERVICE: "SERVICE",
	TOTAL_ORDER: "TOTAL_ORDER",
	RESELLER_HANDLES_ORDERS: "RESELLER_HANDLES_ORDERS",
	WHO_HANDLES_ORDERS_FOR_THIS_COMPANY_INFO:
		"WHO_HANDLES_ORDERS_FOR_THIS_COMPANY_INFO",
	CITY: "CITY",
	HANDLES_ORDERS: "HANDLES_ORDERS",
	SELECT_RESELLER: "SELECT_RESELLER",
	PRICE_SUMMARY: "PRICE_SUMMARY",
	COUNTRY: "COUNTRY",
	PRICE_ADJUSTMENT_PLACEHOLDER: "PRICE_ADJUSTMENT_PLACEHOLDER",
	CUSTOM_ADJUSTMENT: "CUSTOM_ADJUSTMENT",
	GENERAL_INFO: "GENERAL_INFO",
	COMPANY_ROLE: "COMPANY_ROLE",
	BILLING_DETAILS: "BILLING_DETAILS",
	EDIT_COMPONENT_TYPE: "EDIT_COMPONENT_TYPE",
	PL: "PL",
	UPLOADING_FILE: "UPLOADING_FILE",
	ENTER_ADDRESS: "ENTER_ADDRESS",
	SUPPLIER_NAME: "SUPPLIER_NAME",
	BUSINESS_TYPE: "BUSINESS_TYPE",
	COMPANY_ADMIN: "COMPANY_ADMIN",
	SELECT_COMPANY_ADMIN: "SELECT_COMPANY_ADMIN",
	ADMIN_INFO: "ADMIN_INFO",
	WHO_HANDLES_ORDERS_FOR_THIS_COMPANY: "WHO_HANDLES_ORDERS_FOR_THIS_COMPANY",
	ENTER_X: "ENTER_X",
	RESELLER_NAME: "RESELLER_NAME",
	ALL_CUSTOMERS: "ALL_CUSTOMERS",
	DISCOUNT: "DISCOUNT",
	DELETE_COMPONENT_TYPE: "DELETE_COMPONENT_TYPE",
	READING_TEMPLATE: "READING_TEMPLATE",
	COMPLEXITY_ADJUSTMENT: "COMPLEXITY_ADJUSTMENT",
	POSTAL_CODE: "POSTAL_CODE",
	CRM_SERVER: "CRM_SERVER",
	SUPPORTED_FILE_FORMAT_PACKAGE: "SUPPORTED_FILE_FORMAT_PACKAGE",
	TEMPLATE_WARNING_MESSAGE: "TEMPLATE_WARNING_MESSAGE",
	FINISH_ESTIMATION_REVIEW: "FINISH_ESTIMATION_REVIEW",
	ADD_PARAMETER: "ADD_PARAMETER",
	ADJUSTED_PRICE: "ADJUSTED_PRICE",
	ALL_COMPONENT_TYPES: "ALL_COMPONENT_TYPES",
	COMPONENT_TYPES: "COMPONENT_TYPES",
	FILE_UPLOADED: "FILE_UPLOADED",
	UPLOAD_MODEL_FILE: "UPLOAD_MODEL_FILE",
	DXFP_SERVER: "DXFP_SERVER",
	OVERVIEW: "OVERVIEW",
	TEMPLATE_LABEL: "TEMPLATE_LABEL",
	NEW_COMPONENT_TYPE: "NEW_COMPONENT_TYPE",
	TEMPLATES: "TEMPLATES",
	EMPTY_TEMPLATES: "EMPTY_TEMPLATES",
	SELECT_TEMPLATE: "SELECT_TEMPLATE",
	NO_TEMPLATES_FOUND: "NO_TEMPLATES_FOUND",
	ADD_NEW_TYPE: "ADD_NEW_TYPE",
	ELEVATION: "ELEVATION",
	OPEN_DXF_SETTINGS: "OPEN_DXF_SETTINGS",
	ARCHICAD_LABEL: "ARCHICAD_LABEL",
	NEW_TEMPLATE: "NEW_TEMPLATE",
	SELECT_FAMILY: "SELECT_FAMILY",
	WORKSTEP: "WORKSTEP",
	SELECT_BUSINESS_OWNER: "SELECT_BUSINESS_OWNER",
	COMPONENTS: "COMPONENTS",
	FAMILY_TYPE: "FAMILY_TYPE",
	TWOFA: "TWOFA",
	NO_PARAMETERS_FOUND: "NO_PARAMETERS_FOUND",
	FIELD_CANNOT_LOWER_OR_EQUAL_THAN: "FIELD_CANNOT_LOWER_OR_EQUAL_THAN",
	EMAIL_FILE: "EMAIL_FILE",
	SEND_BUILDING_FILE_SUCCESS: "SEND_BUILDING_FILE_SUCCESS",
	EMAIL_FILES: "EMAIL_FILES",
	EMAIL_NUMBER_FILES_BUILDING: "EMAIL_NUMBER_FILES_BUILDING",
	VIEWER: "VIEWER",
	DOWNLOAD_SELECTED: "DOWNLOAD_SELECTED",
	PERCENTAGE: "PERCENTAGE",
	SELECT_OPTIONS: "SELECT_OPTIONS",
	ADD_COMPONENT: "ADD_COMPONENT",
	SELECT_OWNER: "SELECT_OWNER",
	ADD_A_COMMENT: "ADD_A_COMMENT",
	FAMILY_MAPPING: "FAMILY_MAPPING",
	DOWNLOAD_ALL: "DOWNLOAD_ALL",
	VELOCITY: "VELOCITY",
	CUSTOMISATION_TIME: "CUSTOMISATION_TIME",
	ACTIVITY: "ACTIVITY",
	RELATED_TO_ME: "RELATED_TO_ME",
	SERVICES: "SERVICES",
	DEVELOPMENT_CONSTANTS: "DEVELOPMENT_CONSTANTS",
	SELECT_FUNCTION: "SELECT_FUNCTION",
	BIMIFY: "BIMIFY",
	VISUAL_REPRESENTATION: "VISUAL_REPRESENTATION",
	FUNCTION: "FUNCTION",
	COMMENT: "COMMENT",
	MY_TICKETS: "MY_TICKETS",
	BIMIFY_MODEL_SAVING: "BIMIFY_MODEL_SAVING",
	IMAGE_NOT_AVAILABLE: "IMAGE_NOT_AVAILABLE",
	AUTOMATISATION: "AUTOMATISATION",
	SEND: "SEND",
	SEARCH_FOR_JOB: "SEARCH_FOR_JOB",
	ADDED: "ADDED",
	ATTACHMENTS: "ATTACHMENTS",
	MECHANICAL: "MECHANICAL",
	EMPTY_SYMBOLS: "EMPTY_SYMBOLS",
	SUPPORT: "SUPPORT",
	MEDIUM: "MEDIUM",
	ADD_COMMENT: "ADD_COMMENT",
	EDIT_SYMBOL: "EDIT_SYMBOL",
	EDIT_FAMILY: "EDIT_FAMILY",
	CREATE_FAMILY: "CREATE_FAMILY",
	RESOLVE: "RESOLVE",
	OWNER: "OWNER",
	BO: "BO",
	OFFER_TERMS_AND_CONDITIONS: "OFFER_TERMS_AND_CONDITIONS",
	NO_TICKETS_FOUND: "NO_TICKETS_FOUND",
	CREATE_SYMBOL: "CREATE_SYMBOL",
	ENTER_COMMENT: "ENTER_COMMENT",
	PENDING: "PENDING",
	ATTACH_FILES: "ATTACH_FILES",
	SELECT_PRIORITY: "SELECT_PRIORITY",
	LOW: "LOW",
	NEW_TICKET: "NEW_TICKET",
	PROJECT_LEAD: "PROJECT_LEAD",
	SELECT_PROJECT_LEAD: "SELECT_PROJECT_LEAD",
	TICKETS: "TICKETS",
	TICKET_NAME: "TICKET_NAME",
	ENTER_DESCRIPTION: "ENTER_DESCRIPTION",
	ENTER_TICKET_NAME: "ENTER_TICKET_NAME",
	SET_DATE: "SET_DATE",
	SELECT_ASSIGNEE: "SELECT_ASSIGNEE",
	OPTIONAL: "OPTIONAL",
	PRIORITY: "PRIORITY",
	HIGH: "HIGH",
	ITEM: "ITEM",
	SELECT_ITEM: "SELECT_ITEM",
	SELECT_COMPONENT: "SELECT_COMPONENT",
	TICKETING: "TICKETING",
	JOB_SPECIFIC_DESCRIPTION: "JOB_SPECIFIC_DESCRIPTION",
	DELETE_PARAMETER_MESSAGE: "DELETE_PARAMETER_MESSAGE",
	NEW_SYMBOL: "NEW_SYMBOL",
	DEVELOPMENT_TIME: "DEVELOPMENT_TIME",
	ELECTRICAL: "ELECTRICAL",
	DEFAULT: "DEFAULT",
	CREATE_TICKET: "CREATE_TICKET",
	UNCATEGORISED_OBJECT: "UNCATEGORISED_OBJECT",
	ALL_FAMILIES: "ALL_FAMILIES",
	AUTOMATISATION_CONSTANTS: "AUTOMATISATION_CONSTANTS",
	FAMILY_NAME: "FAMILY_NAME",
	SYMBOL: "SYMBOL",
	COMPONENT: "COMPONENT",
	ALL_COMPONNETS: "ALL_COMPONNETS",
	FAMILY: "FAMILY",
	COMPONENT_TYPE: "COMPONENT_TYPE",
	ENTER_FAMILY_NAME: "ENTER_FAMILY_NAME",
	INPUT_FILES: "INPUT_FILES",
	NO_DUE_DATE_SET: "NO_DUE_DATE_SET",
	PUBLISH: "PUBLISH",
	PRICE_EXCL_VAT_CELL: "PRICE_EXCL_VAT_CELL",
	CUSTOM_LEVELS_ERROR: "CUSTOM_LEVELS_ERROR",
	SYMBOL_CATEGORISATION: "SYMBOL_CATEGORISATION",
	SET_DUE_DATE: "SET_DUE_DATE",
	PUBLISH_FILES_TO_BUILDING: "PUBLISH_FILES_TO_BUILDING",
	DELIVER: "DELIVER",
	FILES_DELIVERED: "FILES_DELIVERED",
	SELECT_DISCIPLINES: "SELECT_DISCIPLINES",
	ASSIGNEES: "ASSIGNEES",
	SEARCH_FOR_OFFER: "SEARCH_FOR_OFFER",
	DRAG_AND_DROP: "DRAG_AND_DROP",
	UPDATED_ESTIMATE: "UPDATED_ESTIMATE",
	PARAMETER_ALREADY_EXISTS: "PARAMETER_ALREADY_EXISTS",
	CATEGORY_ALREADY_EXISTS: "CATEGORY_ALREADY_EXISTS",
	BUILDING_SIZE: "BUILDING_SIZE",
	NONE: "NONE",
	ENTER_TEXT: "ENTER_TEXT",
	ASSIGNEES_STATUS: "ASSIGNEES_STATUS",
	IMAGE_MISSING: "IMAGE_MISSING",
	CHECKBOX: "CHECKBOX",
	DELETE_CATEGORY_MESSAGE: "DELETE_CATEGORY_MESSAGE",
	UPDATE_STATUS: "UPDATE_STATUS",
	PARAMETER: "PARAMETER",
	NEW_ASSIGNEE: "NEW_ASSIGNEE",
	CONSULTANT_ESTIMATE: "CONSULTANT_ESTIMATE",
	REMOVE_IMAGE: "REMOVE_IMAGE",
	NO_IMAGE_UPLOADED_YET: "NO_IMAGE_UPLOADED_YET",
	ENTER_SUMMARY: "ENTER_SUMMARY",
	LOD_TIME_ADJUSTMENTS: "LOD_TIME_ADJUSTMENTS",
	TEXT_INPUT_FIELD: "TEXT_INPUT_FIELD",
	START_TYPING: "START_TYPING",
	ADD_NEW_PARAMETER: "ADD_NEW_PARAMETER",
	IMAGE_2D: "IMAGE_2D",
	NEW_PARAMETER: "NEW_PARAMETER",
	CATEGORY_PARAMETERS: "CATEGORY_PARAMETERS",
	BUILDING_LEVELS: "BUILDING_LEVELS",
	BUILDING_CATEGORY_TIME_ADJUSTMENTS: "BUILDING_CATEGORY_TIME_ADJUSTMENTS",
	PLUMBING: "PLUMBING",
	SELECT_TYPE_OF_FIELD: "SELECT_TYPE_OF_FIELD",
	ADD_IMAGE: "ADD_IMAGE",
	ADD_NEW_CATEGORY: "ADD_NEW_CATEGORY",
	REUPLOAD_IMAGE: "REUPLOAD_IMAGE",
	STATUS_MESSAGE: "STATUS_MESSAGE",
	ADD_RECIPIENT: "ADD_RECIPIENT",
	PARAMETERS: "PARAMETERS",
	FIELD_TYPE: "FIELD_TYPE",
	PLACEHOLDER_HOUR: "PLACEHOLDER_HOUR",
	FROM: "FROM",
	SEARCH: "SEARCH",
	EMAIL_OFFER: "EMAIL_OFFER",
	CATEGORIES: "CATEGORIES",
	MODELING_TIME: "MODELING_TIME",
	UPLOAD_NOW: "UPLOAD_NOW",
	RECALCULATE_PRICE: "RECALCULATE_PRICE",
	RECALCULATE_ESTIMATE: "RECALCULATE_ESTIMATE",
	UPLOAD_LATER: "UPLOAD_LATER",
	THANK_YOU_FOR_REQUEST: "THANK_YOU_FOR_REQUEST",
	SUCCESS_CREATE_OFFER_MESSAGE: "SUCCESS_CREATE_OFFER_MESSAGE",
	STARTING_FROM: "STARTING_FROM",
	JOB_CANCELED: "JOB_CANCELED",
	SERVER_ERROR: "SERVER_ERROR",
	BIMIFY_PROGRESS_WAITING: "BIMIFY_PROGRESS_WAITING",
	PRICE_FROM: "PRICE_FROM",
	MODEL_LINK: "MODEL_LINK",
	MODEL_LINK_PLACEHOLDER: "MODEL_LINK_PLACEHOLDER",
	REPETITIVENESS_ADJUSTMENTS: "REPETITIVENESS_ADJUSTMENTS",
	REPETITIVENESS_SAVING_ADJUSTMENTS: "REPETITIVENESS_SAVING_ADJUSTMENTS",
	TIME_REPETITIVENESS_SAVING_ADJUSTMENTS:
		"TIME_REPETITIVENESS_SAVING_ADJUSTMENTS",
	CANCELING_JOB: "CANCELING_JOB",
	PACKAGE_INFO: "PACKAGE_INFO",
	LEGAL: "LEGAL",
	TARGET_BUILDING_TYPE: "TARGET_BUILDING_TYPE",
	UNIQUE_LEVELS: "UNIQUE_LEVELS",
	UNIQUE_LEVELS_EXPLANATION: "UNIQUE_LEVELS_EXPLANATION",
	SELECT_TARGET_BUILDING_TYPE: "SELECT_TARGET_BUILDING_TYPE",
	SYSTEM_SETTINGS: "SYSTEM_SETTINGS",
	TERMS_OF_SERVICE: "TERMS_OF_SERVICE",
	AND: "AND",
	ARE_YOU_SURE_DELETE: "ARE_YOU_SURE_DELETE",
	GENERATE_PDF: "GENERATE_PDF",
	GENERATING_PDF: "GENERATING_PDF",
	DOWNLOAD_AS_PDF: "DOWNLOAD_AS_PDF",
	SELECT_EXPORTS: "SELECT_EXPORTS",
	SELECT_MANUALS: "SELECT_MANUALS",
	MODEL_CONTENT: "MODEL_CONTENT",
	SELECT_CATEGORY: "SELECT_CATEGORY",
	PACKAGE_OWNER: "PACKAGE_OWNER",
	SELECT_PACKAGE_OWNER: "SELECT_PACKAGE_OWNER",
	IMPORT: "IMPORT",
	QA_MANUALS: "QA_MANUALS",
	CUSTOMISATION_MANUALS: "CUSTOMISATION_MANUALS",
	NORMALISATION_MANUALS: "NORMALISATION_MANUALS",
	BUILDING_CATEGORY: "BUILDING_CATEGORY",
	MODELING_COMPLETED: "MODELING_COMPLETED",
	MODELING_FAILED: "MODELING_FAILED",
	REMOVE_THUMBNAIL: "REMOVE_THUMBNAIL",
	THUMBNAIL: "THUMBNAIL",
	DASHBOARD: "DASHBOARD",
	NEW_PACKAGE: "NEW_PACKAGE",
	MANUALS: "MANUALS",
	VIEW_DETAILS: "VIEW_DETAILS",
	CONTENT: "CONTENT",
	SUCCESS_DELETE_BUILDING_COMPONENT: "SUCCESS_DELETE_BUILDING_COMPONENT",
	SUCCESS_DELETE_LOD: "SUCCESS_DELETE_LOD",
	SUCCESS_DELETE_DISCIPLINE: "SUCCESS_DELETE_DISCIPLINE",
	ROWS: "ROWS",
	DEFAULT_VAT: "DEFAULT_VAT",
	SUCCESS_DELETE_EXPORT: "SUCCESS_DELETE_EXPORT",
	SPECIFICATION_SUMMARY: "SPECIFICATION_SUMMARY",
	LOD_CONTENT: "LOD_CONTENT",
	LOD_NAME: "LOD_NAME",
	TITLE: "TITLE",
	ENTER_MANUAL_NAME: "ENTER_MANUAL_NAME",
	MIN_OFFER_PRICE: "MIN_OFFER_PRICE",
	PRICE_ADJUSTMENTS_PER_LOD: "PRICE_ADJUSTMENTS_PER_LOD",
	PRICE_ADJUSTMENT: "PRICE_ADJUSTMENT",
	PRICE_ADJUSTMENT_PER_BUILDING_CATEGORY:
		"PRICE_ADJUSTMENT_PER_BUILDING_CATEGORY",
	ADD_NEW_ITEM: "ADD_NEW_ITEM",
	MIN_PRICE: "MIN_PRICE",
	LOD_ADJUSTMENTS: "LOD_ADJUSTMENTS",
	MODELING_PRICES: "MODELING_PRICES",
	CATEGORY_NAME: "CATEGORY_NAME",
	IMAGE: "IMAGE",
	ADD_NEW: "ADD_NEW",
	CAD: "CAD",
	POINT_CLOUD: "POINT_CLOUD",
	BUILDING_COMPONENT: "BUILDING_COMPONENT",
	ITEM_NAME: "ITEM_NAME",
	ADD_NEW_BUILDING_COMPONENT: "ADD_NEW_BUILDING_COMPONENT",
	COMPONENT_NAME: "COMPONENT_NAME",
	DISCIPLINE: "DISCIPLINE",
	ADD_NEW_DISCIPLINE: "ADD_NEW_DISCIPLINE",
	ADD_NEW_LOD: "ADD_NEW_LOD",
	TYPE_NAME: "TYPE_NAME",
	CATEGORY: "CATEGORY",
	ADD_NEW_BUILDING_TYPE: "ADD_NEW_BUILDING_TYPE",
	ADD_NEW_BUILDING_CATEGORY: "ADD_NEW_BUILDING_CATEGORY",
	STATUS_UPDATED: "STATUS_UPDATED",
	ACCEPT_BIMIFY_ESTIMATE: "ACCEPT_BIMIFY_ESTIMATE",
	LEVELS_AND_GRIDS: "LEVELS_AND_GRIDS",
	EXTERIOR_WALLS: "EXTERIOR_WALLS",
	INTERIOR_WALLS: "INTERIOR_WALLS",
	REVIT_LABEL: "REVIT_LABEL",
	ENGINEERING_DISCIPLINES: "ENGINEERING_DISCIPLINES",
	BUILDING_COMPONENTS: "BUILDING_COMPONENTS",
	ARE_YOY_SURE_DELETE_MESSAGE: "ARE_YOY_SURE_DELETE_MESSAGE",
	BUILDING_CATEGORIES: "BUILDING_CATEGORIES",
	AUTOMATED_PRICING: "AUTOMATED_PRICING",
	USE_DA4REVIT_API: "USE_DA4REVIT_API",
	USE_QUEUE: "USE_QUEUE",
	CONFIRM_USE_DA4REVIT_API: "CONFIRM_USE_DA4REVIT_API",
	CONFIRM_USE_QUEUE: "CONFIRM_USE_QUEUE",
	CURTAIN_WALLS: "CURTAIN_WALLS",
	MODELING: "MODELING",
	SYMBOLS: "SYMBOLS",
	FAMILIES: "FAMILIES",
	PACKAGE: "PACKAGE",
	OTHER_SALE_ITEMS: "OTHER_SALE_ITEMS",
	PASSWORD_UPDATED: "PASSWORD_UPDATED",
	SPECIFICATION_OWNER: "SPECIFICATION_OWNER",
	SELECT_SPECIFICATION_OWNER: "SELECT_SPECIFICATION_OWNER",
	I_ACCEPT: "I_ACCEPT",
	FOUNDATION: "FOUNDATION",
	DELETE_TYPE: "DELETE_TYPE",
	SELECT_NEW_PARAMETER: "SELECT_NEW_PARAMETER",
	PRIVACY_POLICY: "PRIVACY_POLICY",
	PRICING: "PRICING",
	ELAVATION_INFO_MESSAGE: "ELAVATION_INFO_MESSAGE",
	PAGE_NOT_EXIST: "PAGE_NOT_EXIST",
	ELAVATION_ERROR_MESSAGE: "ELAVATION_ERROR_MESSAGE",
	BACK_TO_HOME: "BACK_TO_HOME",
	SEND_OFFER_SUCCESS: "SEND_OFFER_SUCCESS",
	DONE: "DONE",
	THICKNESS: "THICKNESS",
	MANUAL: "MANUAL",
	COLUMNS: "COLUMNS",
	ROLE: "ROLE",
	FLOORS: "FLOORS",
	BEAMS_BRACES_TRUSSES: "BEAMS_BRACES_TRUSSES",
	JOB_SPECIFICATION: "JOB_SPECIFICATION",
	ROOFS: "ROOFS",
	ROOFS_ACCESSSORIES: "ROOFS_ACCESSSORIES",
	CEILINGS: "CEILINGS",
	STAIRS: "STAIRS",
	RAILINGS: "RAILINGS",
	DOORS: "DOORS",
	WINDOWS: "WINDOWS",
	SUNPROTECTION_SHUTTERS_BLINDS: "SUNPROTECTION_SHUTTERS_BLINDS",
	MEP: "MEP",
	SANITARY_EQUIPMENT: "SANITARY_EQUIPMENT",
	APPLIANCES: "APPLIANCES",
	CASEWORK: "CASEWORK",
	LOOSE_FURNITURE: "LOOSE_FURNITURE",
	ROOMS: "ROOMS",
	ACCEPT_DELIVERABLE: "ACCEPT_DELIVERABLE",
	ACCEPT_DELIVERABLE_UNAVAILABLE: "ACCEPT_DELIVERABLE_UNAVAILABLE",
	ACCEPT_ESTIMATE_INFO: "ACCEPT_ESTIMATE_INFO",
	ITEM_CREATED: "ITEM_CREATED",
	JOB_ID: "JOB_ID",
	QUALITY_ASSURANCE: "QUALITY_ASSURANCE",
	BIM_MODEL: "BIM_MODEL",
	BUILDING_TYPES: "BUILDING_TYPES",
	LOD_SPECIFICATIONS: "LOD_SPECIFICATIONS",
	NORMALISATION: "NORMALISATION",
	FINISH: "FINISH",
	INPUT_NORMALISATION: "INPUT_NORMALISATION",
	FAILED_MODEL_MESSAGE: "FAILED_MODEL_MESSAGE",
	OFFER_TYPE: "OFFER_TYPE",
	DECLINE_OFFER: "DECLINE_OFFER",
	DESCRIPTION: "DESCRIPTION",
	CONFIGURATION: "CONFIGURATION",
	JOB_NAME: "JOB_NAME",
	EXPORT: "EXPORT",
	VERSION: "VERSION",
	DOWNLOAD_FILES: "DOWNLOAD_FILES",
	BUILDING_OWNER: "BUILDING_OWNER",
	CUSTOMER_NAME: "CUSTOMER_NAME",
	OFFER_NAME: "OFFER_NAME",
	JOB_TYPE: "JOB_TYPE",
	LAST_UPDATE: "LAST_UPDATE",
	LAST_UPDATED: "LAST_UPDATED",
	ACCEPT_OFFER: "ACCEPT_OFFER",
	NEW_LOD_SPECIFICATION: "NEW_LOD_SPECIFICATION",
	ALL: "ALL",
	COMMENTS: "COMMENTS",
	JOB_CONFIGURATION: "JOB_CONFIGURATION",
	LOD_SPECIFICATION: "LOD_SPECIFICATION",
	MODELS: "MODELS",
	OFFER: "OFFER",
	SPECIFICATION_NAME: "SPECIFICATION_NAME",
	SUPPLIER: "SUPPLIER",
	UPATE_BIM_MODEL_INFO: "UPATE_BIM_MODEL_INFO",
	ENTER_SPECIFICATION_NAME: "ENTER_SPECIFICATION_NAME",
	ITEM_CREATE_SUCCESS: "ITEM_CREATE_SUCCESS",
	EMPTY_LOD_SPECIFICATION: "EMPTY_LOD_SPECIFICATION",
	ACCEPT_QUOTE: "ACCEPT_QUOTE",
	ARCHICAD_VERSION: "ARCHICAD_VERSION",
	SUBTOTAL: "SUBTOTAL",
	EMPTY_PLACHOLDER: "EMPTY_PLACHOLDER",
	REVIT_VERSION: "REVIT_VERSION",
	ACCEPT_TERMS_AND_CONDITIONS: "ACCEPT_TERMS_AND_CONDITIONS",
	ACCEPTED_LABEL: "ACCEPTED_LABEL",
	TEMPLATE_NAME: "TEMPLATE_NAME",
	ENTER_TEMPLATE_NAME: "ENTER_TEMPLATE_NAME",
	TIME_ADJUSTMENT_PER_BUILDING_CATEGORY:
		"TIME_ADJUSTMENT_PER_BUILDING_CATEGORY",
	CHOOSE_FILE: "CHOOSE_FILE",
	UPLOAD_FILE: "UPLOAD_FILE",
	DOWNLOAD_FILE: "DOWNLOAD_FILE",
	EXPORTS: "EXPORTS",
	OFFERS: "OFFERS",
	WE_ARE_HERE_FOR_YOU: "WE_ARE_HERE_FOR_YOU",
	NORMALIZER_LABEL: "NORMALIZER_LABEL",
	NOBODY_ASSIGNED: "NOBODY_ASSIGNED",
	CUSTOMIZER_LABEL: "CUSTOMIZER_LABEL",
	SEND_OFFER: "SEND_OFFER",
	UPLOAD_INVOICE: "UPLOAD_INVOICE",
	ELECTRICAL_CONDUITS: "ELECTRICAL_CONDUITS",
	LIGHTING_FIXTURE: "LIGHTING_FIXTURE",
	CABLE_TRAY_FITTINGS: "CABLE_TRAY_FITTINGS",
	CABLE_TRAYS: "CABLE_TRAYS",
	PLUMBING_FIXTURES: "PLUMBING_FIXTURES",
	PIPE_FITTINGS: "PIPE_FITTINGS",
	PIPES: "PIPES",
	MECHANICAL_EQUIPMENT: "MECHANICAL_EQUIPMENT",
	DUCT_FITTINGS: "DUCT_FITTINGS",
	DUCTS: "DUCTS",
	CHOOSE_BUILDING: "CHOOSE_BUILDING",
	GUEST_LABEL: "GUEST_LABEL",
	TIME_ADJUSTMENTS_PER_LOD: "TIME_ADJUSTMENTS_PER_LOD",
	QUALITY_CONTROL_LABEL: "QUALITY_CONTROL_LABEL",
	SELECT_BUILDING: "SELECT_BUILDING",
	JOB: "JOB",
	SELECT_DISCIPLINE: "SELECT_DISCIPLINE",
	NEW_BUILDING: "NEW_BUILDING",
	ALL_TYPES: "ALL_TYPES",
	DELETE: "DELETE",
	SIZE: "SIZE",
	JOBS: "JOBS",
	CUSTOMIZATION_DESCRIPTION: "CUSTOMIZATION_DESCRIPTION",
	CHOOSE_LOD: "CHOOSE_LOD",
	USER_ROLE: "USER_ROLE",
	MAP_FILES: "MAP_FILES",
	CHOOSE_FOMRAT: "CHOOSE_FOMRAT",
	ACCEPTED_QUOTE: "ACCEPTED_QUOTE",
	NEXT: "NEXT",
	JOB_DESCRIPTION: "JOB_DESCRIPTION",
	CREATE: "CREATE",
	NEW_JOB: "NEW_JOB",
	CREATE_BIM_MODEL: "CREATE_BIM_MODEL",
	UPDATE_BIM_MODEL: "UPDATE_BIM_MODEL",
	EMPTY_ORDERS: "EMPTY_ORDERS",
	EMPTY_MANUALS: "EMPTY_MANUALS",
	EMPTY_FAMILIES: "EMPTY_FAMILIES",
	EMPTY_COMPANIES: "EMPTY_COMPANIES",
	CREATE_JOB: "CREATE_JOB",
	CREATE_MANUAL: "CREATE_MANUAL",
	CREATE_COMPONENT_TYPE: "CREATE_COMPONENT_TYPE",
	EDIT_MANUAL: "EDIT_MANUAL",
	NO_BUILDINGS_FOUND: "NO_BUILDINGS_FOUND",
	EMPTY_BUILDINGS: "EMPTY_BUILDINGS",
	DELETE_SUCCESS_MESSAGE: "DELETE_SUCCESS_MESSAGE",
	ACCORDION: "ACCORDION",
	PREVIEW: "PREVIEW",
	UPDATE_ORDER_STATUS: "UPDATE_ORDER_STATUS",
	UPDATE_JOB_STATUS: "UPDATE_JOB_STATUS",
	ACTIONS_BUSINESS_OWNER: "ACTIONS_BUSINESS_OWNER",
	ENTER_BUILDING_NAME: "ENTER_BUILDING_NAME",
	ENTER_LEVEL_NAME: "ENTER_LEVEL_NAME",
	ACTIONS_PROJECT_LEAD: "ACTIONS_PROJECT_LEAD",
	CUSTOMER: "CUSTOMER",
	SETTINGS: "SETTINGS",
	DECLINE: "DECLINE",
	SELECT_CUSTOMER: "SELECT_CUSTOMER",
	SEND_REQUEST: "SEND_REQUEST",
	BIMIFY_SUPPORT: "BIMIFY_SUPPORT",
	TERMS_AND_CONDITIONS_ACCEPT: "TERMS_AND_CONDITIONS_ACCEPT",
	DELIVERY_FORMAT: "DELIVERY_FORMAT",
	DECLINE_ORDER_HEADING: "DECLINE_ORDER_HEADING",
	ACTIVATE: "ACTIVATE",
	SELECT_VERSION: "SELECT_VERSION",
	PAGE_COMING_SOON: "PAGE_COMING_SOON",
	CUSTOMERS: "CUSTOMERS",
	CANCEL_MESSAGE: "CANCEL_MESSAGE",
	ADDITIONAL_INFORMATION: "ADDITIONAL_INFORMATION",
	SAVE_AND_LOGIN: "SAVE_AND_LOGIN",
	INPUT_FORMAT: "INPUT_FORMAT",
	PROCESS_STEP: "PROCESS_STEP",
	ACTIVATE_COMPANY: "ACTIVATE_COMPANY",
	ACTIVATE_COMPANY_ERROR: "ACTIVATE_COMPANY_ERROR",
	ACTIVATE_COMPANY_MESSAGE: "ACTIVATE_COMPANY_MESSAGE",
	ACTIVATE_COMPANY_SUCCESS: "ACTIVATE_COMPANY_SUCCESS",
	ACTIVE_USER: "ACTIVE_USER",
	OFFER_INFO: "OFFER_INFO",
	ACTIVE: "ACTIVE",
	INPUT: "INPUT",
	OUTPUT: "OUTPUT",
	SELECT_PROCESS_STEP: "SELECT_PROCESS_STEP",
	ADD: "ADD",
	ADD_ANOTHER_USER: "ADD_ANOTHER_USER",
	ADD_CUSTOM_SERVICE: "ADD_CUSTOM_SERVICE",
	SAVE_NEW_TEMPLATE: "SAVE_NEW_TEMPLATE",
	SAVE_LOD_TEMPLATE_TITLE: "SAVE_LOD_TEMPLATE_TITLE",
	SAVE_LOD_TEMPLATE_TEXT: "SAVE_LOD_TEMPLATE_TEXT",
	ENTER_SHORT_DESCRIPTION: "ENTER_SHORT_DESCRIPTION",
	SELECT_OTPUT_FORMAT: "SELECT_OTPUT_FORMAT",
	SELECT_INPUT_FORMAT: "SELECT_INPUT_FORMAT",
	SELECT_EXPORT_FILES: "SELECT_EXPORT_FILES",
	SELECT_PREDEFINED_LOD: "SELECT_PREDEFINED_LOD",
	ADD_USER: "ADD_USER",
	ADD_X: "ADD_X",
	ADDRESS: "ADDRESS",
	ADDITIONAL_SERVICES: "ADDITIONAL_SERVICES",
	ADJUSTMENT: "ADJUSTMENT",
	ADMIN_LABEL: "ADMIN_LABEL",
	AMOUNT: "AMOUNT",
	APPROVE_QUOTE: "APPROVE_QUOTE",
	ARCHIVE: "ARCHIVE",
	ARCHIVE_COMPANY: "ARCHIVE_COMPANY",
	ARCHIVE_COMPANY_ERROR: "ARCHIVE_COMPANY_ERROR",
	ARCHIVE_COMPANY_MESSAGE: "ARCHIVE_COMPANY_MESSAGE",
	ARCHIVE_COMPANY_SUCCESS: "ARCHIVE_COMPANY_SUCCESS",
	ARCHIVE_USER: "ARCHIVE_USER",
	AREA: "AREA",
	ASSIGN: "ASSIGN",
	ATTACH: "ATTACH",
	AWAITING_APPROVAL: "AWAITING_APPROVAL",
	AWAITING_APPROVAL_LABEL: "AWAITING_APPROVAL_LABEL",
	BACK: "BACK",
	NEW_OFFER: "NEW_OFFER",
	NEW_MANUAL: "NEW_MANUAL",
	NEW_FAMILY: "NEW_FAMILY",
	BACK_TO_LIST: "BACK_TO_LIST",
	BACK_TO_LOGIN: "BACK_TO_LOGIN",
	BACK_TO_X: "BACK_TO_X",
	IMAGE_3D: "IMAGE_3D",
	BIMIFY_CONTACT: "BIMIFY_CONTACT",
	ADDITIONAL_INFORMATION_PLACEHOLDER: "ADDITIONAL_INFORMATION_PLACEHOLDER",
	BIMIFY_LABEL: "BIMIFY_LABEL",
	UPDATE_DELIVERY_FORMAT_VERSION_LABEL: "UPDATE_DELIVERY_FORMAT_VERSION_LABEL",
	BROWSE_TO_UPLOAD: "BROWSE_TO_UPLOAD",
	BUILDING: "BUILDING",
	BUILDING_ACCEPT_DELIVERABLE: "BUILDING_ACCEPT_DELIVERABLE",
	SELECT_DELIVERY_FORMAT: "SELECT_DELIVERY_FORMAT",
	SELECT_LOD_SPECIFICATION: "SELECT_LOD_SPECIFICATION",
	BUILDING_ADDRESS: "BUILDING_ADDRESS",
	BUILDING_DELIVERABLES: "BUILDING_DELIVERABLES",
	BUILDING_DETAILS: "BUILDING_DETAILS",
	BUILDING_ESTIMATE_ERROR_MESSAGE: "BUILDING_ESTIMATE_ERROR_MESSAGE",
	BUILDING_ESTIMATE_GUEST_SUCCESS: "BUILDING_ESTIMATE_GUEST_SUCCESS",
	BUILDING_ESTIMATE_SUCCESS: "BUILDING_ESTIMATE_SUCCESS",
	BUILDING_INFO: "BUILDING_INFO",
	TO: "TO",
	BUILDING_NAME: "BUILDING_NAME",
	BUILDING_PROGRESS: "BUILDING_PROGRESS",
	BUILDING_SPECIFICATION: "BUILDING_SPECIFICATION",
	BUILDING_STATUS: "BUILDING_STATUS",
	BUILDING_STATUS_RESTORE: "BUILDING_STATUS_RESTORE",
	BUILDING_STATUS_RESTORE_ERROR: "BUILDING_STATUS_RESTORE_ERROR",
	BUILDING_STATUS_RESTORE_SUCCESS: "BUILDING_STATUS_RESTORE_SUCCESS",
	BUILDING_STATUS_TRASH: "BUILDING_STATUS_TRASH",
	BUILDING_STATUS_TRASH_ERROR: "BUILDING_STATUS_TRASH_ERROR",
	ITEM_UPDATED: "ITEM_UPDATED",
	ITEM_DELETED: "ITEM_DELETED",
	ERROR_ITEM_DELETED: "ERROR_ITEM_DELETED",
	ERROR_ITEM_UPDATED: "ERROR_ITEM_UPDATED",
	ERROR_ITEM_CREATED: "ERROR_ITEM_CREATED",
	BUILDING_STATUS_TRASH_SUCCESS: "BUILDING_STATUS_TRASH_SUCCESS",
	BUILDING_TYPE: "BUILDING_TYPE",
	BUILDING_UPDATE_ERROR: "BUILDING_UPDATE_ERROR",
	BUILDING_UPDATE_SUCCESS: "BUILDING_UPDATE_SUCCESS",
	BUILDINGS: "BUILDINGS",
	BUILDINGS_OVERWRITE_WARNING: "BUILDINGS_OVERWRITE_WARNING",
	BUSINESS_OWNER_LABEL: "BUSINESS_OWNER_LABEL",
	BUTTON: "BUTTON",
	BUTTON_STYLES: "BUTTON_STYLES",
	BY: "BY",
	CANCEL: "CANCEL",
	CANCEL_ORDER: "CANCEL_ORDER",
	CANCEL_UPLOAD: "CANCEL_UPLOAD",
	CANCELED_ESTIMATE_ERROR: "CANCELED_ESTIMATE_ERROR",
	CANCELED_ESTIMATE_MESSAGE: "CANCELED_ESTIMATE_MESSAGE",
	CANCELED_ESTIMATE_SUCCESS: "CANCELED_ESTIMATE_SUCCESS",
	CANCELED_LABEL: "CANCELED_LABEL",
	CANCELED_ORDER_ERROR: "CANCELED_ORDER_ERROR",
	DECLINED_ORDER_MESSAGE: "DECLINED_ORDER_MESSAGE",
	DECLINED_ORDER_SUCCESS: "DECLINED_ORDER_SUCCESS",
	CARD_STYLES: "CARD_STYLES",
	CARDS: "CARDS",
	CHANGE_PASSWORD: "CHANGE_PASSWORD",
	CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
	CHATROOM_HISTORY_EMPTY: "CHATROOM_HISTORY_EMPTY",
	CHATROOM_HISTORY_END: "CHATROOM_HISTORY_END",
	CHATROOM_HISTORY_START: "CHATROOM_HISTORY_START",
	CHATROOM_NOTES_INTRO: "CHATROOM_NOTES_INTRO",
	CHATROOM_NOTES_NAME: "CHATROOM_NOTES_NAME",
	CHATROOM_USER_FORBIDDEN: "CHATROOM_USER_FORBIDDEN",
	UPLOAD_FROM_COMPUTER: "UPLOAD_FROM_COMPUTER",
	CHOOSE_NEW_PASSWORD: "CHOOSE_NEW_PASSWORD",
	CLEAR_SELECTION: "CLEAR_SELECTION",
	ASSIGNEE: "ASSIGNEE",
	CLIENT: "CLIENT",
	CLIENT_COMPANY: "CLIENT_COMPANY",
	CLIENT_LABEL: "CLIENT_LABEL",
	CLIENT_NAME: "CLIENT_NAME",
	PRINT: "PRINT",
	CLIENT_SPECIFIC_SERVICE: "CLIENT_SPECIFIC_SERVICE",
	CLIENT_SPECIFIC_SERVICES: "CLIENT_SPECIFIC_SERVICES",
	CLIENTS: "CLIENTS",
	CLOSE: "CLOSE",
	CODE: "CODE",
	COMING_SOON: "COMING_SOON",
	COMPANIES: "COMPANIES",
	COMPANY: "COMPANY",
	COMPANY_ADD_ERROR: "COMPANY_ADD_ERROR",
	COMPANY_ADDRESS: "COMPANY_ADDRESS",
	COMPANY_ERROR: "COMPANY_ERROR",
	COMPANY_INFO: "COMPANY_INFO",
	COMPANY_NAME: "COMPANY_NAME",
	COMPANY_NOT_FOUND: "COMPANY_NOT_FOUND",
	COMPANY_TYPE: "COMPANY_TYPE",
	COMPANY_TYPE_REQUIRED: "COMPANY_TYPE_REQUIRED",
	COMPLETED_LABEL: "COMPLETED_LABEL",
	CONFRIM_NEW_PASSWORD: "CONFRIM_NEW_PASSWORD",
	CONTACT: "CONTACT",
	CONTACT_PERSON: "CONTACT_PERSON",
	CONTACT_PERSON_INFO: "CONTACT_PERSON_INFO",
	CONVERSATION_EXPLANATION: "CONVERSATION_EXPLANATION",
	CONVERSATIONS: "CONVERSATIONS",
	COPIED: "COPIED",
	COPY: "COPY",
	COPY_HTML_TO_USE_X: "COPY_HTML_TO_USE_X",
	CREATE_NEW: "CREATE_NEW",

	CREATE_NEW_SPECIFICATION: "CREATE_NEW_SPECIFICATION",
	CREATE_ORDER: "CREATE_ORDER",
	CREATE_ORDER_LOADING_MODAL_MESSAGE: "CREATE_ORDER_LOADING_MODAL_MESSAGE",
	CREATE_ORDER_LOADING_MODAL_MESSAGE_FILELESS:
		"CREATE_ORDER_LOADING_MODAL_MESSAGE_FILELESS",
	CREATE_SPECIFICATION: "CREATE_SPECIFICATION",
	CREATE_USER_ERROR: "CREATE_USER_ERROR",
	CREATED: "CREATED",
	CURRENTLY_UNAVAILABLE: "CURRENTLY_UNAVAILABLE",
	DAYS: "DAYS",
	NO_JOBS_FOUND: "NO_JOBS_FOUND",
	DEFAULT_BUTTON_CLASSES: "DEFAULT_BUTTON_CLASSES",
	DEFAULT_LEVEL_HEIGHT: "DEFAULT_LEVEL_HEIGHT",
	DEFAULT_VALUES: "DEFAULT_VALUES",
	DEFAULT_VALUES_EXPLANATION: "DEFAULT_VALUES_EXPLANATION",
	DELETE_FILE: "DELETE_FILE",
	DELETE_FILE_ERROR_MESSAGE: "DELETE_FILE_ERROR_MESSAGE",
	DELETE_FILES: "DELETE_FILES",
	DELETE_USERS_SUCCESS_MESSAGE: "DELETE_USERS_SUCCESS_MESSAGE",
	DELIVERABLE: "DELIVERABLE",
	ITEM_ACTIVE_SUCCESS: "ITEM_ACTIVE_SUCCESS",
	DELIVERABLE_DOWNLOAD_DISABLED: "DELIVERABLE_DOWNLOAD_DISABLED",
	DELIVERABLE_REQUIRED: "DELIVERABLE_REQUIRED",
	DELIVERABLES: "DELIVERABLES",
	DELIVERY: "DELIVERY",
	DELIVERY_DAYS: "DELIVERY_DAYS",
	DELIVERY_LABEL: "DELIVERY_LABEL",
	DELIVERY_TIME_LABEL: "DELIVERY_TIME_LABEL",
	DESIGNATOR_CLASS: "DESIGNATOR_CLASS",
	DISABLED: "DISABLED",
	DOCS: "DOCS",
	DOCS_BUTTONS_DIRECTION: "DOCS_BUTTONS_DIRECTION",
	DOCS_BUTTONS_NOTE: "DOCS_BUTTONS_NOTE",
	DOCS_FORM_DIRECTION: "DOCS_FORM_DIRECTION",
	DOCS_FORM_NOTE: "DOCS_FORM_NOTE",
	DOCS_SNACKBAR_DIRECTION: "DOCS_SNACKBAR_DIRECTION",
	DOCS_SNACKBAR_NOTE: "DOCS_SNACKBAR_NOTE",
	DOWNLOAD: "DOWNLOAD",
	DOWNLOAD_INVOICE: "DOWNLOAD_INVOICE",
	DRAG_AND_UPLOAD_FILES: "DRAG_AND_UPLOAD_FILES",
	DRAWING_REFERENCE: "DRAWING_REFERENCE",
	DRAWING_REFERENCE_REQUIRED: "DRAWING_REFERENCE_REQUIRED",
	DRAWING_REFERENCES: "DRAWING_REFERENCES",
	DUE_DATE: "DUE_DATE",
	EDIT: "EDIT",
	EDIT_ESTIMATE: "EDIT_ESTIMATE",
	EDIT_PROFILE_IMAGE: "EDIT_PROFILE_IMAGE",
	EDIT_SPECIFICATION_PRICE: "EDIT_SPECIFICATION_PRICE",
	EDIT_USER: "EDIT_USER",
	EDIT_X: "EDIT_X",
	EMAIL: "EMAIL",
	EMAIL_ADDRESS: "EMAIL_ADDRESS",
	EMAIL_ESTIMATE: "EMAIL_ESTIMATE",
	EMPTY_USER: "EMPTY_USER",
	EMAIL_NOT_CORRECT: "EMAIL_NOT_CORRECT",
	EMAIL_SENT: "EMAIL_SENT",
	EMAIL_UPDATE_SUCCESS: "EMAIL_UPDATE_SUCCESS",
	EMAIL_PENDING: "EMAIL_PENDING",
	EMAILED_TO: "EMAILED_TO",
	EMBED_MODEL: "EMBED_MODEL",
	ENTER_AMOUNT: "ENTER_AMOUNT",
	ENTER_BUILDING_ADDRESS: "ENTER_BUILDING_ADDRESS",
	ENTER_CODE: "ENTER_CODE",
	ENTER_COMPANY: "ENTER_COMPANY",
	ENTER_COMPANY_ADDRESS: "ENTER_COMPANY_ADDRESS",
	SELECT_ITEM_NAME: "SELECT_ITEM_NAME",
	ENTER_COMPANY_NAME: "ENTER_COMPANY_NAME",
	ENTER_EMAIL: "ENTER_EMAIL",
	ENTER_FNAME: "ENTER_FNAME",
	ENTER_LNAME: "ENTER_LNAME",
	ENTER_MESSAGE: "ENTER_MESSAGE",
	ENTER_OFFER_NAME: "ENTER_OFFER_NAME",
	LOD_TIME: "LOD_TIME",
	ENTER_PHONE: "ENTER_PHONE",
	ENTER_TAX_ID: "ENTER_TAX_ID",
	ENTER_REGISTRATION_NUMBER: "ENTER_REGISTRATION_NUMBER",
	ERROR: "ERROR",
	BUILDNG_SIZE: "BUILDNG_SIZE",
	ESTIMATE: "ESTIMATE",
	ESTIMATE_ADJUSTMENT: "ESTIMATE_ADJUSTMENT",
	ESTIMATE_ACCURATE_PRICE: "ESTIMATE_ACCURATE_PRICE",
	ESTIMATE_ACCURATE_PRICE_REGISTER_USER:
		"ESTIMATE_ACCURATE_PRICE_REGISTER_USER",
	ESTIMATE_EMAIL_SUCCESS: "ESTIMATE_EMAIL_SUCCESS",
	ESTIMATE_MESSAGE_TITLE: "ESTIMATE_MESSAGE_TITLE",
	ESTIMATE_SENT: "ESTIMATE_SENT",
	ESTIMATE_TYPE: "ESTIMATE_TYPE",
	ADMINISTRATION: "ADMINISTRATION",
	EXCLUDING_VAT: "EXCLUDING_VAT",
	FIELD_CANNOT_GREATER_THAN: "FIELD_CANNOT_GREATER_THAN",
	FIELD_CANNOT_LOWER_THAN: "FIELD_CANNOT_LOWER_THAN",
	FIELD_MUST_GREATER_THAN: "FIELD_MUST_GREATER_THAN",
	FIELD_REQUIRED: "FIELD_REQUIRED",
	FILE_NAME: "FILE_NAME",
	FILE_NOT_SELECTED: "FILE_NOT_SELECTED",
	FILE_REMOVED: "FILE_REMOVED",
	FILE_UPLOAD: "FILE_UPLOAD",
	FILES: "FILES",
	FILES_SIZE_EXCEEDED: "FILES_SIZE_EXCEEDED",
	FILL_TO_LOGIN: "FILL_TO_LOGIN",
	BUILDNG_SIZE_PLACEHOLDER: "BUILDNG_SIZE_PLACEHOLDER",
	FNAME: "FNAME",
	FORGOT_PASSWORD: "FORGOT_PASSWORD",
	FORM: "FORM",
	FORMATTING: "FORMATTING",
	FULL_SPECIFICATION: "FULL_SPECIFICATION",
	GET_404: "GET_404",
	GET_404_CLICK_BUTTON: "GET_404_CLICK_BUTTON",
	HAVE_ACCOUNT: "HAVE_ACCOUNT",
	ALL_COUNTRIES: "ALL_COUNTRIES",
	COUNTRIES: "COUNTRIES",
	HELP_SCANNING: "HELP_SCANNING",
	HIDE: "HIDE",
	HOURS: "HOURS",
	HOURS_SPENT: "HOURS_SPENT",
	ID: "ID",
	INCLUDING_VAT: "INCLUDING_VAT",
	INCOMPLETE_QUOTE_ERROR: "INCOMPLETE_QUOTE_ERROR",
	CREATE_LOD_TEMPLATE_TEXT: "CREATE_LOD_TEMPLATE_TEXT",
	CREATE_LOD_TEMPLATE_TITLE: "CREATE_LOD_TEMPLATE_TITLE",
	INFO: "INFO",
	INFO_BLOCKS: "INFO_BLOCKS",
	INITIAL_STEP: "INITIAL_STEP",
	GO_TO_HOME: "GO_TO_HOME",
	INVALID_EMAIL: "INVALID_EMAIL",
	INVITATION_RESENT: "INVITATION_RESENT",
	UPLOADING_FILES: "UPLOADING_FILES",
	INVOICE: "INVOICE",
	INVOICE_AMOUNT: "INVOICE_AMOUNT",
	INVOICE_CREATE_ERROR: "INVOICE_CREATE_ERROR",
	INVOICE_CREATE_SUCCESS: "INVOICE_CREATE_SUCCESS",
	INVOICE_DATE: "INVOICE_DATE",
	INVOICE_DUE: "INVOICE_DUE",
	INVOICE_EDIT: "INVOICE_EDIT",
	BIMIFY_ESTIMATE: "BIMIFY_ESTIMATE",
	INVOICE_INFORMATION: "INVOICE_INFORMATION",
	INVOICE_MARK_AS_PAID_ERROR: "INVOICE_MARK_AS_PAID_ERROR",
	INVOICE_MARK_AS_PAID_SUCCESS: "INVOICE_MARK_AS_PAID_SUCCESS",
	BUILDING_CATEGORY_ADJUSTMENTS: "BUILDING_CATEGORY_ADJUSTMENTS",
	INVOICE_NOT_FOUND: "INVOICE_NOT_FOUND",
	INVOICE_QUOTE_SEARCH_LABEL: "INVOICE_QUOTE_SEARCH_LABEL",
	INVOICE_QUOTE_SEARCH_PLACEHOLDER: "INVOICE_QUOTE_SEARCH_PLACEHOLDER",
	INVOICE_REOPEN_ERROR: "INVOICE_REOPEN_ERROR",
	INVOICE_REOPEN_SUCCESS: "INVOICE_REOPEN_SUCCESS",
	INVOICE_UPDATE_ERROR: "INVOICE_UPDATE_ERROR",
	INVOICE_UPDATE_SUCCESS: "INVOICE_UPDATE_SUCCESS",
	INVOICED: "INVOICED",
	INVOICES: "INVOICES",
	JOB_ERROR: "JOB_ERROR",
	JOB_IN_PROGRESS: "JOB_IN_PROGRESS",
	JOB_LIST_ERROR: "JOB_LIST_ERROR",
	JOB_LIST_SUCCESS: "JOB_LIST_SUCCESS",
	JOB_SENT_TO_QUEUE_ERROR: "JOB_SENT_TO_QUEUE_ERROR",
	JOB_SENT_TO_QUEUE_SUCCESS: "JOB_SENT_TO_QUEUE_SUCCESS",
	JOB_STATUS_STEPS: "JOB_STATUS_STEPS",
	JOB_SUCCESS: "JOB_SUCCESS",
	JOB_WAIT: "JOB_WAIT",
	KEY_NOT_FOUND: "KEY_NOT_FOUND",
	LABEL: "LABEL",
	LEFT_TO_INVOICE: "LEFT_TO_INVOICE",
	LEVEL_OF_DETAIL: "LEVEL_OF_DETAIL",
	LNAME: "LNAME",
	LOADING: "LOADING",
	LOCATION: "LOCATION",
	LOD: "LOD",
	LOD_REQUIRED: "LOD_REQUIRED",
	LOG_IN: "LOG_IN",
	LOG_IN_TO_BIMIFY: "LOG_IN_TO_BIMIFY",
	LOG_OUT: "LOG_OUT",
	LOGGED: "LOGGED",
	LOGOS: "LOGOS",
	LOWER_LEVELS: "LOWER_LEVELS",
	LEVELS: "LEVELS",
	MARK_AS_PAID: "MARK_AS_PAID",
	MARKED_AS_PAID_ON: "MARKED_AS_PAID_ON",
	MAX_FILE_SIZE: "MAX_FILE_SIZE",
	MAX_LENGTH_ERROR: "MAX_LENGTH_ERROR",
	MAX_VALUE_ERROR: "MAX_VALUE_ERROR",
	MAX_VALUE: "MAX_VALUE",
	MIN_VALUE: "MIN_VALUE",
	MAX_VALUE_X: "MAX_VALUE_X",
	MEMBER_REASSIGN: "MEMBER_REASSIGN",
	MEMBER_REMOVE: "MEMBER_REMOVE",
	MEMBER_REMOVE_MESSAGE: "MEMBER_REMOVE_MESSAGE",
	MEMBER_UPDATE_ERROR: "MEMBER_UPDATE_ERROR",
	MEMBER_UPDATE_SUCCESS: "MEMBER_UPDATE_SUCCESS",
	MESSAGE: "MESSAGE",
	MESSAGE_TO: "MESSAGE_TO",
	MESSAGES: "MESSAGES",
	METRIC: "METRIC",
	MIN_LENGTH_X: "MIN_LENGTH_X",
	ITEM_ARCHIVE_SUCCESS: "ITEM_ARCHIVE_SUCCESS",
	ITEM_ARCHIVED: "ITEM_ARCHIVED",
	MIN_VALUE_X: "MIN_VALUE_X",
	MINIMUM_FILES_ERROR: "MINIMUM_FILES_ERROR",
	MODELING_SPECIFICATION: "MODELING_SPECIFICATION",
	MODELING_VELOCITY: "MODELING_VELOCITY",
	MONTH: "MONTH",
	MULTIPLE_DRAWING_REFERENCES: "MULTIPLE_DRAWING_REFERENCES",
	MULTIPLE_SELECTION_ALLOWED: "MULTIPLE_SELECTION_ALLOWED",
	MY_ORGANISATION: "MY_ORGANISATION",
	MY_PROFILE: "MY_PROFILE",
	PROFILE: "PROFILE",
	NAME: "NAME",
	PACKAGE_NAME: "PACKAGE_NAME",
	ENTER_PACKAGE_NAME: "ENTER_PACKAGE_NAME",
	NEW_CLIENT: "NEW_CLIENT",
	NEW_COMPANY: "NEW_COMPANY",
	NEW_INVOICE: "NEW_INVOICE",
	NEW_ORDER_CREATE: "NEW_ORDER_CREATE",
	NEW_MANUAL_CREATE: "NEW_MANUAL_CREATE",
	NEW_PASSWORD: "NEW_PASSWORD",
	NEW_QUOTE: "NEW_QUOTE",
	NEW_SPECIFICATION_NAME: "NEW_SPECIFICATION_NAME",
	NEW_SPECIFICATION_NAME_PLACEHOLDER: "NEW_SPECIFICATION_NAME_PLACEHOLDER",
	NEW_USER: "NEW_USER",
	NEW_X: "NEW_X",
	NO: "NO",
	NO_BUILDING_JOBS: "NO_BUILDING_JOBS",
	NO_CLIENT_SPECIFIC_SERVICES_SELECTED: "NO_CLIENT_SPECIFIC_SERVICES_SELECTED",
	NO_FILES_FOUND: "NO_FILES_FOUND",
	NO_MODELING_SERVICES_AVAILABLE: "NO_MODELING_SERVICES_AVAILABLE",
	NO_MODELING_SERVICES_MESSAGE: "NO_MODELING_SERVICES_MESSAGE",
	NO_MODELING_SERVICES_SELECTED: "NO_MODELING_SERVICES_SELECTED",
	NO_OPTIONS_AVAILABLE: "NO_OPTIONS_AVAILABLE",
	NO_ORDERS_FOUND: "NO_ORDERS_FOUND",
	NO_TAKE_ME_BACK: "NO_TAKE_ME_BACK",
	SELECT_PARAMETER: "SELECT_PARAMETER",
	NO_USERS_FOUND: "NO_USERS_FOUND",
	NO_X_FOUND: "NO_X_FOUND",
	NOT_ASSIGNED: "NOT_ASSIGNED",
	NUMBER_OF_BUILDINGS: "NUMBER_OF_BUILDINGS",
	NUMBER_OF_BUILDINGS_PLACEHOLDER: "NUMBER_OF_BUILDINGS_PLACEHOLDER",
	NUMBER_OF_CONSULTANTS: "NUMBER_OF_CONSULTANTS",
	OLD_PASSWORD: "OLD_PASSWORD",
	ON: "ON",
	ORDER: "ORDER",
	ORDER_CANCELED: "ORDER_CANCELED",
	ORDER_DRAWING_REFERENCES_HEADING: "ORDER_DRAWING_REFERENCES_HEADING",
	ORDER_ERROR: "ORDER_ERROR",
	ORDER_NOW: "ORDER_NOW",
	ORDER_SIZE: "ORDER_SIZE",
	ORDER_STEP3_BACK_WARNING: "ORDER_STEP3_BACK_WARNING",
	ORDER_SUCCESS: "ORDER_SUCCESS",
	ORDERS: "ORDERS",
	OVERDUE: "OVERDUE",
	PAID_ON: "PAID_ON",
	PASSWORD: "PASSWORD",
	EMPTY_JOBS: "EMPTY_JOBS",
	PASSWORD_RESET: "PASSWORD_RESET",
	PASSWORD_RESET_INFO: "PASSWORD_RESET_INFO",
	PAYMENT_RECIVED: "PAYMENT_RECIVED",
	PHONE: "PHONE",
	PHONE_NUMBER: "PHONE_NUMBER",
	PHONE_NUMBER_SHORT: "PHONE_NUMBER_SHORT",
	PLAN: "PLAN",
	PLEASE_TRY_AGAIN: "PLEASE_TRY_AGAIN",
	PRICE: "PRICE",
	PRICE_AND_DELIVERY: "PRICE_AND_DELIVERY",
	PRICE_CALCULATION_ERROR_TITLE: "PRICE_CALCULATION_ERROR_TITLE",
	PRICE_CALCULATION_INFO_TITLE: "PRICE_CALCULATION_INFO_TITLE",
	PRICE_ESTIMATE_ERROR: "PRICE_ESTIMATE_ERROR",
	PRICE_EXCL_VAT_LABEL: "PRICE_EXCL_VAT_LABEL",
	PRICE_EXCL_VAT_MESSAGE: "PRICE_EXCL_VAT_MESSAGE",
	PRICE_INCL_VAT_LABEL: "PRICE_INCL_VAT_LABEL",
	PRIMARY_BO_INFO: "PRIMARY_BO_INFO",
	PRIMARY_LABEL: "PRIMARY_LABEL",
	PROFILE_UPDATE_ERROR: "PROFILE_UPDATE_ERROR",
	PROFILE_UPDATE_SUCCESS: "PROFILE_UPDATE_SUCCESS",
	PROGRESS: "PROGRESS",
	PROGRESS_INDICATORS: "PROGRESS_INDICATORS",
	PROJECT_LEAD_LABEL: "PROJECT_LEAD_LABEL",
	STUDENT_LABEL: "STUDENT_LABEL",
	PROJECT_MEMBERS: "PROJECT_MEMBERS",
	PROJECT_SIZE_HEADING: "PROJECT_SIZE_HEADING",
	ORDER_SIZE_LABEL: "ORDER_SIZE_LABEL",
	PROJECT_SIZE_PLACEHOLDER: "PROJECT_SIZE_PLACEHOLDER",
	QUOTE: "QUOTE",
	QUOTE_APPROVE_ERROR: "QUOTE_APPROVE_ERROR",
	ORDER_ACCEPT_SUCCESS: "ORDER_ACCEPT_SUCCESS",
	QUOTE_AUTO_REJECTED: "QUOTE_AUTO_REJECTED",
	QUOTE_EDIT: "QUOTE_EDIT",
	QUOTE_ESTIMATE_ERROR: "QUOTE_ESTIMATE_ERROR",
	QUOTE_ESTIMATE_SUCCESS: "QUOTE_ESTIMATE_SUCCESS",
	QUOTE_ID: "QUOTE_ID",
	QUOTE_INFORMATION: "QUOTE_INFORMATION",
	QUOTE_NOT_FOUND: "QUOTE_NOT_FOUND",
	QUOTE_SAVE: "QUOTE_SAVE",
	QUOTE_SAVE_AVAILABLE_MESSAGE: "QUOTE_SAVE_AVAILABLE_MESSAGE",
	QUOTE_SAVE_UNAVAILABLE_ERROR: "QUOTE_SAVE_UNAVAILABLE_ERROR",
	QUOTE_SEND_FOR_APPROVAL_ERROR: "QUOTE_SEND_FOR_APPROVAL_ERROR",
	QUOTE_SEND_FOR_APPROVAL_SUCCESS: "QUOTE_SEND_FOR_APPROVAL_SUCCESS",
	QUOTE_STATUS_ESTIMATE_INFO_MESSAGE: "QUOTE_STATUS_ESTIMATE_INFO_MESSAGE",
	QUOTE_UNDER_REVIEW_ERROR: "QUOTE_UNDER_REVIEW_ERROR",
	QUOTE_UNDER_REVIEW_SUCCESS: "QUOTE_UNDER_REVIEW_SUCCESS",
	QUOTE_UPDATE_ERROR: "QUOTE_UPDATE_ERROR",
	QUOTE_UPDATE_SUCCESS: "QUOTE_UPDATE_SUCCESS",
	QUOTES: "QUOTES",
	QUOTES_SEARCH: "QUOTES_SEARCH",
	QUOTES_SEARCH_LABEL: "QUOTES_SEARCH_LABEL",
	QUOTES_SEARCH_PLACEHOLDER: "QUOTES_SEARCH_PLACEHOLDER",
	REASSIGN_LABEL: "REASSIGN_LABEL",
	REASSIGN_USER_ERROR: "REASSIGN_USER_ERROR",
	REASSIGN_USER_SUCCESS: "REASSIGN_USER_SUCCESS",
	REFERENCE: "REFERENCE",
	REFERENCE_TYPE: "REFERENCE_TYPE",
	REGISTER: "REGISTER",
	REGISTRATION_NUMBER: "REGISTRATION_NUMBER",
	REJECT_QUOTE: "REJECT_QUOTE",
	CUSTOMISATION: "CUSTOMISATION",
	GENERIC_MODEL: "GENERIC_MODEL",
	REJECT_QUOTE_ERROR: "REJECT_QUOTE_ERROR",
	REJECT_QUOTE_MESSAGE: "REJECT_QUOTE_MESSAGE",
	REJECT_QUOTE_SUCCESS: "REJECT_QUOTE_SUCCESS",
	REJECTED_LABEL: "REJECTED_LABEL",
	REJECTED_QUOTE: "REJECTED_QUOTE",
	REMAINING: "REMAINING",
	REMOVE: "REMOVE",
	REMOVE_FILE: "REMOVE_FILE",
	REMOVE_USER_ERROR: "REMOVE_USER_ERROR",
	REMOVE_USER_SUCCESS: "REMOVE_USER_SUCCESS",
	REOPEN: "REOPEN",
	REOPEN_INVOICE: "REOPEN_INVOICE",
	REOPEN_TEXT_1: "REOPEN_TEXT_1",
	REOPEN_TEXT_2: "REOPEN_TEXT_2",
	REOPEN_TEXT_EDIT_1: "REOPEN_TEXT_EDIT_1",
	REOPEN_TEXT_EDIT_2: "REOPEN_TEXT_EDIT_2",
	REOPEN_TEXT_EDIT_3: "REOPEN_TEXT_EDIT_3",
	REQUEST_CONFIRMATION_LINK: "REQUEST_CONFIRMATION_LINK",
	REQUEST_THANK_YOU: "REQUEST_THANK_YOU",
	REQUEST_VERIFY_EMAIL: "REQUEST_VERIFY_EMAIL",
	REQUIRED: "REQUIRED",
	REQUIRED_SELECTION: "REQUIRED_SELECTION",
	RESELLER_COMPANY: "RESELLER_COMPANY",
	RESELLER_LABEL: "RESELLER_LABEL",
	RESEND_CODE: "RESEND_CODE",
	RESEND_INVITATION: "RESEND_INVITATION",
	BUILDING_CATEGORY_TIME: "BUILDING_CATEGORY_TIME",
	RESET_LINK_SENT: "RESET_LINK_SENT",
	RESET_YOUR_PASSWORD: "RESET_YOUR_PASSWORD",
	RESTORE: "RESTORE",
	REVIEW_DEADLINE: "REVIEW_DEADLINE",
	REVIEW_LABEL: "REVIEW_LABEL",
	RISK: "RISK",
	ROLE_REQUIRED: "ROLE_REQUIRED",
	ROOF: "ROOF",
	SAVE: "SAVE",
	SAVE_AND_PUBLISH: "SAVE_AND_PUBLISH",
	SAVE_AND_UPDATE: "SAVE_AND_UPDATE",
	SAVE_NEW_SERVICE: "SAVE_NEW_SERVICE",
	SAVE_SPECIFICATION: "SAVE_SPECIFICATION",
	SCANNING_HELP: "SCANNING_HELP",
	SCANNING_SERVICES_COMING_SOON: "SCANNING_SERVICES_COMING_SOON",
	SELECT_BUILDING_TYPE: "SELECT_BUILDING_TYPE",
	SELECT_COMPANIES: "SELECT_COMPANIES",
	SELECT_COMPANY: "SELECT_COMPANY",
	SELECT_COMPANY_AND_ROLE: "SELECT_COMPANY_AND_ROLE",
	SELECT_DRAWING_REFERENCES: "SELECT_DRAWING_REFERENCES",
	SELECT_FILE_FROM_LIST: "SELECT_FILE_FROM_LIST",
	SELECT_FORMAT: "SELECT_FORMAT",
	SELECT_LEVEL_OF_DETAIL: "SELECT_LEVEL_OF_DETAIL",
	SELECT_PRIMARY: "SELECT_PRIMARY",
	SELECT_ROLE: "SELECT_ROLE",
	SELECT_SPECIFICATION: "SELECT_SPECIFICATION",
	SELECT_STATUS: "SELECT_STATUS",
	SELECT_SUBSCRIPTION_PLAN: "SELECT_SUBSCRIPTION_PLAN",
	SELECT_TYPE: "SELECT_TYPE",
	SELECT_X: "SELECT_X",
	SELECT_CONTACT_PERSON: "SELECT_CONTACT_PERSON",
	SEND_EMAIL: "SEND_EMAIL",
	SEND_FOR_APPROVAL: "SEND_FOR_APPROVAL",
	SEND_FOR_APPROVAL_MESSAGE: "SEND_FOR_APPROVAL_MESSAGE",
	SEND_INVITATION: "SEND_INVITATION",
	SEND_QUOTE: "SEND_QUOTE",
	SEND_QUOTE_FOR_APPROVAL: "SEND_QUOTE_FOR_APPROVAL",
	SEND_QUOTE_TO_CLIENT_FOR_APPROVAL: "SEND_QUOTE_TO_CLIENT_FOR_APPROVAL",
	SEND_RESET_LINK: "SEND_RESET_LINK",
	SEND_TO_FOR_APPROVAL: "SEND_TO_FOR_APPROVAL",
	SENDING: "SENDING",
	SENDING_TO: "SENDING_TO",
	SENT_FOR_APPROVAL: "SENT_FOR_APPROVAL",
	SERVICE_DESCRIPTION: "SERVICE_DESCRIPTION",
	SERVICE_DESCRIPTION_PLACEHOLDER: "SERVICE_DESCRIPTION_PLACEHOLDER",
	SERVICE_NAME: "SERVICE_NAME",
	SERVICE_NAME_PLACEHOLDER: "SERVICE_NAME_PLACEHOLDER",
	SESSION_EXPIRED: "SESSION_EXPIRED",
	SIGN_UP: "SIGN_UP",
	SIGN_UP_MESSAGE: "SIGN_UP_MESSAGE",
	SIGN_UP_TO_BIMIFY: "SIGN_UP_TO_BIMIFY",
	SIZE_LABEL: "SIZE_LABEL",
	SNACKBARS: "SNACKBARS",
	SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
	SPECIFICATION: "SPECIFICATION",
	SPECIFICATION_DESCRIPTION: "SPECIFICATION_DESCRIPTION",
	SPECIFICATION_DESCRIPTION_NONE: "SPECIFICATION_DESCRIPTION_NONE",
	SPECIFICATION_DESCRIPTION_PLACEHOLDER:
		"SPECIFICATION_DESCRIPTION_PLACEHOLDER",
	SPECIFICATION_PRICE: "SPECIFICATION_PRICE",
	SPECIFICATION_VELOCITY: "SPECIFICATION_VELOCITY",
	STATUS: "STATUS",
	STATUS_ACTION_INFO: "STATUS_ACTION_INFO",
	STATUS_DATE: "STATUS_DATE",
	SUBSCRIPTION: "SUBSCRIPTION",
	SUBSCRIPTION_PLAN: "SUBSCRIPTION_PLAN",
	SUCCESS: "SUCCESS",
	SUMMARY_LABEL: "SUMMARY_LABEL",
	SUPPORTED_FILE_FORMAT: "SUPPORTED_FILE_FORMAT",
	SUPPORTED_FILE_FORMAT_TEXT: "SUPPORTED_FILE_FORMAT_TEXT",
	TABLE_OF_CONTENTS: "TABLE_OF_CONTENTS",
	TABS: "TABS",
	TAKE_ME_BACK: "TAKE_ME_BACK",
	TAX_ID: "TAX_ID",
	TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
	TERMS_AND_CONDITIONS_INFO: "TERMS_AND_CONDITIONS_INFO",
	TERMS_AND_CONDITIONS_LABEL: "TERMS_AND_CONDITIONS_LABEL",
	THANK_YOU: "THANK_YOU",
	THANK_YOU_FOR_CHOOSING: "THANK_YOU_FOR_CHOOSING",
	TIME: "TIME",
	TIME_ESTIMATE: "TIME_ESTIMATE",
	TIME_ESTIMATE_SNACKBAR: "TIME_ESTIMATE_SNACKBAR",
	TIME_SPENT: "TIME_SPENT",
	TIME_TO_LEFT: "TIME_TO_LEFT",
	TOTAL: "TOTAL",
	TOTAL_AREA: "TOTAL_AREA",
	TOTAL_PRICE: "TOTAL_PRICE",
	TOTAL_SIZE_LABEL: "TOTAL_SIZE_LABEL",
	TRANSLATING_MODEL: "TRANSLATING_MODEL",
	TRASH: "TRASH",
	TRY_AGAIN: "TRY_AGAIN",
	TYPE: "TYPE",
	TYPE_LABEL: "TYPE_LABEL",
	TYPE_MESSAGE: "TYPE_MESSAGE",
	UNDER_REVIEW: "UNDER_REVIEW",
	UNPAID: "UNPAID",
	UNSUPPORTED_DRAWING_REFERENCES: "UNSUPPORTED_DRAWING_REFERENCES",
	UPDATE_DATE: "UPDATE_DATE",
	UPDATE_X: "UPDATE_X",
	UPDATED: "UPDATED",
	UPLOAD_DELIVERABLE_FILE_ERROR: "UPLOAD_DELIVERABLE_FILE_ERROR",
	UPLOAD_DELIVERABLE_FILE_SUCCESS: "UPLOAD_DELIVERABLE_FILE_SUCCESS",
	UPLOAD_FILES: "UPLOAD_FILES",
	UPLOAD_NEW_FILE: "UPLOAD_NEW_FILE",
	UPLOAD_PROFILE_IMAGE: "UPLOAD_PROFILE_IMAGE",
	UPLOAD_X_FILE: "UPLOAD_X_FILE",
	UPLOADED: "UPLOADED",
	UPLOADED_ITEMS: "UPLOADED_ITEMS",
	UPLOADING: "UPLOADING",
	UPLOADING_ITEMS: "UPLOADING_ITEMS",
	ZIP_IN_PROGIRESS: "ZIP_IN_PROGIRESS",
	UPPER_LEVELS: "UPPER_LEVELS",
	USER: "USER",
	USER_ACTIVATION_LINK_SUCCESSFULLY_SENT:
		"USER_ACTIVATION_LINK_SUCCESSFULLY_SENT",
	USER_ACTIVE_ERROR: "USER_ACTIVE_ERROR",
	USER_ACTIVE_MESSAGE: "USER_ACTIVE_MESSAGE",
	USER_ARCHIVE_ERROR: "USER_ARCHIVE_ERROR",
	USER_ARCHIVE_MESSAGE: "USER_ARCHIVE_MESSAGE",
	USER_REQUEST_EMAIL_SENT: "USER_REQUEST_EMAIL_SENT",
	USER_UPDATE_ERROR: "USER_UPDATE_ERROR",
	USERS: "USERS",
	UTILS: "UTILS",
	VALID_LABEL: "VALID_LABEL",
	VALID_UNTIL_LABEL: "VALID_UNTIL_LABEL",
	VAT_LABEL: "VAT_LABEL",
	VERIFICATION_CODE_MESSAGE: "VERIFICATION_CODE_MESSAGE",
	VERIFICATION_MESSAGE: "VERIFICATION_MESSAGE",
	VERIFY: "VERIFY",
	VIEW: "VIEW",
	VIEW_X: "VIEW_X",

	WARNING: "WARNING",
	WELCOME_TO_BIMIFY: "WELCOME_TO_BIMIFY",
	WORKDAYS: "WORKDAYS",
	WORKHOURS: "WORKHOURS",
	WRONG_FILE_FORMAT: "WRONG_FILE_FORMAT",
	OPENINGS: "OPENINGS",
	YES: "YES",
	YOU: "YOU",
	YEAR: "YEAR",
	ZERO: "ZERO",
	ACCEPT_PRIVACY_POLICY: "ACCEPT_PRIVACY_POLICY",
};

const LOC = {
	ACCEPT_BIMIFY_ESTIMATE: "Accept estimate",
	ACCEPT_DELIVERABLE: "Accept deliverable",
	ACCEPT_DELIVERABLE_UNAVAILABLE: "Accept deliverable unavailable",
	MODELING: "Modeling",
	SYMBOLS: "Symbols",
	WHO_HANDLES_ORDERS_FOR_THIS_COMPANY: "Allow customer to handle orders?",
	FAMILIES: "Families",
	ATTACHMENTS: "Attachments",
	SEARCH_FOR_JOB: "Search for job",
	ACCEPT_ESTIMATE_INFO:
		"Please review the Bimify estimate and ACCEPT if correct or fill in a new estimate and SAVE.",
	ACCEPT_QUOTE: "Accept quote",
	JOB_INFO: "Job info",
	ACCEPT_TERMS_AND_CONDITIONS: "Please accept terms and conditions",
	MODEL_LINK: "Model link (A360)",
	ARCHICAD_LABEL: "Archicad",
	CATEGORY_PARAMETERS: "Category parameters",
	ACCEPT_PRIVACY_POLICY: "Please accept Privacy Policy and Terms of Service",
	ACCEPTED_LABEL: "Accepted",
	ACCEPTED_QUOTE: "Accepted quote {0}.",
	NORMALISATION: "Normalisation",
	NEW_LOD_SPECIFICATION: "New specification",
	DONE: "Done",
	ACCORDION: "Accordion",
	ACTIONS_BUSINESS_OWNER: "{0} Business Owner",
	ACTIONS_PROJECT_LEAD: "{0} Project Lead",
	ACTIVATE: "Activate",
	ACTIVATE_COMPANY: "Activate company",
	FOUNDATION:
		"Foundation (Foundations and Wall foundations with footing where applicable)",
	ACTIVATE_COMPANY_ERROR:
		"Something went wrong while trying to activate a company, please try again!",
	ACTIVATE_COMPANY_MESSAGE:
		"Are you sure you want to activate this company? {0} users related to it will be activated as well!",
	ACTIVATE_COMPANY_SUCCESS:
		" The company and its {0} users were successfully activated!",
	ACTIVE_USER: "Activate user",
	REMOVE_THUMBNAIL: "Remove thumbnail",
	THUMBNAIL: "Thumbnail",
	COMPONENT: "Component",
	ACTIVE: "Active",
	DXFP_SERVER: "DXFP server",
	ADD: "Add",
	TICKET_NAME: "Ticket name",
	ADD_ANOTHER_USER: "Add another user",
	ADD_CUSTOM_SERVICE: "Add custom service",
	ADD_USER: "Add user",
	ADDRESS: "Address",
	ADD_X: "Add {0}",
	ADDITIONAL_SERVICES: "Additional services",
	ADJUSTMENT: "Adjustment",
	GENERAL_INFO: "General info",
	SERVICES: "Services",
	PAGE_NOT_EXIST: "The page you are looking for does not exist.",
	ADMIN_LABEL: "Admin",
	AMOUNT: "Amount",
	DEVELOPMENT_CONSTANTS: "Development constants",
	APPROVE_QUOTE: "Approve quote",
	ARCHIVE: "Archive",
	ARCHIVE_COMPANY: "Archive company",
	PRICE_FROM: "Price from",
	NEW_PACKAGE: "New package",
	ARCHIVE_COMPANY_ERROR:
		"Something went wrong while trying to arhive a company, please try again!",
	ARCHIVE_COMPANY_MESSAGE:
		"Are you sure you want to archive this company? {0} users related to it will be archived as well!",
	ARCHIVE_COMPANY_SUCCESS:
		" The company and its {0} users were successfully archived!",
	ARCHIVE_USER: "Archive user",
	AREA: "Area",
	ASSIGN: "Assign",
	ATTACH: "Attach",
	SERVICE: "Service",
	NEW_BUILDING: "New building",
	AWAITING_APPROVAL: "AWAITING APPROVAL",
	AWAITING_APPROVAL_LABEL: "Approve",
	FUNCTION: "Function",
	BACK: "Back",
	BACK_TO_LIST: "Back to list",
	BACK_TO_LOGIN: "Back to login",
	PRICE_ADJUSTMENT: "Price adjustment / Building category",
	MIN_OFFER_PRICE: "Min. offer price",
	LOD_ADJUSTMENTS: "LOD adjustments",
	LOD_TIME: "LOD time",
	BACK_TO_X: "Back to {0}",
	BIMIFY_CONTACT: "Bimify contact",
	BIMIFY_LABEL: "Bimify",
	UPDATE_DELIVERY_FORMAT_VERSION_LABEL:
		"Please update delivery format to supported version.",
	BROWSE_TO_UPLOAD: "Browse file to upload",
	SUPPLIER_NAME: "Supplier name",
	QA_MANUALS: "Quality assurance manuals",
	HANDLES_ORDERS: "Customer handles orders",
	CUSTOM_LEVELS_ERROR: "Levels must be greater or equal to Unique levels.",
	CUSTOMISATION_MANUALS: "Customisation manuals",
	NEW_COMPONENT_TYPE: "New component type",
	NORMALISATION_MANUALS: "Normalisation manuals",
	ITEM: "Item",
	BUILDING: "Building",
	BUILDING_ACCEPT_DELIVERABLE:
		"This building will be considered as completed. Are you sure that you have checked that all deliverables match your order?",
	BUILDING_ADDRESS: "Building address",
	ADD_COMPONENT: "Add component",
	BUILDING_DELIVERABLES: "DELIVERABLES",
	ADD_NEW_ITEM: "Add new item",
	BUILDING_DETAILS: "BUILDING DETAILS",
	UPDATE_STATUS: "Update status",
	BUILDING_ESTIMATE_ERROR_MESSAGE:
		"If you have selected multiple drawing references or created a requirement for a new service, the order needs to be analysed by our team in order to provide the price and delivery date.",
	BUILDING_ESTIMATE_GUEST_SUCCESS:
		"To get a more accurate price please login or signup and provide more details about your building(s).",
	BUILDING_ESTIMATE_SUCCESS:
		"To get a more accurate price please fill in the detailed information about each building below.",
	BUILDING_INFO: "Building info",
	BUILDING_NAME: "Building name",
	BUILDING_PROGRESS: "Building progress",
	BUILDING_SPECIFICATION: "Building specification",
	DOWNLOAD_SELECTED: "Download selected",
	LOD_NAME: "LOD name",
	TITLE: "Title",
	SELECT_COMPANY_ADMIN: "Select company admin",
	ENTER_MANUAL_NAME: "Enter manual name",
	ADD_A_COMMENT: "Add a comment",
	BUILDING_STATUS: "Update building status",
	BUILDING_STATUS_RESTORE: "Are you sure you want to restore this building?",
	BUILDING_STATUS_RESTORE_ERROR:
		"Something went wrong while trying to restore a building, please try again!",
	BUILDING_STATUS_RESTORE_SUCCESS: "The building was successfully restored!",
	BUILDING_STATUS_TRASH:
		"Are you sure you want to move this building to trash?",
	JOB_ID: "Job ID",
	SELECT_FUNCTION: "Select function",
	BUILDING_STATUS_TRASH_ERROR:
		"Something went wrong while trying to trashed a building, please try again!",
	BUILDING_STATUS_TRASH_SUCCESS:
		"The building was successfully moved to trash!",
	BUILDING_TYPE: "Building type",
	LOD_SPECIFICATIONS: "BIM model specifications",
	BUILDING_UPDATE_ERROR:
		"Something went wrong while updating the building, please try again.",
	JOB_SPECIFICATION: "Job specification",
	BUILDING_UPDATE_SUCCESS: "Your building has been updated successfully!",
	BUILDINGS: "Buildings",
	BUILDINGS_OVERWRITE_WARNING:
		"If you change these values, any buildings that you've previously created/edited will be overwritten, including all customization data.",
	BUSINESS_OWNER_LABEL: "Business Owner",
	JOB_TYPE: "Job type",
	ADDED: "added",
	JOB_CONFIGURATION: "Job configuration",
	BACK_TO_HOME: "Back to home",
	BUTTON: "Button",
	SELECT_PROCESS_STEP: "Select process step",
	BUTTON_STYLES: "Button styles",
	BY: "by",
	REMOVE_IMAGE: "Remove image",
	CANCEL: "Cancel",
	CANCEL_ORDER: "Cancel offer",
	CANCEL_UPLOAD: "Cancel upload",
	CANCELED_ESTIMATE_ERROR: "There has been an error canceling the estimate.",
	CANCELED_ESTIMATE_MESSAGE:
		"Are you sure you want to cancel the estimate #{0}?",
	CANCELED_ESTIMATE_SUCCESS: "You have successfully canceled the estimate.",
	OTHER_SALE_ITEMS: "Other sale items",
	CANCELED_LABEL: "Canceled",
	CANCELED_ORDER_ERROR: "There has been an error canceling the offer.",
	PRICING: "Pricing",
	DECLINED_ORDER_SUCCESS: "Offer declined.",
	SEARCH_FOR_BUILIDNG: "Search for building",
	DECLINED_ORDER_MESSAGE: "Are you sure you want to decline the offer?",
	CARD_STYLES: "Card styles",
	EMAIL_FILE: "Email file",
	CARDS: "Cards",
	SET_DUE_DATE: "Set due date",
	CHANGE_PASSWORD: "Change password",
	CHANGE_PASSWORD_SUCCESS: "Your password has been updated successfully!",
	CHATROOM_HISTORY_EMPTY: `You have not exchanged any messages with {0}.`,
	CHATROOM_HISTORY_END: "There are no more messages.",
	NEW_TEMPLATE: "New template",
	CHATROOM_HISTORY_START: `This is the start of your conversation with {0}.`,
	CHATROOM_NOTES_INTRO: `This is your space. Draft messages, list your to-dos, or keep links and files handy. You can also talk to yourself here, but please bear in mind you'll have to supply both sides of the conversation.`,
	CHATROOM_NOTES_NAME: "This space is just for you",
	CHATROOM_USER_FORBIDDEN: "You cannot chat with this user.",
	CHOOSE_NEW_PASSWORD: "Choose new password",
	ENTER_TICKET_NAME: "Enter ticket name",
	ADD_NEW: "Add new",
	CLEAR_SELECTION: "Clear selection",
	PARAMETERS: "Parameters",
	COMPONENT_TYPES: "Component types",
	CLIENT: "Customer",
	CLIENT_COMPANY: "Customer company",
	CLIENT_LABEL: "Customer",
	CLIENT_NAME: "Customer name",
	CLIENT_SPECIFIC_SERVICE: "Customer specific service",
	ARE_YOY_SURE_DELETE_MESSAGE: "Are you sure you want to delete",
	COUNTRY: "Country",
	CLIENT_SPECIFIC_SERVICES: "Customer specific services",
	CLIENTS: "Customers",
	EMPTY_COMPANIES:
		"You have not created any companies yet. Click on the button above to create the first company.",
	EMPTY_USER:
		"You have not created any users yet. Click on the button above to create the first user.",
	CLOSE: "Close",
	CODE: "Code",
	CONTENT: "Content",
	COMING_SOON: "Coming soon",
	PAGE_COMING_SOON:
		"Another valuable feature that will be released soon. Stay tuned!",
	COMPANIES: "Companies",
	GO_TO_HOME: "Click here to go home",
	SEARCH_FOR_OFFER: "Search for offer",
	TEMPLATE_NAME: "Template name",
	FAMILY: "Family",
	COMPONENT_TYPE: "Component type",
	ENTER_TEMPLATE_NAME: "Enter new template name",
	SAVE_NEW_TEMPLATE: "Save new template",
	COMPANY: "Company",
	COMPANY_ADD_ERROR: "Something went wrong!",
	SELECT_TYPE_OF_FIELD: "Select type of field",
	COMPANY_ADDRESS: "Company address",
	SELECT_NEW_PARAMETER: "Select new parameter",
	COMPANY_ERROR: "Something went wrong!",
	COMPANY_INFO: "Company info",
	COMPANY_NAME: "Company name",
	COMPANY_NOT_FOUND: "No company found.",
	COMPANY_TYPE: "Company type",
	NEW_PARAMETER: "New parameter",
	BILLING_DETAILS: "Billing details",
	DOWNLOAD_ALL: "Download all",
	COMPANY_TYPE_REQUIRED: "Company type is required",
	SUPPORTED_FILE_FORMAT_PACKAGE: "Supported format (.rvt files)",
	COMPLETED_LABEL: "Completed",
	AUTOMATISATION: "Automatisation",
	CONFRIM_NEW_PASSWORD: "Confirm new password",
	CONTACT: "Contact",
	CONTACT_PERSON: "Contact person",
	CONTACT_PERSON_INFO: `Only users with Admin role can be assigned as a contact person for the company.`,
	CONVERSATION_EXPLANATION:
		" **CTRL + Return** to send **Return** to add new line",
	CONVERSATIONS: "Conversations",
	COPIED: "Copied",
	DEFAULT: "Default",
	DELIVER: "Deliver",
	NEW_SYMBOL: "New symbol",
	COPY: "Copy",
	COPY_HTML_TO_USE_X: "Copy this HTML to use this {0}.",
	CREATE_NEW: "Create new",
	NEW_OFFER: "New offer",
	NEW_MANUAL: "New manual",
	NEW_FAMILY: "New family",
	MODELING_TIME: "Modeling time",
	SELECT_ITEM: "Select item",
	CREATE_NEW_SPECIFICATION: "Create new specification",
	CREATE_ORDER: "Create offer",
	EMAIL_OFFER: "Email offer",
	DELETE_TYPE: "Delete type",
	ENTER_FAMILY_NAME: "Enter family name",
	SYMBOL: "Symbol",
	CREATE: "Create",
	CREATE_ORDER_LOADING_MODAL_MESSAGE:
		"Please do not refresh the page and wait for the building files to be uploaded. This can take a minute or two.",
	CREATE_ORDER_LOADING_MODAL_MESSAGE_FILELESS:
		"Please do not refresh the page and wait, your {0} is being saved.",
	CREATE_SPECIFICATION: "Create specification",
	FAILED_MODEL_MESSAGE:
		"Something went wrong while translating model. The file might be corrupted. Please try again with a new file",
	CREATE_USER_ERROR:
		"Something went wrong while creating the user, please try again.",
	CATEGORIES: "Categories",
	ADD_NEW_CATEGORY: "Add new category",
	SELECT_CATEGORY: "Select category",
	CUSTOMER_NAME: "Customer name",
	OFFER_NAME: "Offer name",
	CREATED: "Created",
	SELECT_ASSIGNEE: "Select assignee",
	CURRENTLY_UNAVAILABLE: "Currently unavailable",
	CREATE_LOD_TEMPLATE_TEXT: "Save configuration as a new template.",
	DOWNLOAD_FILES: "Download files",
	CREATE_LOD_TEMPLATE_TITLE: "SPECIFY NEW LOD TEMPLATE",
	SAVE_LOD_TEMPLATE_TITLE: "OVERRIDE LOD TEMPLATE",
	SAVE_LOD_TEMPLATE_TEXT:
		"This will override the existing LOD template **{0}**. Are you sure you want to continue?",
	DAYS: "days",
	DEFAULT_BUTTON_CLASSES: "Default button classes",
	MODELS: "Models",
	NO_DUE_DATE_SET: "No due date set",
	HIGH: "High",
	DEFAULT_LEVEL_HEIGHT: "Default level height",
	DEFAULT_VALUES: "Use the values as default",
	DEFAULT_VALUES_EXPLANATION:
		"Service addition will be added as default for the services. Risk addition will be added as default for the specification.",
	DELETE_FILE: "Are you sure you want to delete {0}?",
	DELETE_FILE_ERROR_MESSAGE: "Something went wrong while deleting file.",
	DELETE_SUCCESS_MESSAGE: "{0} deleted.",
	DELETE_FILES: "Are you sure you want to delete {0} files?",
	DELETE_USERS_SUCCESS_MESSAGE: "Users successfully deleted.",
	DELIVERABLE: "Deliverable",
	PRICE_EXCL_VAT_CELL: "Price (excl. VAT)",
	DELIVERABLE_DOWNLOAD_DISABLED: "Download not available before confirming",
	DELIVERABLE_REQUIRED: "At least one delvierable has to be selected",
	DELIVERABLES: "Deliverables",
	IMAGE_2D: "2D representation",
	IMAGE_3D: "3D representation",
	DELIVERY: "Delivery",
	DELIVERY_DAYS: "Delivery days",
	DELIVERY_LABEL: "Delivery",
	DELIVERY_TIME_LABEL: "Delivery time",
	DESIGNATOR_CLASS: "Designator class",
	ALL_COMPONNETS: "All components",
	OPEN_DXF_SETTINGS: "Open DXF settings",
	DISABLED: "Disabled",
	DOCS: "Docs",
	DOCS_BUTTONS_DIRECTION: "Click on a button to see an example of use.",
	DOCS_FORM_DIRECTION:
		"Click on a button next to the field to see a usage example; as well as available variants.",
	DOCS_FORM_NOTE: "DOCS_FORM_NOTE",
	DOCS_SNACKBAR_DIRECTION: "DOCS_SNACKBAR_DIRECTION",
	START_TYPING: "Start typing",
	PRICE_ADJUSTMENT_LABEL: "Price adjustment",
	CONSULTANT_ESTIMATE: "Consultant estimate",
	VELOCITY: "Velocity",
	OVERVIEW: "Overview",
	DOCS_SNACKBAR_NOTE: "* Note: Click outside to close snackbar.",
	DOWNLOAD: "Download",
	DOWNLOAD_INVOICE: "Download invoice",
	UPLOAD_FROM_COMPUTER: "Upload from computer",
	DRAG_AND_UPLOAD_FILES: "Drag and drop your file here or",
	DRAWING_REFERENCE: "Drawing reference",
	DRAWING_REFERENCE_REQUIRED: "At least one reference has to be selected",
	DRAWING_REFERENCES: "Drawing references",
	ADMIN_INFO: "Admin info",
	SELECT_OWNER: "Select owner",
	SELECT_PROJECT_LEAD: "Select Project lead",
	PROJECT_LEAD: "Project lead",
	BIMIFY_PROGRESS_WAITING:
		"Waiting for server to start. This can take a minute.",
	JOB_NAME: "Job name",
	ENTER_TEXT: "Enter text",
	LAST_UPDATE: "Last update",
	LAST_UPDATED: "Last updated",
	NO_IMAGE_UPLOADED_YET: "No image uploaded yet",
	DUE_DATE: "Due date",
	EDIT: "Edit",
	EDIT_ESTIMATE: "Edit estimate",
	EDIT_PROFILE_IMAGE: "Edit profile image",
	EDIT_SPECIFICATION_PRICE: "Edit specification price",
	EDIT_USER: "Edit user",
	PRINT: "Print",
	EMAIL_FILES: "Email files",
	EDIT_X: "Edit {0}",
	EMAIL: "Email",
	EMAIL_ADDRESS: "Email address",
	BO: "BO",
	UNIQUE_LEVELS: "Unique levels",
	UNIQUE_LEVELS_EXPLANATION: `Number of levels that differ from other levels, 
	including both small differences (one extra door or wall) 
	and significant differences (completely different topology).`,
	ACCEPT_OFFER: "Accept offer",
	PRICE_SUMMARY: "Price summary",
	EMAIL_ESTIMATE: "Email estimate",
	SELECT_EXPORTS: "Select exports",
	SELECT_MANUALS: "Select manuals",
	EMAIL_NOT_CORRECT: "Email address is not correct",
	OWNER: "Owner",
	EMAIL_SENT: "Email sent",
	EMAIL_UPDATE_SUCCESS: "Thank you! Your new email has been confirmed.",
	EMAIL_PENDING:
		"New email address not confirmed yet. Confirmation link sent to ",
	EMAILED_TO: "Emailed to",
	EMBED_MODEL: "Embed Model",
	ALL_FAMILIES: "All families",
	FROM: "From",
	ENTER_AMOUNT: "Enter amount",
	ENTER_BUILDING_ADDRESS: "Enter building address",
	ENTER_CODE: "Enter your code",
	CRM_SERVER: "CRM server",
	ENTER_COMPANY: "Enter company",
	ENTER_COMPANY_ADDRESS: "Enter company address",
	ENTER_COMPANY_NAME: "Enter company name",
	ENTER_EMAIL: "Enter email",
	INPUT: "Input",
	OUTPUT: "Output",
	ENTER_FNAME: "Enter first name",
	SYMBOL_CATEGORISATION: "Symbol categorisation",
	ENTER_LNAME: "Enter last name",
	ENTER_MESSAGE: "Enter your message here",
	BIMIFY: "Bimify",
	ENTER_OFFER_NAME: "Enter offer name",
	MY_TICKETS: "My tickets",
	ENTER_PHONE: "Enter phone number",
	LOW: "Low",
	EMPTY_PLACHOLDER: "-",
	TO: "To",
	ADD_RECIPIENT: "Add recipient",
	ENTER_TAX_ID: "Enter Tax ID",
	CUSTOM_ADJUSTMENT: "Custom adjustment",
	ENTER_REGISTRATION_NUMBER: "Enter company registration number",
	ERROR: "Error",
	ESTIMATE: "Estimate",
	ESTIMATE_ADJUSTMENT: "Estimate adjustment",
	ESTIMATE_ACCURATE_PRICE:
		"To get a more accurate price, fill in the correct information for each building below.",
	ESTIMATE_ACCURATE_PRICE_REGISTER_USER:
		"To get a more accurate estimate, please login or sign up.",
	CREATE_SYMBOL: "Create symbol",
	TICKETS: "Tickets",
	AUTOMATISATION_CONSTANTS: "Automatisation constants",
	ESTIMATE_EMAIL_SUCCESS: "The estimate has been emailed to **{0}**!",
	ESTIMATE_MESSAGE_TITLE: "This step only available for registered users.",
	ESTIMATE_SENT: "Estimate sent",
	SELECT_PRIORITY: "Select priority",
	OFFER_TYPE: "Offer type",
	ESTIMATE_TYPE: "Estimate ({0})",
	ENTER_NAME: "Enter name",
	EXCLUDING_VAT: "excl. VAT",
	FIELD_CANNOT_GREATER_THAN: "{0} cannot be greater than {1}",
	FIELD_CANNOT_LOWER_THAN: "{0} cannot be lower than {1}",
	FIELD_MUST_GREATER_THAN: "{0} has to be greater than {1}",
	FIELD_CANNOT_LOWER_OR_EQUAL_THAN: "{0} cannot be lower or equal to {1}.",
	JOB_CANCELED: "Job canceled",
	COMMENTS: "Comments",
	FIELD_REQUIRED: "{0} is required",
	FILE_NAME: "File name",
	ADJUSTED_PRICE: "Adjusted price",
	SETTINGS: "Settings",
	FILE_NOT_SELECTED: "File not selected",
	NEW_TICKET: "New ticket",
	UPLOAD_FILE: "Upload file",
	PLACEHOLDER_HOUR: "0,0",
	ENTER_COMMENT: "Enter comment",
	DOWNLOAD_FILE: "Download file",
	FILE_REMOVED: "File removed",
	FILE_UPLOAD: "File upload",
	CANCELING_JOB: "Canceling job",
	SEND: "Send",
	ADD_NEW_PARAMETER: "Add new parameter",
	COMMENT: "Comment",
	REVIT_LABEL: "Revit",
	FILES: "Files",
	FAMILY_MAPPING: "Family mapping",
	FILES_SIZE_EXCEEDED:
		"File(s) you're trying to upload exceed allowed size. Maximum size is {0}. Please try with smaller files or contact support.",
	FILL_TO_LOGIN: "Fill in new password in the fields below.",
	FNAME: "First name",
	FORGOT_PASSWORD: "Forgot your password?",
	FIELD_TYPE: "Field type",
	ADD_IMAGE: "Add image",
	FINISH: "Finish",
	FORM: "Form",
	FORMATTING: "Formatting",
	FULL_SPECIFICATION: "Full specification",
	GET_404: "Looks like you don't have any offers created.",
	GET_404_CLICK_BUTTON: "Click the button below to get started.",
	HAVE_ACCOUNT: "I already have an account",
	BUILDING_OWNER: "Building owner",
	BIMIFY_SUPPORT: "Bimify support",
	MEDIUM: "Medium",
	OFFER: "Offer",
	HELP_SCANNING: "Do you need help to scan your building?",
	HIDE: "Hide",
	SUPPLIER: "Supplier",
	HOURS: "hours",
	HOURS_SPENT: "hours spent",
	IMAGE_MISSING: "Image missing",
	ID: "ID",
	INCLUDING_VAT: "incl. VAT",
	INCOMPLETE_QUOTE_ERROR:
		"Please edit the quote first and add the required data",
	INFO: "Info",
	INFO_BLOCKS: "Info blocks",
	VIEWER: "Viewer",
	INITIAL_STEP: "Initial step",
	INVALID_EMAIL: "Invalid email",
	LEGAL: "Legal",
	SYSTEM_SETTINGS: "System settings",
	INVITATION_RESENT: "Invitation resent",
	BIMIFY_MODEL_SAVING: "Bimify model saving",
	INVOICE: "Invoice",
	INVOICE_AMOUNT: "Invoice amount",
	INVOICE_CREATE_ERROR:
		"Something went wrong while sending the invoice, please try again.",
	INVOICE_CREATE_SUCCESS: "The invoice has been successfully created!",
	INVOICE_DATE: "Invoice date",
	UPATE_BIM_MODEL_INFO: `**The feature will be released soon.**&nbsp;  
		It will give you a possibility to inform
		us about changes in your building so
		that we can **update** your digital model 
		to reflect those changes.&nbsp;  
		That way, you will always have 
		a digital model that represents 
		the actual state of your building.`,

	ASSIGNEE: "Assignee",
	NOBODY_ASSIGNED: "Nobody assigned",
	RESELLER_HANDLES_ORDERS: "Assigned reseller",
	INVOICE_DUE: "Invoice due",
	INVOICE_EDIT: "Edit invoice",
	I_ACCEPT: "I accept",
	ADD_NEW_TYPE: "Add new type",
	CITY: "City",
	PRIVACY_POLICY: "Privacy Policy",
	INVOICE_INFORMATION: "Invoice information",
	SELECT_BUSINESS_OWNER: "Select business owner",
	SPECIFICATION_OWNER: "Specification owner",
	SELECT_SPECIFICATION_OWNER: "Select specification owner",
	WORKSTEP: "Workstep",
	ELAVATION_INFO_MESSAGE:
		"Must be at least 1 000 mm higher than the level below and lower than the level above.",
	ELAVATION_ERROR_MESSAGE: "Wrong number!",
	BIM_MODEL: "BIM model",
	ADD_COMMENT: "Add comment",
	INPUT_NORMALISATION: "Input normalisation",
	SET_DATE: "Set date",
	STATUS_MESSAGE: "Are you sure you want to change the {0} status to {1}?",
	DESCRIPTION: "Description",
	CONFIGURATION: "Configuration",
	INVOICE_MARK_AS_PAID_ERROR:
		"An error occurred while marking the invoice as paid. Please try again.",
	INVOICE_MARK_AS_PAID_SUCCESS:
		"Invoice #{0} has been successfully marked as paid.",
	ENTER_SUMMARY: "Enter summary",
	INVOICE_NOT_FOUND: "No invoice found.",
	NEW_ASSIGNEE: "New Assignee",
	REPETITIVENESS_ADJUSTMENTS: "Repetitiveness adjustment",
	REPETITIVENESS_SAVING_ADJUSTMENTS: "Repetitiveness saving adjustment",
	TIME_REPETITIVENESS_SAVING_ADJUSTMENTS:
		"Time repetitiveness saving adjustment",
	INVOICE_QUOTE_SEARCH_LABEL:
		"Try typing quote ID, project, building, or client name",
	PARAMETER: "Parameter",
	INVOICE_QUOTE_SEARCH_PLACEHOLDER:
		"Type to search quote or select from dropdown",
	PARAMETER_ALREADY_EXISTS: "Parameter with this name already exists!",
	INVOICE_REOPEN_ERROR:
		"An error occurred while marking the invoice as paid. Please try again.",
	SELECT_BUILDING: "Select building",
	FILE_UPLOADED: "File uploaded.",
	SELECT_DISCIPLINE: "Select discipline",
	PENDING: "Pending",
	INVOICE_REOPEN_SUCCESS: "Invoice #{0} has been successfully reopened.",
	INVOICE_UPDATE_ERROR: "Something went wrong while updating invoice.",
	INVOICE_UPDATE_SUCCESS: "Invoice updated.",
	NORMALIZER_LABEL: "Normaliser",
	PACKAGE: "Package",
	FAMILY_NAME: "Family name",
	MODELING_PRICES: "Modeling prices",
	CUSTOMIZER_LABEL: "Customiser",
	EXPORTS: "Exports",
	GUEST_LABEL: "Guest",
	QUALITY_CONTROL_LABEL: "Quality Controler",
	ARE_YOU_SURE_DELETE: "Are you sure you want to delete **{0}**?",
	INVOICED: "Invoiced",
	ADDITIONAL_INFORMATION: "Additional info",
	TWOFA_CODE_MESSAGE: "Please enter the code to verify your email.",
	ADDITIONAL_INFORMATION_PLACEHOLDER: "Describe your requirements here",
	BUILDING_CATEGORY: "Building category",
	SUCCESS_DELETE_BUILDING_COMPONENT: "Delete building component.",
	SUCCESS_DELETE_LOD: "Delete LOD.",
	SUCCESS_DELETE_DISCIPLINE: "Delete discipline.",
	SUCCESS_DELETE_EXPORT: "Delete export.",
	LEVELS_AND_GRIDS: "Levels and grids",
	POSTAL_CODE: "Postal code",
	EXTERIOR_WALLS: "Exterior walls",
	MANUAL: "Manual",
	COMPANY_ADMIN_REQUIRED: "Company admin is required",
	INTERIOR_WALLS: "Interior walls",
	OPENINGS: "Openings (elevator, shaft)",
	BUILDING_LEVELS: "Building levels",
	CURTAIN_WALLS: "Curtain walls",
	COLUMNS: "Columns",
	FLOORS: "Floors",
	TWOFA: "Two-Factor Authentication",
	BEAMS_BRACES_TRUSSES: "Beams / braces / trusses",
	REUPLOAD_IMAGE: "Reupload image",
	AUTOMATED_PRICING: "Automated pricing",
	SUPPORT: "Support",
	EMAIL_NUMBER_FILES_BUILDING: "Email ({0}) files - {1}",
	USE_DA4REVIT_API: "Run CRMServer on Autodesk Platform Services (APS)",
	USE_QUEUE:
		"Run CRMServer on Autodesk Platform Services (APS). (Running local backup solution in Revit).",
	CONFIRM_USE_DA4REVIT_API:
		"Do you want to turn on CRMServer on Autodesk Platform Services (APS)?",
	CONFIRM_USE_QUEUE:
		"Do you want to turn off CRMServer on Autodesk Platform Services (APS)? The locally deployed CRMServer in Revit will be used instead.",
	ROOFS: "Roofs",
	SELECT_PARAMETER: "Select parameter",
	ROOFS_ACCESSSORIES:
		"Roof accesssories (walkways, ladders, vents and similar)",
	CATEGORY_ALREADY_EXISTS: "Category with this name already exists!",
	CEILINGS: "Ceilings",
	STAIRS: "Stairs",
	RAILINGS: "Railings",
	ELECTRICAL_CONDUITS: "Electrical Conduits",
	CUSTOMISATION_TIME: "Customisation time",
	PRIORITY: "Priority",
	LOD_TIME_ADJUSTMENTS: "LOD time adjustments",
	ENTER_ADDRESS: "Enter address",
	READING_TEMPLATE: "Reading template",
	PRICE_ADJUSTMENT_PER_BUILDING_CATEGORY:
		"Price adjustment per building category",
	TIME_ADJUSTMENT_PER_BUILDING_CATEGORY:
		"Time adjustment per building category",
	LIGHTING_FIXTURE: "Lighting Fixture",
	CABLE_TRAY_FITTINGS: "Cable Tray Fittings",
	COMPANY_ADMIN: "Company admin",
	STANDARD_DISCOUNT: "Standard discount",
	TARGET_BUILDING_TYPE: "Target building type",
	ALL_CUSTOMERS: "All customers",
	SELECT_TARGET_BUILDING_TYPE: "Select target building type",
	BUILDING_COMPONENT: "Building component",
	ADD_NEW_BUILDING_COMPONENT: "Add new building components",
	COMPONENT_NAME: "Component name",
	COMPONENTS: "Components",
	DEVELOPMENT_TIME: "Development time",
	DISCIPLINE: "Discipline",
	ADD_NEW_DISCIPLINE: "Add new discipline",
	ADD_NEW_LOD: "Add new LOD",
	OPTIONAL: "Optional",
	CABLE_TRAYS: "Cable Trays",
	PLUMBING_FIXTURES: "Plumbing Fixtures",
	MODELING_COMPLETED: "Modeling completed.",
	SPECIFICATION_SUMMARY: "Specification summary",
	MODELING_FAILED: "Modeling failed.",
	PIPE_FITTINGS: "Pipe Fittings",
	SELECT_RESELLER: "Select reseller",
	PIPES: "Pipes",
	MECHANICAL_EQUIPMENT: "Mechanical Equipment",
	PUBLISH_FILES_TO_BUILDING: "Publish files to building",
	GENERATE_PDF: "Generate PDF",
	GENERATING_PDF: "Generating PDF",
	DOWNLOAD_AS_PDF: "Download PDF",
	DUCT_FITTINGS: "Duct Fittings",
	DUCTS: "Ducts",
	DOORS: "Doors",
	WINDOWS: "Windows",
	SUNPROTECTION_SHUTTERS_BLINDS: "Sun protection / shutters / blinds",
	MEP: "MEP openings in architecture",
	SANITARY_EQUIPMENT: "Sanitary equipment (plumbing fixtures)",
	PRICE_ADJUSTMENTS_PER_LOD: "Price adjustment per LOD",
	TIME_ADJUSTMENTS_PER_LOD: "Time adjustment per LOD",
	TICKETING: "Ticketing",
	INPUT_FILES: "Input files",
	APPLIANCES: "Appliances (stove, fridge and similar)",
	RECALCULATE_PRICE: "Recalculate prices",
	RECALCULATE_ESTIMATE: "Recalculate estimate",
	CASEWORK: "Casework",
	LOOSE_FURNITURE: "Loose furniture (sofa, chair, table, etc.)",
	ROOMS: "Rooms",
	PACKAGE_NAME: "Package name",
	UPLOAD_NOW: "Upload now",
	DELETE_CATEGORY_MESSAGE:
		"By deleting this category, all the related parameters and  LOD content will be deleted.\n If you want to keep the related parameters and LOD content, re-assign the related parameters to another category.",
	UPLOAD_LATER: "Upload later",
	BUSINESS_TYPE: "Business type",
	COMPANY_ROLE: "Company role",
	ENTER_PACKAGE_NAME: "Enter package name here",
	ENTER_SHORT_DESCRIPTION: "Enter a short description of the package",
	SELECT_OTPUT_FORMAT: "Select output format",
	SELECT_INPUT_FORMAT: "Select input format",
	SELECT_EXPORT_FILES: "Select export files",
	SELECT_PREDEFINED_LOD: "Select a predefined BIM model specification",
	INVOICES: "Invoices",
	JOB_ERROR:
		"Something went wrong with {0}, please try again later or contact our support.",
	JOB_IN_PROGRESS:
		"If you want to see job progress please don't leave this screen until job is completed.",
	JOB_LIST_ERROR: "failed to bimify",
	JOB_LIST_SUCCESS: "bimified",
	JOB_SENT_TO_QUEUE_ERROR: "Error sending job",
	TEXT_INPUT_FIELD: "Text input field",
	CATEGORY_NAME: "Category name",
	IMAGE: "Image",
	CAD: "CAD",
	POINT_CLOUD: "Point cloud",
	JOB_SENT_TO_QUEUE_SUCCESS: "Job sent to queue!",
	PACKAGE_INFO: "Package info",
	MODEL_CONTENT: "Model content",
	TOTAL_CUSTOMERS: "Total customers",
	JOB_STATUS_STEPS: "Step {0} of {1} currently working on...",
	JOB_SUCCESS: "Job {0} completed successfully!",
	BUILDING_TYPES: "Building types",
	BUILDING_CATEGORIES: "Building categories",
	ATTACH_FILES: "Attach files",
	NO_TEMPLATES_FOUND: "No templates found.",
	JOB_WAIT: "Please wait...",
	KEY_NOT_FOUND: "Key not found: {0}",
	VISUAL_REPRESENTATION: "Visual representation",
	LABEL: "Label",
	LEFT_TO_INVOICE: "Left to invoice",
	STATUS_UPDATED: "Status updated.",
	TICKET: "Ticket",
	LEVEL_OF_DETAIL: "Level of Detail",
	CHOOSE_BUILDING: "Choose building",
	LNAME: "Last name",
	SELECT_FAMILY: "Select family",
	DELETE_PARAMETER_MESSAGE:
		"Parameter **{0}** is assigned to **{1}** building categories. When deleted, the parameter and the related content will disappear from the categories the parameter is assigned to.",
	LOADING: "Loading",
	LOCATION: "Location",
	LOD: "LOD",
	PL: "PL",
	DRAG_AND_DROP: "Drag and drop or",
	LOD_REQUIRED: "At least one lod has to have a value",
	ENTER_X: "Enter {0}",
	COMPANY_ADMIN_FIRST_ASSIGNMENT:
		"Please assign a company admin first to enable order handling permissions",
	LOG_IN: "Log in",
	LOG_IN_TO_BIMIFY: "Log in to Bimify",
	SERVER_ERROR:
		"The platform is currently under maintenance, we'll be up and running again soon.",
	LOG_OUT: "Log Out",
	SIZE: "Size",
	JOB: "Job",
	LOGGED: "logged",
	VAT_NUMBER: "VAT number",
	SELECT_LOD_SPECIFICATION: "Select BIM model specification",
	LOGOS: "Logos",
	LOWER_LEVELS: "Lower levels",
	LEVELS: "Levels",
	MARK_AS_PAID: "Mark as paid",
	MARKED_AS_PAID_ON: "marked as paid on",
	MAX_FILE_SIZE: "Maximum file size: {0}",
	ASSIGNEES_STATUS: "Normalisation / Customisation / QA",
	MAX_LENGTH_X: "Max length for this field is {0}",
	MAX_VALUE_X: "Max value for this field is {0}",
	MEMBER_REASSIGN: "Reassign member",
	CUSTOMER: "Customer",
	NO_TICKETS_FOUND: "No tickets found.",
	SELECT_CUSTOMER: "Select customer",
	DELIVERY_INFO: "Delivery info",
	MEMBER_REMOVE: "Remove member",
	SEARCH: "Search",
	PUBLISH: "Publish",
	ELEVATION: "Elevation",
	THICKNESS: "Thickness",
	ALL: "All",
	PREVIEW: "Preview",
	MEMBER_REMOVE_MESSAGE:
		"Are you sure you want to remove this member from the project?",
	MEMBER_UPDATE_ERROR:
		"There has been an error updating member. Please try again.",
	MEMBER_UPDATE_SUCCESS: "You have successfully updated member.",
	MESSAGE: "Message",
	SELECT_ITEM_NAME: "Select item name",
	MESSAGE_TO: "Message to {0}",
	DECLINE_OFFER: "Decline offer",
	ITEM_NAME: "Item name",
	MESSAGES: "Messages",
	SEND_OFFER: "Send offer",
	UPLOAD_INVOICE: "Upload invoice",
	METRIC: "Metric",
	MIN_LENGTH_X: "Min length for this field is {0}",
	MIN_VALUE_X: "Min value for this field is {0}",
	UPLOADING_FILE: "Uploading file...",
	CREATE_BIM_MODEL: "Create BIM model",
	UPDATE_BIM_MODEL: "Update BIM model",
	MINIMUM_FILES_ERROR: "At least one file has to be uploaded",
	MODELING_SPECIFICATION: "Modeling specification",
	MODELING_VELOCITY: "Modeling velocity",
	BUILDING_SIZE: "Building size",
	MONTH: "Month",
	MULTIPLE_DRAWING_REFERENCES: "Multiple drawing references",
	MULTIPLE_SELECTION_ALLOWED: "You can select multiple items",
	BUILDING_CATEGORY_TIME_ADJUSTMENTS: "Building category time adjustments",
	MY_ORGANISATION: "My organisation",
	MY_PROFILE: "Profile",
	SUBTOTAL: "Subtotal",
	PROFILE: "Profile",
	NAME: "Name",
	NEW_CLIENT: "New Customer",
	CHECKBOX: "Check box",
	NEW_COMPANY: "New {0}",
	COMPLEXITY_ADJUSTMENT: "Complexity adjustment",
	NEW_INVOICE: "New invoice",
	WE_ARE_HERE_FOR_YOU: "We are here for you!",
	SELECT_DELIVERY_FORMAT: "Select delivery format",
	NEW_ORDER_CREATE: "Created this offer.",
	ALL_COMPONENT_TYPES: "All component types",
	NEW_MANUAL_CREATE: "New manual created",
	NEW_PASSWORD: "New password",
	NEW_QUOTE: "New quote",
	NEW_SPECIFICATION_NAME: "New specification name",
	FINISH_ESTIMATION_REVIEW: "Estimation review finished.",
	TYPE_NAME: "Type name",
	CATEGORY: "Category",
	ADD_NEW_BUILDING_TYPE: "Add new building type",
	ADD_NEW_BUILDING_CATEGORY: "Add new building category",
	NEW_SPECIFICATION_NAME_PLACEHOLDER: "Enter new specification name",
	CUSTOMIZATION_DESCRIPTION: "Customization description",
	UPDATED_ESTIMATE: "Updated estimate",
	BIMIFY_ESTIMATE: "Bimify estimate",
	TEMPLATES: "Templates",
	ACTIVITY: "Activity",
	CHOOSE_LOD: "Choose LOD",
	DELETE_COMPONENT_TYPE:
		"Are you sure you want to delete this type? \n It is used for symbol categorisation.",
	ROWS: "Rows",
	MAP_FILES: "Map files",
	USER_ROLE: "User role",
	CHOOSE_FOMRAT: "Choose format",
	NEW_USER: "New user",
	ENGINEERING_DISCIPLINES: "Engineering disciplines",
	BUILDING_COMPONENTS: "Building components",
	NEW_JOB: "New job",
	JOBS: "Jobs",
	NEW_X: "New {0}",
	NEXT: "Next",
	NO: "No",
	NO_BUILDING_JOBS: "There are no building jobs.",
	DASHBOARD: "Dashboard",
	NO_CLIENT_SPECIFIC_SERVICES_SELECTED:
		"You have not selected any customer specific services for this building.",
	NO_FILES_FOUND: "You have not uploaded any files yet.",
	NO_MODELING_SERVICES_AVAILABLE: "No modeling services available for {0}.",
	ARCHICAD_VERSION: "ArchiCAD version",
	NO_MODELING_SERVICES_MESSAGE:
		"Modeling services for this discipline will be added soon.",
	NO_MODELING_SERVICES_SELECTED: "No modeling services selected for {0}.",
	NO_OPTIONS_AVAILABLE: "No options available",
	NO_ORDERS_FOUND: "No offers found.",
	NO_TAKE_ME_BACK: "No, take me back",
	PACKAGE_OWNER: "Package owner",
	ENTER_DESCRIPTION: "Enter description",
	SELECT_PACKAGE_OWNER: "Select package owner",
	NO_USERS_FOUND: "No users found.",
	EXPORT: "Export",
	NO_X_FOUND: "No {0} found.",
	NOT_ASSIGNED: "Not assigned",
	NUMBER_OF_BUILDINGS: "No. of buildings",
	NUMBER_OF_BUILDINGS_PLACEHOLDER: "Enter a number of buildings...",
	NUMBER_OF_CONSULTANTS: "Number of consultants",
	JOB_DESCRIPTION: "Job description",
	ROLE: "Role",
	OLD_PASSWORD: "Old password",
	CANCEL_MESSAGE: "Are you sure you want cancel offer?",
	SELECT_DISCIPLINES: "Select disciplines",
	VERSION: "Version",
	ON: "on",
	ORDER: "Order",
	ORDER_CANCELED: "Canceled this offer.",
	CLIENT_HANDLES_ORDERS: "Customer",
	TOTAL_ORDER: "Total order",
	PASSWORD_UPDATED: "Password updated.",
	ORDER_DRAWING_REFERENCES_HEADING: "What drawing references do you have?",
	TERMS_AND_CONDITIONS_ACCEPT: "I accept Terms and Conditions",
	BUILDING_CATEGORY_ADJUSTMENTS: "Building category adjustments",
	PRICE_ADJUSTMENT_PLACEHOLDER: "Price adjustment",
	BUILDING_CATEGORY_TIME: "Building category time",
	ORDER_ERROR:
		"Something went wrong while creating the offer, please try again.",
	ENTER_BUILDING_NAME: "Enter building name",
	REVIT_VERSION: "Revit version",
	ORDER_NOW: "Order now",
	DECLINE_ORDER_HEADING: "Decline {0}",
	LOD_SPECIFICATION: "BIM model specification",
	ORDER_SIZE: "Order size",
	ORDER_STEP3_BACK_WARNING:
		"If you go back, all the existing buildings will be lost, including customization data and new buildings will be generated.",
	ORDER_SUCCESS:
		"Assuming that all we have all the neccessary information about your offer, you will get the final price and delivery date for approval within",
	ORDERS: "Orders",
	OVERDUE: "overdue",
	PAID_ON: "paid on",
	PASSWORD: "Password",
	PASSWORD_RESET: "Password reset",
	OFFER_TERMS_AND_CONDITIONS: "Offer Terms and Conditions",
	PASSWORD_RESET_INFO:
		"A password reset instruction link will be sent to this email address.",
	PAYMENT_RECIVED: "Payment received",
	PHONE: "Phone",
	PHONE_NUMBER: "Phone number",
	PHONE_NUMBER_SHORT: "Phone number is too short",
	UNCATEGORISED_OBJECT: "Uncategorised objects",
	PLAN: "Plan",
	PLEASE_TRY_AGAIN: "Please try again.",
	PRICE: "Price",
	CUSTOMISATION: "Customisation",
	ALL_TYPES: "All types",
	PRICE_AND_DELIVERY: "Price & delivery estimate",
	PRICE_CALCULATION_ERROR_TITLE: "The price cannot be calculated automatically",
	PRICE_CALCULATION_INFO_TITLE: "This is just an estimate",
	PRICE_ESTIMATE_ERROR: "An error occurred getting your estimate.",
	PRICE_EXCL_VAT_LABEL: "Total price excl. VAT",
	PRICE_EXCL_VAT_MESSAGE: "All the prices displayed are excl. VAT.",
	PRICE_INCL_VAT_LABEL: "Total price incl. VAT",
	FILES_DELIVERED: "Files delivered.",
	PRIMARY_BO_INFO: `This user will be added as Super Admin for this company.
	An invitation will be sent to User’s email.`,
	PRIMARY_LABEL: "Primary",
	PROFILE_UPDATE_ERROR:
		"Something went wrong while updating your profile. Please try again.",
	PROFILE_UPDATE_SUCCESS: "Profile updated.",
	THANK_YOU_FOR_REQUEST: "Thank you for your request!",
	SUCCESS_CREATE_OFFER_MESSAGE:
		"Please upload input files for review so we can provide the final price and delivery time to you.",
	EMPTY_LOD_SPECIFICATION:
		"You have not created any BIM model specification yet. Click on the button above to create your first BIM model specification.",
	PROGRESS: "Progress",
	PROGRESS_INDICATORS: "Progress indicators",
	PROJECT_LEAD_LABEL: "Project Lead",
	ORDER_INFO: "Order info",
	STUDENT_LABEL: "Student",
	PROJECT_MEMBERS: "Project members",
	ALL_COUNTRIES: "All countries",
	TERMS_OF_SERVICE: "Terms of Service",
	AND: "and",
	COUNTRIES: "Countries",
	PROJECT_SIZE_HEADING: "What is the size of your project?",
	ORDER_SIZE_LABEL: "Order size",
	CREATE_TICKET: "Create ticket",
	PROJECT_SIZE_PLACEHOLDER: "Order size",
	QUOTE: "Quote",
	QUOTE_APPROVE_ERROR: "There has been an approving the quote.",
	ORDER_ACCEPT_SUCCESS: "Offer accepted.",
	LOD_CONTENT: "LOD content",
	QUOTE_AUTO_REJECTED: "Quote auto rejected. Due date for approval passed.",
	QUOTE_EDIT: "Edit quote",
	ITEM_CREATED: "{0} created.",
	ITEM_UPDATED: "{0} updated.",
	ITEM_DELETED: "{0} deleted.",
	QUALITY_ASSURANCE: "Quality assurance",
	ITEM_CREATE_SUCCESS: "The {0} has been successfully created!",
	WHO_HANDLES_ORDERS_FOR_THIS_COMPANY_INFO:
		"If the customer is to place orders themselves,\n ensure that an Admin user is added to their account. \n If the reseller will manage orders on behalf of the customer,\n adding an Admin user is optional.",
	QUOTE_ESTIMATE_ERROR:
		"Something went wrong while creating your estimate, please try again",
	QUOTE_ESTIMATE_SUCCESS: "You have successfully saved your estimate!",
	QUOTE_ID: "Quote ID",
	QUOTE_INFORMATION: "Quote information",
	ASSIGNEES: "Assignees",
	QUOTE_NOT_FOUND: "No quotes found.",
	UPDATE_ORDER_STATUS: "Update order status.",
	UPDATE_JOB_STATUS: "Job status updated.",
	CREATE_JOB: "Create job",
	CREATE_MANUAL: "Create manual",
	EDIT_MANUAL: "Edit manual",
	BUILDNG_SIZE: "Building size",
	BUILDNG_SIZE_PLACEHOLDER: "Enter building size",
	QUOTE_SAVE: "Save quote",
	QUOTE_SAVE_AVAILABLE_MESSAGE: "Save quote and return to it later",
	QUOTE_SAVE_UNAVAILABLE_ERROR:
		"You cannot save a quote before Building specification step",
	QUOTE_SEND_FOR_APPROVAL_ERROR:
		"There has been an error sending the quote for approval.",
	QUOTE_SEND_FOR_APPROVAL_SUCCESS:
		"You have successfully sent the quote for approval.",
	QUOTE_STATUS_ESTIMATE_INFO_MESSAGE:
		"This is only an estimate that provides indicative price and delivery time. In order to get the final price, please create an order and provide drawing references for analyses.",
	QUOTE_UNDER_REVIEW_ERROR:
		"There has been an error updating the review by date.",
	QUOTE_UNDER_REVIEW_SUCCESS:
		"You have successfully updated the review by date.",
	DEFAULT_VAT: "Default VAT",
	IMAGE_NOT_AVAILABLE: "Image not available.",
	QUOTE_UPDATE_ERROR:
		"There has been an error updating the quote. Please try again.",
	VIEW_DETAILS: "View details",
	QUOTE_UPDATE_SUCCESS: "You have successfully updated the quote.",
	QUOTES: "Quotes",
	QUOTES_SEARCH: "Try typing quote ID, project, building, or client name",
	QUOTES_SEARCH_LABEL: "Select a quote",
	QUOTES_SEARCH_PLACEHOLDER: "Search for a quote or select from the list",
	JOB_SPECIFIC_DESCRIPTION: "Job specific description",
	REASSIGN_LABEL: "Reassign",
	FAMILY_TYPE: "Family type",
	CHOOSE_FILE: "Choose file",
	REASSIGN_USER_ERROR:
		"Something went wrong while assigning {0}. Please try again.",
	REASSIGN_USER_SUCCESS: "{0} has been assigned as the {1}.",
	REFERENCE: "Reference",
	REFERENCE_TYPE: "Reference type",
	RESOLVE: "Resolve",
	REGISTER: "Register",
	REGISTRATION_NUMBER: "Registration number",
	REJECT_QUOTE: "Reject quote",
	REJECT_QUOTE_ERROR: "There has been an rejecting the quote.",
	REJECT_QUOTE_MESSAGE:
		"By rejecting this quote order **{0}** will be canceled.",
	REJECT_QUOTE_SUCCESS:
		"You have successfully rejected the quote and cancelled the project.",
	REJECTED_LABEL: "Rejected",
	DELETE: "Delete",
	REJECTED_QUOTE: "Rejected quote {0}.",
	SELECT_COMPONENT: "Select component",
	REMAINING: "remaining",
	REMOVE: "Remove",
	CUSTOMERS: "Customers",
	ERROR_ITEM_DELETED: "Something went wrong while deleting {0}.",
	ERROR_ITEM_UPDATED: "Something went wrong while updating {0}.",
	ERROR_ITEM_CREATED: "Something went wrong while creating {0}.",
	MANUALS: "Manuals",
	REMOVE_FILE: "Remove file",
	REMOVE_USER_ERROR:
		"Something went wrong while removing the user. Please try again.",
	REMOVE_USER_SUCCESS: "{0} has been removed from the project.",
	SPECIFICATION_NAME: "Specification name",
	MIN_PRICE: "Min.  price",
	EDIT_COMPONENT_TYPE: "Edit component type",
	CREATE_COMPONENT_TYPE: "Create component type",
	ENTER_SPECIFICATION_NAME: "Enter specification name",
	REOPEN: "Reopen",
	REOPEN_INVOICE: "Reopen invoice",
	REOPEN_TEXT_1: "Are you sure you want to re-open the invoice",
	REOPEN_TEXT_2: "The invoice will be marked as",
	REOPEN_TEXT_EDIT_1:
		"In order to edit the invoice it needs to be reopened first.",
	REOPEN_TEXT_EDIT_2: "By reopening the invoice will be marked as unpaid.",
	REOPEN_TEXT_EDIT_3: "Do you want to reopen the invoice",
	REQUEST_CONFIRMATION_LINK: "We have sent a confirmation link to",
	REQUEST_THANK_YOU: "Thank you for your request {0}!",
	REQUEST_VERIFY_EMAIL:
		"Please click on the link to verify your email address.",
	REQUIRED: "Required",
	EMPTY_SYMBOLS: "There are no symbols to be displayed at this moment",
	EMPTY_TEMPLATES: "There are no templates to be displayed at this moment",
	ADMINISTRATION: "Administration",
	REQUIRED_SELECTION: "This selection is required.",
	RESELLER_COMPANY: "Reseller company",
	RESELLER_LABEL: "Reseller",
	RESEND_CODE: "Resend code",
	RESEND_INVITATION: "Resend invitation",
	RESET_LINK_SENT: "An email with a password reset link has been sent to",
	RESET_YOUR_PASSWORD: "Reset your password",
	OTHER: "Other",
	ENGINEERING_DICIPLINES: "Engineering disciplines",
	ARCHITECTURE: "Architecture",
	STRUCTURE: "Structure",
	MECHANICAL: "Mechanical",
	ELECTRICAL: "Electrical",
	PLUMBING: "Plumbing",
	RESTORE: "Restore",
	REVIEW_DEADLINE: "Review deadline",
	REVIEW_LABEL: "Review",
	RISK: "Risk",
	ROLE_REQUIRED: "Role is required",
	ROOF: "Roof",
	DISCOUNT: "Discount",
	SAVE: "Save",
	SAVE_AND_PUBLISH: "Save and publish",
	ENTER_LEVEL_NAME: "Enter level name",
	SAVE_AND_UPDATE: "Save and update",
	SAVE_NEW_SERVICE: "Save new service",
	GENERIC_MODEL: "Generic model",
	SAVE_SPECIFICATION: "Save specification",
	SCANNING_HELP: "Scanning help",
	SCANNING_SERVICES_COMING_SOON: "Scanning services coming soon",
	SELECT_BUILDING_TYPE: "Select building type",
	SELECT_COMPANIES: "Select companies",
	SELECT_COMPANY: "Select company",
	SELECT_COMPANY_AND_ROLE: "SELECT COMPANY AND ROLE",
	SELECT_DRAWING_REFERENCES: "SELECT DRAWING REFERENCES",
	SELECT_FILE_FROM_LIST: "Select file from list",
	SELECT_FORMAT: "Select format",
	SELECT_LEVEL_OF_DETAIL: "SELECT LEVEL OF DETAIL",
	DECLINE: "Decline",
	SELECT_PRIMARY: "Select primary",
	PERCENTAGE: "Percentage",
	SELECT_ROLE: "SELECT ROLE",
	SELECT_SPECIFICATION: "Select specification",
	SELECT_STATUS: "Select status",
	EMPTY_ORDERS:
		"You have not created any orders yet. Click on the button above to create your first order.",
	EMPTY_MANUALS: "You have not created any manuals yet",
	EMPTY_FAMILIES: "You have not created any families yet.",
	EMPTY_BUILDINGS: "You have not created any buildings yet.",
	EMPTY_JOBS: "You have not created any jobs yet",
	RELATED_TO_ME: "Related to me",
	NO_BUILDINGS_FOUND: "No buildings found",
	NO_JOBS_FOUND: "No jobs found",
	SELECT_SUBSCRIPTION_PLAN: "Select subscription plan",
	SELECT_TYPE: "Select type",
	SELECT_X: "Select {0}",
	SELECT_CONTACT_PERSON: "Select contact person",
	SEND_EMAIL: "Send email",
	SEND_FOR_APPROVAL: "Send for approval",
	SEND_FOR_APPROVAL_MESSAGE: "Sent quote {0} for approval.",
	SEND_INVITATION: "Send invitation",
	SEND_QUOTE: "Send quote",
	SEND_QUOTE_FOR_APPROVAL: "Send quote to {0} for approval?",
	SEND_QUOTE_TO_CLIENT_FOR_APPROVAL: "Send quote to {0} for approval",
	SEND_RESET_LINK: "Send reset password link",
	SEND_TO_FOR_APPROVAL: "Send to {0} for approval",
	SENDING: "Sending",
	SENDING_TO: "Sending to",
	SENT_FOR_APPROVAL: "Sent for approval",
	SERVICE_DESCRIPTION: "Service description",
	SERVICE_DESCRIPTION_PLACEHOLDER:
		"Enter new customer specific service description",
	SERVICE_NAME: "Service name",
	SERVICE_NAME_PLACEHOLDER: "Enter new customer specific service name",
	SESSION_EXPIRED: "Your session has expired",
	SIGN_UP: "Sign up",
	UPLOAD_MODEL_FILE: "Upload model file",
	SAVE_AND_LOGIN: "Save and login",
	SIGN_UP_MESSAGE: "Don't have an account?",
	SIGN_UP_TO_BIMIFY: "Sign up to Bimify",
	SIZE_LABEL: "Size",
	SNACKBARS: "Snackbars",
	SOMETHING_WENT_WRONG: "Something went wrong",
	SPECIFICATION: "Specification",
	SPECIFICATION_DESCRIPTION: "Specification description",
	NONE: "NONE",
	SELECT_TEMPLATE: "Select template",
	EDIT_SYMBOL: "Edit symbol",
	EDIT_FAMILY: "Edit family",
	CREATE_FAMILY: "Create family",
	SPECIFICATION_DESCRIPTION_NONE:
		"A description has not been added for this specification",
	SPECIFICATION_DESCRIPTION_PLACEHOLDER: "Enter specification description",
	SPECIFICATION_PRICE: "Specification price",
	SPECIFICATION_VELOCITY: "Specification velocity",
	STATUS: "Status",
	STATUS_ACTION_INFO: "{0} by **{1}** **{2}** on **{3}**",
	STATUS_DATE: "Status date",
	SUBSCRIPTION: "Subscription",
	OFFERS: "Offers",
	SUBSCRIPTION_PLAN: "Subscription plan",
	SUCCESS: "Success!",
	SUMMARY_LABEL: "Summary",
	OFFER_INFO: "Offer info",
	SUPPORTED_FILE_FORMAT: "Supported file formats",
	STARTING_FROM: "Starting from",
	IMPORT: "Import",
	SUPPORTED_FILE_FORMAT_TEXT:
		"Only one file format can be used for the configuration",
	TABLE_OF_CONTENTS: "Table of contents",
	TABS: "Tabs",
	MAX_VALUE: "Max value",
	MIN_VALUE: "Min value",
	TAKE_ME_BACK: "Take me back",
	TAX_ID: "Tax ID",
	TERMS_AND_CONDITIONS: "Terms and Conditions",
	TERMS_AND_CONDITIONS_INFO:
		'By accepting the terms and conditions, and clicking the "Sign up" button, you give Bimify permission to use your information, including phone number to send you automated text messages for the purpose of confirming or changing your account information. Bimify will only use your phone number to send you transactional SMS messages, in response to an action you have done (ie. Signup, Login, Changing your account information). SMS communication will exclusively be used to send one-time tokens and confirmation messages for the purpose of Two-Factor Authentication (2FA). You will never receive any promotional content from Bimify, nor will Bimify share your personal information with any 3rd party service. Message and data rates may apply.',
	TERMS_AND_CONDITIONS_LABEL:
		"I accept Terms and Conditions and Privacy Policy",
	THANK_YOU: "Thank you",
	THANK_YOU_FOR_CHOOSING: "Thank you for choosing Bimify!",
	TIME: "Time",
	ADD_PARAMETER: "Add parameter",
	TIME_ESTIMATE: "Time estimate / Time report",
	TIME_ESTIMATE_SNACKBAR: "Update time estimate / time report",
	TIME_SPENT: "Time spent",
	SELECT_VERSION: "Select version",
	TIME_TO_LEFT: "Time to left",
	SEND_REQUEST: "Send request",
	TOTAL: "Total",
	ALL_ROLES: "All roles",
	TEMPLATE_WARNING_MESSAGE:
		"The selected template can not be found. \n The model will be generated in Bimify's default template. \n If you want to use another template, please select the right template in the template settings.",
	TOTAL_AREA: "Total area",
	TOTAL_PRICE: "Total price",
	TOTAL_SIZE_LABEL: "Total size",
	TRANSLATING_MODEL: "Translating model, please wait",
	UPLOADING_FILES: "Uploading files, please wait",
	TRASH: "Move to trash",
	TRY_AGAIN: "Try again",
	TYPE: "Type",
	NO_PARAMETERS_FOUND: "No parameters found.",
	TYPE_LABEL: "Type",
	TYPE_MESSAGE: "Type a message",
	UNDER_REVIEW: "UNDER REVIEW",
	UNPAID: "unpaid",
	UNSUPPORTED_DRAWING_REFERENCES:
		"Image & Point Cloud drawing reference automation is not supported. However, our algorithms are fast learners so we will be able to support you soon.",
	UPDATE_DATE: "Update date",
	UPDATE_X: "Update {0}",
	UPDATED: "Updated",
	UPLOAD_DELIVERABLE_FILE_ERROR: "Error upload deliverable file!",
	UPLOAD_DELIVERABLE_FILE_SUCCESS: "File uploaded successfully.",
	UPLOAD_FILES: "Upload files",
	UPLOAD_NEW_FILE: "Upload new file",
	SELECT_OPTIONS: "Select options",
	UPLOAD_PROFILE_IMAGE: "Upload profile image",
	UPLOAD_X_FILE: "Upload {0} file",
	UPLOADED: "Uploaded",
	UPLOADED_ITEMS: "Items uploaded",
	UPLOADING: "Uploading",
	UPLOADING_ITEMS: "Uploading {0} item",
	ZIP_IN_PROGIRESS: "Zip in progress",
	UPPER_LEVELS: "Upper levels",
	INPUT_FORMAT: "Input format",
	PROCESS_STEP: "Process step",
	DELIVERY_FORMAT: "Delivery format",
	TEMPLATE_LABEL: "Template",
	USER: "User",
	USER_ACTIVATION_LINK_SUCCESSFULLY_SENT:
		"Activation link has been successfully sent to **{0}**!",
	USER_ACTIVE_ERROR:
		"Something went wrong while trying to activate an user, please try again!",
	USER_ACTIVE_MESSAGE: "Are you sure you want to activate this user?",

	ITEM_ACTIVE_SUCCESS: "{0} activated.",
	RESELLER_NAME: "Reseller name",
	USER_ARCHIVE_ERROR:
		" Something went wrong while trying to arhive an user, please try again!",
	USER_ARCHIVE_MESSAGE: "Are you sure you want to archive this user?",
	ITEM_ARCHIVE_SUCCESS: "{0} archived.",
	ITEM_ARCHIVED: "{0} archived.",
	USER_REQUEST_EMAIL_SENT: `Request to add user has been send to {0}. Once user accepts invitation he or she will be active in users list.`,
	USER_UPDATE_ERROR: "An error ocurred while trying to update the user.",
	USERS: "Users",
	UTILS: "Utils",
	VALID_LABEL: "Valid",
	VALID_UNTIL_LABEL: "Valid until",
	VAT_LABEL: "VAT",
	VERIFICATION_CODE_MESSAGE:
		"Please enter the code to verify your phone number.",
	VERIFICATION_MESSAGE: "We have sent a verification code to",
	VERIFY: "Verify",
	VIEW: "View",
	VIEW_X: "View {0}",
	WARNING: "Warning",
	WELCOME_TO_BIMIFY: "Welcome to \n Bimify",
	SEND_OFFER_SUCCESS: "The offer {0} has been sent to:\n {1}",
	SEND_BUILDING_FILE_SUCCESS:
		"The files from building **{0}** has been sent to:\n {1}",
	WORKDAYS: "workdays",
	WORKHOURS: "workhours",
	WRONG_FILE_FORMAT:
		"Mixed file formats selected. Please choose one format for each level.",
	YES: "Yes",
	YOU: "You",
	YEAR: "Year",
	ZERO: "zero",
};
/**
 * Used to retrieve a localization string, with the option of variable
 * inputs.
 *
 * @param {string} key - localization key from the localization object
 * @param {array} inputs - used for custom value input within the array
 */
const localize = (key, inputs = []) => {
	if (!LOC.hasOwnProperty(key)) {
		return localize("KEY_NOT_FOUND", [key]);
	}

	let localization = LOC[key];
	if (!!inputs && inputs.length === 0) {
		return localization;
	}

	// Future alternative w/ interpolation, won't work w/
	// state in the current state

	// let interpolated = "";
	// let remaining = localization;

	// for (let index in inputs) {
	// 	const input = inputs[index];
	// 	const [before, after] = remaining.split(`{${index}}`);

	// 	interpolated = (
	// 		<>
	// 			{interpolated}
	// 			{before}
	// 			{input}
	// 		</>
	// 	);
	// 	remaining = after;
	// }

	// if (remaining.length > 0) {
	// 	interpolated = (
	// 		<>
	// 			{interpolated}
	// 			{remaining}
	// 		</>
	// 	);
	// 	return interpolated;
	// }

	inputs.map((input, index) => {
		return (localization = localization.replaceAll(`{${index}}`, input));
	});

	return localization;
};

export default localize;
