import administrationConstants from "../constants/administrationConstants";
import { api } from "../api";
import { getToken } from "../utils/local-storage.util";
import {
	setTempBuildingCategories,
	setTempBuildingComponents,
	setTempBuildingTypes,
	setTempDisciplines,
	setTempLODAdjustment,
	setTempLods,
	setTempBuildingCategoryAdjustment,
	setTempModelingPrices,
	setTempTermsAndCondition,
	setTempRepetitivenessAdjustment,
	setTempSpecificationParameters,
	setTempSpecificationCategories,
	setTempDXFServerComponents,
	setTempDXFServerComponentParameters,
	clearTempDXFServerComponents,
} from "./tempAdministrationAction";
import {
	setLodSpecification,
	setTempSpecificationContent,
} from "./lodSpecificationActions";
import tempAdministrationConstants from "../constants/tempAdministrationConstants";
import localize, { LocKeys } from "../constants/localizations";
import { statusConstants } from "../constants/constants";
import { lodSpecificationConstants } from "../constants/lodSpecificationConstants";

/**
 * Retrieves all Building Categories
 *
 * SUCCESS: Populates 'types' in the administration state.
 *
 * @returns array of Building Categories
 */
export const getBuildingCategories = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/building-type`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_BUILDING_TYPES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all Building types (childrens)
 *
 * SUCCESS: Populates 'buildingTypeChildrens' in the administration state.
 *
 * @param {boolean} temp - true/false (true - set temp. Building Types)
 * @returns array of Building types (childrens)
 */
export const getBuildingTypeChildrens = (temp = false) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/building-type-children`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!!temp) {
					dispatch(setTempBuildingTypes(res.data.result));
				}
				dispatch({
					type: administrationConstants.GET_BUILDING_TYPE_CHILDRENS,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all Building types (parents)
 *
 * SUCCESS: Populates 'buildingTypeParents' in the administration state.
 *
 * @param {boolean} temp - true/false (true - set temp. Building Categories)
 * @returns array of Building types (parents)
 */
export const getBuildingTypeParents = (temp = false) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/building-type-parents`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!!temp) {
					dispatch(setTempBuildingCategories(res.data.result));
				}

				dispatch({
					type: administrationConstants.GET_BUILDING_TYPE_PARENTS,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all Input formats
 *
 * SUCCESS: Populates 'inputFormat' in the administration state.
 *
 * @param {boolean} temp - true/false (true - set temp. Input formats in the  tempAdministration state)
 * @returns array of input formats
 */
export const getInputFormats = (temp = false) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/input-format`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!!temp) {
					dispatch({
						type: tempAdministrationConstants.GET_TEMP_INPUT_FORMATS,
						data: res.data.result,
					});
				}
				dispatch({
					type: administrationConstants.GET_DRAWING_REFERENCES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves Delivery Formats
 *
 * SUCCESS: Populates 'deliveryFormat' or 'deliveryFormats' in the administration state.
 *
 * @param {*} type - Retrieves only this delivery format type
 * @returns
 */
export const getDeliveryFormat = (type = null) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/delivery-format`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: {
				type: type,
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!type) {
					dispatch({
						type: administrationConstants.GET_DELIVERY_FORMATS,
						data: res.data.result,
					});
				} else {
					dispatch({
						type: administrationConstants.GET_DELIVERY_FORMAT,
						data: res.data.result,
					});
				}
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all LODs
 *
 * SUCCESS: Populates 'lods' in the administration state
 *
 * @param {boolean} temp - true/false (true - set temp. LODs in the  tempAdministration state)
 * @returns array of lods
 */
export const getLods = (temp = false) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/lod`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!!temp) {
					dispatch(setTempLods(res.data.result));
				}
				dispatch({
					type: administrationConstants.GET_LODS,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all disciplines
 *
 * SUCCESS: Populates 'disciplines' in the administration state
 *
 * @param {boolean} tempDiscipline  - true/false (true - set temp. disciplines object in the  tempAdministration state)
 * @param {boolean} tempLodSpecification  - true/false (true - set temp. lod specification object in the  lodSpecification state)
 * @param {boolean} tempLodContent  - true/false (true - set temp. lod content object in the  tempAdministration state)
 * @returns array of disciplines
 */
export const getDisciplines = ({
	tempDiscipline = false,
	tempLodSpecification = false,
	search = null,
	limit = null,
	page = null,
}) => {
	const pagePath = page ? `page=${page}&` : ``;
	const limitPath = limit ? `limit=${limit}&` : ``;
	const searchPath = search ? `search=${search}` : ``;

	const queryPath = `${pagePath}${limitPath}${searchPath}`;

	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/discipline?${queryPath}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!!tempDiscipline) {
					dispatch(setTempDisciplines(res.data.result));
				}
				if (!!tempLodSpecification) {
					dispatch(setLodSpecification(res.data.result));
				}

				dispatch({
					type: administrationConstants.GET_DISCIPLINES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all Exports
 *
 * SUCCESS: Populates 'exports' in the administration state
 *
 * @param {boolean} temp - true/false (true - set temp. others object in the  tempAdministration state)
 * @returns array of exports
 */
export const getExports = (temp = true) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/export`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!!temp) {
					dispatch({
						type: tempAdministrationConstants.SET_TEMP_OTHERS,
						data: res.data.result,
					});
				}
				dispatch({
					type: administrationConstants.GET_EXPORTS,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all Package categories
 *
 * SUCCESS: Populates 'packageCategories' in the administration state
 *
 * @returns array of packageCategories
 */
export const getPackageCategories = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/package-category`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_PACKAGE_CATEGORIES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all Building Components
 *
 * SUCCESS: Populates 'buildingComponents' in the administration state
 *
 * @param {boolean} temp - true/false (true - set temp. Building Components object in the  tempAdministration state)
 * @returns array of buildingComponents
 */
export const getBuildingComponents = (temp = false) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/discipline-component-category/get-all`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!!temp) {
					dispatch(setTempBuildingComponents(res.data.result));
				}
				dispatch({
					type: administrationConstants.GET_BUILDING_COMPONENTS,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all Discipline component-category input-format adjustment and time
 *
 * SUCCESS: Populates 'modelingPrices' or 'modelingTime' in the administration state
 *
 * @param {boolean} temp - true/false (true - set temp. Modeling Prices or Modeling Time object in the tempAdministration state)
 * @param {boolean} modelingTime - true/false (true - set Modeling Time object, false - set Modeling Prices object)
 * @returns array of modelingPrices/modelingTime
 */
export const getModeling = ({ temp = false }) => {
	return (dispatch, getState) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/discipline-component-category-input-format-adjustment`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				const state = getState();
				const disciplines = state
					.getIn(["administration", "disciplines"])
					?.toJS();

				if (!!temp) {
					dispatch(setTempModelingPrices(res.data.result, disciplines));
				}
				dispatch({
					type: administrationConstants.GET_MODELING_DATA,
					data: { disciplines, modelingPrices: res.data.result },
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all LOD Adjustments
 *
 * SUCCESS: Populates 'LODAdjustment' or 'lodTimeAdjustment' in the administration state
 *
 * @param {boolean} temp - true/false (true - set temp. LOD Adjustments object or LOD Time Adjustments object in the tempAdministration state)
 * @param {boolean} timeAdjustment - true/false (true - set LOD Time Adjustments object, false - set LOD Price Adjustments object)
 * @returns array of LODAdjustment
 */
export const getLODAdjustments = ({ temp = false }) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/lod-input-format-adjustment`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!!temp) {
					dispatch(setTempLODAdjustment(res.data.result));
				}

				dispatch({
					type: administrationConstants.GET_LOD_ADJUSTMENTS,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all Building Category Adjustments
 *
 * SUCCESS: Populates 'buildingCategoryAdjustments' or 'buildingCategoryTimeAdjustments' in the administration state
 *
 * @param {boolean} temp - true/false (true - set temp. Building Category Adjustments object or Building Category Time Adjustments object in the tempAdministration state)
 * @param {boolean} timeAdjustment - true/false (true - set Building Category Time Adjustments object, false - set Building Category Price Adjustments object)
 * @returns array of buildingCategoryAdjustments
 */
export const getBuildingCategoryAdjustments = ({ temp = false }) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/building-type-input-format-adjustment`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!!temp) {
					dispatch(setTempBuildingCategoryAdjustment(res.data.result));
				}

				dispatch({
					type: administrationConstants.GET_BUILDING_CATEGORY_ADJUSTMENTS,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all Repetitiveness Adjustments
 *
 * SUCCESS: Populates 'repetitivenessAdjustments' in the administration state
 *
 * @param {boolean} temp - true/false (true - set temp. Repetitiveness Adjustments object in the tempAdministration state)
 * @returns array of repetitivenessAdjustments
 */
export const getRepetitivenessAdjustments = (temp = false) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/input-format-repetiteveness-constant`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!!temp) {
					dispatch(setTempRepetitivenessAdjustment(res.data.result));
				}
				dispatch({
					type: administrationConstants.GET_REPETITIVENESS_ADJUSTMENTS,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all Settings data (default vat , min. offer price)
 * @returns object of settings
 */
export const getSettings = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/settings`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_SETTINGS,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves all Specification Categories or Parameters data
 * @returns object of Parameters/Categories
 */
export const getSpecificationCategoryParameters = (
	isForParameters = false,
	categoryId = null,
	excludeIds = [],
	parentName = null
) => {
	let queryPath = `?`;

	if (excludeIds.length > 0 || categoryId) {
		queryPath = categoryId ? `${queryPath}parentId=${categoryId}` : queryPath;

		queryPath =
			excludeIds.length > 0
				? `${queryPath}&excludeIds=${JSON.stringify(excludeIds)}`
				: queryPath;
	} else if (!!parentName) {
		queryPath = `${queryPath}parentName=${parentName}`;
	} else {
		queryPath = isForParameters
			? `${queryPath}children=${isForParameters}`
			: `${queryPath}parents=${!isForParameters}`;
	}

	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/parameter-category${queryPath}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (isForParameters) {
					dispatch(setTempSpecificationParameters(res.data.result));
					dispatch({
						type: administrationConstants.GET_SPECIFICATION_PARAMETERS,
						data: res.data.result,
					});
				} else {
					dispatch(setTempSpecificationCategories(res.data.result));
					dispatch({
						type: administrationConstants.GET_SPECIFICATION_CATEGORIES,
						data: res.data.result,
					});
				}
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * GET Field types for Specification Parameters
 * @returns
 */
export const getFieldTypes = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/input-field-type`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_FIELD_TYPES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * GET all content for single component category
 *
 * @param {number} contentId  - Unique content identifier
 * @returns
 */
export const getComponentCategoryContent = (
	contentId,
	isForSpeficiation = false
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/discipline-component-category/${contentId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (!!isForSpeficiation) {
					dispatch(setTempSpecificationContent(res.data.result));
				} else {
					dispatch({
						type: administrationConstants.GET_COMPONENT_CATEGORY_CONTENT,
						data: res.data.result,
					});
				}
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

export const getDisciplineComponentCategoryLodContent = (
	disciplineId,
	componentCategoryId,
	lod
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/discipline-component-category-lod?disciplineId=${disciplineId}&componentCategoryId=${componentCategoryId}&lodId=${lod.id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: lodSpecificationConstants.GET_DISCIPLINE_COMPONENT_CATEGORY_LOD_PARAM_CATEGORIES,
					data: res.data,
					lod,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Get number of component categories by component category id
 * @param {number} parameterCategoryId - Unique parameter category identifier
 * @returns
 */
export const getCountByParameterCategoryId = (parameterCategoryId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/discipline-component-category-parameter-category-count/${parameterCategoryId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_NUMBER_OF_COMPONENT_CATEGORIES_BY_PARAMETER_CATEGORY_ID,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * -----------------DEPRECATED-----------------
 *
 * Retrieves all delivery format manuals
 *
 * SUCCESS:
 * 		1. status === CUSTOMISATION - Populates 'customisationManuals' in the administration and tempAdministration state.
 * 		2. status === QA - Populates 'qaManuals' in the administration and tempAdministration state.
 *
 * @param {*} statusId - Unique job status identifier
 * @param {*} deliveryFormatId - Unique delivery format identifier
 * @param {*} status - type of status
 * @param {*} cb - callback fucntion
 *
 * @returns array of manuals
 */
export const getAllJobStatusDeliveryFormatManual = ({
	statusId = null,
	deliveryFormatId = null,
	status,
	search = null,
	page = null,
	limit = null,
	cb = () => {},
}) => {
	const statusIdPath = statusId ? `statusId=${statusId}&` : ``;
	const deliveryFormatIdPath = deliveryFormatId
		? `deliveryFormatId=${deliveryFormatId}&`
		: ``;
	const pagePath = page ? `page=${page}&` : ``;
	const limitPath = limit ? `limit=${limit}&` : ``;
	const searchPath = search ? `search=${search}` : ``;

	const queryPath = `${statusIdPath}${deliveryFormatIdPath}${pagePath}${limitPath}${searchPath}`;

	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/job-status-delivery-format-manual?${queryPath}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (status === statusConstants.CUSTOMISATION) {
					dispatch({
						type: tempAdministrationConstants.SET_TEMP_CUSTOMISATION_MANUAL,
						data: res.data.result.result,
					});
					dispatch({
						type: administrationConstants.GET_CUSTOMISATION_MANUAL,
						data: res.data.result.result,
					});
				}

				if (status === statusConstants.QA) {
					dispatch({
						type: tempAdministrationConstants.SET_TEMP_QA_MANUAL,
						data: res.data.result.result,
					});
					dispatch({
						type: administrationConstants.GET_QA_MANUAL,
						data: res.data.result.result,
					});
				}

				cb();
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * -----------------DEPRECATED-----------------
 *
 * Retrieves all input format manuals
 *
 * SUCCESS: - Populates 'normalisationManuals' in the administration and tempAdministration state.
 *
 * @param {*} inputFormatId - Unique input format identifier
 * @param {*} cb - callback fucntion
 *
 * @returns array of manuals
 */
export const getAllJobStatusInputFormatManual = ({
	inputFormatId = null,
	cb = () => {},
	search = null,
	page = null,
	limit = null,
}) => {
	const inputFormatPath = inputFormatId
		? `inputFormatId=${inputFormatId}&`
		: ``;
	const pagePath = page ? `page=${page}&` : ``;
	const limitPath = limit ? `limit=${limit}&` : ``;
	const searchPath = search ? `search=${search}` : ``;

	const queryPath = `${inputFormatPath}${pagePath}${limitPath}${searchPath}`;

	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/job-status-input-format-manual?${queryPath}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: tempAdministrationConstants.SET_TEMP_NORMALISATION_MANUAL,
					data: res.data.result,
				});
				dispatch({
					type: administrationConstants.GET_NORMALISATION_MANUAL,
					data: res.data.result,
				});
				cb();
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * GET all customisation time by input format
 *
 * @param {number} inputFormatId - Unique input format identifier
 * @returns
 */
export const getCustomisationTimeByInputFormat = (inputFormatId) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/discipline-component-category-input-format-lod-adjustment?inputFormatId=${inputFormatId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_CUSTOMISATION_TIME_BY_INPUT_FORMAT,
					data: { result: res.data.result, inputFormatId },
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};
//------------------------------------UPDATE ENDPOINTS------------------------------------------

/**
 * UPDATE Modeling Data
 *
 * @param {object} data - Contains all form data in a key-value pair
 * @param {boolean} data - true/false
 * @param {Function} cb - Callback function
 * @returns
 */
export const updateModelingData = (data, isTimeView = false, cb = () => {}) => {
	const message = isTimeView
		? localize(LocKeys.MODELING_TIME)
		: localize(LocKeys.MODELING_PRICES);

	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/discipline-component-category-input-format-adjustment`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(localize(LocKeys.ITEM_UPDATED, [message]))
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [message])
					)
				);
			}
		);
	};
};

/**
 * UPDATE Settings
 *
 * @param {*} data - Contains all settings form data in a key-value pair
 * @param {*} cb - Callback function
 * @param {boolean} isPriceEdit - true/false
 * @returns
 */
export const updateSettings = (data, cb = () => {}, isPriceEdit = false) => {
	const message = isPriceEdit
		? localize(LocKeys.MIN_OFFER_PRICE)
		: localize(LocKeys.DEFAULT_VAT);

	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/settings`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: data,
		};
		return api(requestOptions).then(
			(_) => {
				cb();
				dispatch(
					administrationActionSuccess(localize(LocKeys.ITEM_UPDATED, [message]))
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [message])
					)
				);
			}
		);
	};
};

/**
 * UPDATE Building Category Adjustments
 *
 * @param {*} data - Contains all form data in a key-value pair
 * @param {*} cb - Callback function
 * @returns
 */
export const updateBuildingCategoryAdjustments = (
	data,
	isTimeView = false,
	cb = () => {}
) => {
	const message = isTimeView
		? localize(LocKeys.BUILDING_CATEGORY_TIME_ADJUSTMENTS)
		: localize(LocKeys.BUILDING_CATEGORY_ADJUSTMENTS);

	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/building-type-input-format-adjustment`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(localize(LocKeys.ITEM_UPDATED, [message]))
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [message])
					)
				);
			}
		);
	};
};

/**
 * UPDATE Repetitiveness Adjustments
 *
 * @param {*} data - Contains all form data in a key-value pair
 * @param {*} cb - Callback function
 * @returns
 */
export const updateRepetitivenessAdjustments = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/input-format-repetiteveness-constant`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [
							localize(LocKeys.REPETITIVENESS_ADJUSTMENTS),
						])
					)
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [
							localize(LocKeys.REPETITIVENESS_ADJUSTMENTS),
						])
					)
				);
			}
		);
	};
};

/**
 * UPDATE LOD Adjustments
 *
 * @param {*} data - Contains all form data in a key-value pair
 * @param {*} cb - Callback function
 * @returns
 */
export const updateLODAdjustments = (
	data,
	isTimeView = false,
	cb = () => {}
) => {
	const message = isTimeView
		? localize(LocKeys.LOD_TIME_ADJUSTMENTS)
		: localize(LocKeys.LOD_ADJUSTMENTS);

	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/lod-input-format-adjustment`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(localize(LocKeys.ITEM_UPDATED, [message]))
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [message])
					)
				);
			}
		);
	};
};

/**
 * UPDATE Disciplines
 *
 * @param {*} data - Contains all form data in a key-value pair
 * @param {*} cb - Callback function
 * @returns
 */
export const updateDisciplines = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/discipline`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [
							localize(LocKeys.ENGINEERING_DISCIPLINES),
						])
					)
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [
							localize(LocKeys.ENGINEERING_DISCIPLINES),
						])
					)
				);
			}
		);
	};
};

/**
 * UPDATE LODs
 *
 * @param {*} data - Contains all form data in a key-value pair
 * @param {*} cb - Callback function
 * @returns
 */
export const updateLods = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/lod`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.LOD)])
					)
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.LOD)])
					)
				);
			}
		);
	};
};

/**
 * UPDATE Building Types (childrens)
 *
 * @param {*} data - Contains all form data in a key-value pair
 * @param {*} cb - Callback function
 * @returns
 */
export const updateBuildingTypeChildrens = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/building-type-children`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.BUILDING_TYPES)])
					)
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [
							localize(LocKeys.BUILDING_TYPES),
						])
					)
				);
			}
		);
	};
};

/**
 * UPDATE Building Types (parents)
 *
 * @param {*} data - Contains all form data in a key-value pair
 * @param {*} cb - Callback function
 * @returns
 */
export const updateBuildingTypeParents = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/building-type-parents`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [
							localize(LocKeys.BUILDING_CATEGORIES),
						])
					)
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [
							localize(LocKeys.BUILDING_CATEGORIES),
						])
					)
				);
			}
		);
	};
};

/**
 * UPDATE Building Components
 *
 * @param {*} data - Contains all form data in a key-value pair
 * @param {*} cb - Callback function
 * @returns
 */
export const updateBuildingComponents = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/discipline-component-category`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [
							localize(LocKeys.BUILDING_COMPONENTS),
						])
					)
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [
							localize(LocKeys.BUILDING_COMPONENTS),
						])
					)
				);
			}
		);
	};
};

/**
 * UPDATE Other items
 *
 * @param {*} data - Contains all form data in a key-value pair
 * @param {*} cb - Callback function
 * @returns
 */
export const updateOtherItems = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/export`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.EXPORTS)])
					)
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.EXPORTS)])
					)
				);
			}
		);
	};
};

/**
 * UPDATE LOD Content - summary and images
 *
 * @param {*} data - Contains all form data in a key-value pair
 * @param {*} cb - Callback function
 * @returns
 */
export const updateLODContent = (data, contentId, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/discipline-component-category-lods-description`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(getComponentCategoryContent(contentId));
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.LOD_CONTENT)])
					)
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [
							localize(LocKeys.LOD_CONTENT),
						])
					)
				);
			}
		);
	};
};

/**
 * UPDATE Specification Categories or Parameters
 *
 * @param {boolean} isForParameters - true/false (true - update parameters, false - update categories)
 * @param {object} data - Contains all form data in a key-value pair
 * @param {Function} cb - Callback function
 *
 * @returns getSpecificationCategoryParameters
 */
export const updateSpecificationCategoryParameters = (
	isForParameters = false,
	data,
	cb = () => {},
	cbError = () => {}
) => {
	const message = isForParameters
		? localize(LocKeys.PARAMETER)
		: localize(LocKeys.CATEGORIES);

	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/parameter-category`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getSpecificationCategoryParameters(isForParameters));
				if (!isForParameters) {
					dispatch(getSpecificationCategoryParameters(true));
				}

				dispatch(
					administrationActionSuccess(localize(LocKeys.ITEM_UPDATED, [message]))
				);
				cb();
			},
			(err) => {
				const result = err.response.data?.result;
				cbError(result);
			}
		);
	};
};

/**
 * UPDATE Parametar Categories - added parameters to category
 *
 * @param {number} contentId - unique content identifier
 * @param {object} data - Contains all form data in a key-value pair
 * @param {Function} cb - callback function
 * @returns
 */
export const updateComponentCategoryContentParameterCategory = (
	contentId,
	data,
	cb = () => {}
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/discipline-component-category-lod-desc-parameter-category`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getComponentCategoryContent(contentId));
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [
							localize(LocKeys.CATEGORY_PARAMETERS),
						])
					)
				);
				cb();
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * GET DXF Model Properties (Min,Max...)
 */
export const getDXFModelProperties = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/dxf-model-prop`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_DXF_MODEL_PROPERTIES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

//------------------------------------------------------------------------------

/**
 * -----------------DEPRECATED-----------------
 *
 * UPDATE input format manuals
 *
 * SUCCESS: - Callback fucntion and Snackbar message
 *
 * @param {*} data - Contains all form data in a key-value pair
 * @param {*} cb - callback fucntion
 *
 */
export const updateJobStatusInputFormatManual = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/job-status-input-format-manual`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [
							localize(LocKeys.NORMALISATION_MANUALS),
						])
					)
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [
							localize(LocKeys.NORMALISATION_MANUALS),
						])
					)
				);
			}
		);
	};
};

/**
 * -----------------DEPRECATED-----------------
 *
 * UPDATE delivery format manuals
 *
 * SUCCESS: - Callback fucntion and Snackbar message
 *
 * @param {*} data -  Contains all form data in a key-value pair
 * @param {*} status - type of status
 * @param {*} cb - callback fucntion
 *
 */
export const updateJobStatusDeliveryFormatManual = (
	data,
	status,
	cb = () => {}
) => {
	const message =
		status === statusConstants.QA
			? localize(LocKeys.QA_MANUALS)
			: localize(LocKeys.CUSTOMISATION_MANUALS);

	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/job-status-delivery-format-manual`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(localize(LocKeys.ITEM_UPDATED, [message]))
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [message])
					)
				);
			}
		);
	};
};
/**
 * UPDATE Customisation Time By Input Format
 *
 * @param {*} data - Contains all form data in a key-value pair
 * @param {number} inputFormatId - Unique input format identifier
 * @param {Function} cb - Callback function
 * @returns
 */
export const updateCustomisationTimeByInputFormat = (
	data,
	inputFormatId,
	cb = () => {}
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/discipline-component-category-input-format-lod-adjustment`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [
							localize(LocKeys.CUSTOMISATION_TIME),
						])
					)
				);
				dispatch(getCustomisationTimeByInputFormat(inputFormatId));
				cb();
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [
							localize(LocKeys.CUSTOMISATION_TIME),
						])
					)
				);
			}
		);
	};
};

//----------------------------DELETE ENDPOINTS----------------------

/**
 * DELETE Building Type
 *
 * @param {*} id - Unique building Type identifier
 * @param {*} cb - Callback function
 * @param {boolean} isParent - true/false
 * @returns
 */
export const deleteBuildingType = (id, cb = () => {}, isParent = false) => {
	const message = isParent
		? localize(LocKeys.BUILDING_CATEGORY)
		: localize(LocKeys.BUILDING_TYPE);

	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/building-type/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				cb();

				dispatch(
					administrationActionSuccess(localize(LocKeys.ITEM_DELETED, [message]))
				);
			},
			(err) => {
				dispatch(
					administrationModalActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [message])
					)
				);
			}
		);
	};
};

/**
 * DELETE Discipline
 *
 * @param {*} id - Unique Discipline identifier
 * @param {*} cb - Callback function
 * @returns
 */
export const deleteDiscipline = (id, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/discipline/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_DELETED, [
							localize(LocKeys.ENGINEERING_DISCIPLINES),
						])
					)
				);
			},
			(err) => {
				dispatch(
					administrationModalActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [
							localize(LocKeys.ENGINEERING_DISCIPLINES),
						])
					)
				);
			}
		);
	};
};

/**
 * DELETE LOD
 *
 * @param {*} id - Unique lod identifier
 * @param {*} cb - Callback function
 * @returns
 */
export const deleteLod = (id, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/lod/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.LOD)])
					)
				);
			},
			(err) => {
				dispatch(
					administrationModalActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [localize(LocKeys.LOD)])
					)
				);
			}
		);
	};
};

/**
 * DELETE Building Component
 *
 * @param {*} id - Unique Building Component identifier
 * @param {*} cb - Callback function
 * @returns
 */
export const deleteBuildingComponent = (id, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/discipline-component-category/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_DELETED, [
							localize(LocKeys.BUILDING_COMPONENTS),
						])
					)
				);
			},
			(err) => {
				dispatch(
					administrationModalActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [
							localize(LocKeys.BUILDING_COMPONENTS),
						])
					)
				);
			}
		);
	};
};

/**
 * DELETE Specification Category or Parameter
 *
 * @param {boolean} isForParameters - true/false (true - delete parameters, false - delete categories)
 * @param {number} id - Unique identifier
 * @param {Function} cb - Callback function
 * @returns
 */
export const deleteSpecificationCategoryParameters = (
	isForParameters = false,
	id,
	cb = () => {}
) => {
	const message = isForParameters
		? localize(LocKeys.PARAMETER)
		: localize(LocKeys.CATEGORIES);

	return (dispatch) => {
		const requestOptions = {
			method: "PATCH",
			url: `/dashboard/parameter-category/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(localize(LocKeys.ITEM_DELETED, [message]))
				);
			},
			(err) => {
				dispatch(
					administrationModalActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [message])
					)
				);
			}
		);
	};
};

/**
 * DELETE other item
 *
 * @param {*} id - Unique other item identifier
 * @param {*} cb - Callback function
 * @returns
 */
export const deleteOtherItem = (id, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/export/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(localize(LocKeys.ITEM_DELETED, [localize(LocKeys.EXPORTS)]));
			},
			(err) => {
				dispatch(
					administrationModalActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [localize(LocKeys.EXPORT)])
					)
				);
			}
		);
	};
};

/**
 * Delete Parameter
 *
 * @param {Array} deleteIds - array of ids to delete
 * @param {Function} cb - callback function
 * @returns
 */
export const deleteParameter = (deleteIds, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/discipline-component-category-lod-desc-parameter-category`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { deleteIds },
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.PARAMETER)])
					)
				);
			},
			(err) => {
				dispatch(
					administrationModalActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [localize(LocKeys.PARAMETER)])
					)
				);
			}
		);
	};
};

/**
 * -------------------------TERMS AND CONDITIONS-------------------------
 */

/**
 * GET Terms And Condition
 *
 * @returns
 */
export const getTermsAndCondition = ({
	cb = () => {},
	page = null,
	limit = null,
	search = null,
}) => {
	const pagePath = page ? `page=${page}&` : ``;
	const limitPath = limit ? `limit=${limit}&` : ``;
	const searchPath = search ? `search=${search}` : ``;

	const queryPath = `${pagePath}${limitPath}${searchPath}`;
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/terms-and-conditions?${queryPath}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(setTempTermsAndCondition(res.data.result?.details || ""));

				dispatch({
					type: administrationConstants.GET_TERMS_AND_CONDITION,
					data: res.data.result,
				});
				cb();
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * UPDATE Terms And Condition
 *
 * @returns
 */
export const updateTermsAndCondition = (id, data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/terms-and-conditions/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.UPDATE_TERMS_AND_CONDITION,
					data: res.data.result,
				});
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [
							localize(LocKeys.TERMS_AND_CONDITIONS),
						])
					)
				);
				cb();
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * GET families endpoint
 * @returns
 */

export const getFamiliesByComponent = (id = null) => {
	return (dispatch) => {
		const query = id ? `&disciplineComponentCategoryId=${id}` : "";
		const requestOptions = {
			method: "GET",
			url: `/family?usePagination=false${query}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_ADMIN_FAMILIES,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.result));
			}
		);
	};
};

//------------------------------------EXPORTS ADJUSTMENT------------------------------------------

/**
 * GET exports adjustment (time,velocity,percentage) by input format
 *
 * @param {number} inputFormatId - Unique input format identifier
 * @returns
 */
export const getExportsAdjustment = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/export-input-format-adjustment`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_EXPORTS_ADJUSTMENT_BY_INPUT_FORMAT,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * UPDATE exports adjustment (time,velocity,percentage) by input format
 *
 * @param {object} data - Contains all form data in a key-value pair
 * @param {number} inputFormatId - Unique input format identifier
 * @param {Function} cb - Callback function
 * @returns
 */
export const updateExportsAdjustment = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/export-input-format-adjustment`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getExportsAdjustment());
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.EXPORTS)])
					)
				);
				cb();
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.EXPORTS)])
					)
				);
			}
		);
	};
};

//------------------------------------SERVICES ADJUSTMENT------------------------------------------

/**
 * GET services adjustment (time, velocity, percentage) by input format
 *
 * @returns
 */
export const getServicesAdjustment = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/manual-input-format-adjustment`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_SERVICES_ADJUSTMENT_INPUT_FORMAT,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * UPDATE services adjustment (time, velocity, percentage) by input format
 *
 * @param {object} data - Contains all form data in a key-value pair
 * @param {Function} cb - Callback function
 * @returns
 */
export const updateServicesAdjustment = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/manual-input-format-adjustment`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getServicesAdjustment());
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.SERVICES)])
					)
				);
				cb();
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.SERVICES)])
					)
				);
			}
		);
	};
};

/**
 * GET Symbol Functions endpoint
 * @returns
 */
export const getSymbolFunctions = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/component-category-function`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_SYMBOL_FUNCTIONS,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.result));
			}
		);
	};
};

//------------------------------------DXF server settings------------------------------------

/**
 * Get DXF Server Components per discipline
 *
 * @param {number} disciplineId - Unique discipline identifier
 *
 */
export const getDXFServerComponents = ({ disciplineId }) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/discipline-component-category-dxf-prop?disciplineId=${disciplineId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_DXF_SERVER_COMPONENTS,
					data: { disciplineId, result: res.data?.result },
				});
				dispatch(
					setTempDXFServerComponents({ disciplineId, result: res.data.result })
				);
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * Get all Discipline Components
 *
 * @param {number} disciplineId - Unique discipline identifier
 * @returns
 */
export const getAllDisciplineComponents = ({ disciplineId }) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/component-category?disciplineId=${disciplineId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_ALL_DISCIPLINE_COMPONENTS,
					data: { disciplineId, result: res.data?.result },
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * UPDATE DXF Server Components
 *
 * @param {number} disciplineId - Unique discipline identifier
 * @param {object} data - Contains all form data in a key-value pair
 * @param {Function} cb - Callback function
 *
 */
export const updateDXFServerComponents = ({
	disciplineId,
	data,
	cb = () => {},
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/dashboard/discipline-component-category-parameter-category`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getDXFServerComponents({ disciplineId }));
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.COMPONENTS)])
					)
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.COMPONENTS)])
					)
				);
			}
		);
	};
};

/**
 * GET DXF Server Component Parameters
 *
 * @param {number} disciplineId - Unique discipline identifier
 * @param {number} componentId - Unique component identifier
 * @param {Function} cb - Callback function
 *
 * @returns - Set DXF Server Component Parameters object in the state
 */
export const getDXFServerComponentParameters = ({
	disciplineId,
	componentId,
	cb = () => {},
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/dashboard/discipline-component-category-parameter-category/${componentId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_DXF_SERVER_COMPONENT_PARAMETERS,
					data: { disciplineId, componentId, result: res.data?.result },
				});
				dispatch(
					setTempDXFServerComponentParameters({
						disciplineId,
						componentId,
						result: res.data.result,
					})
				);
				cb();
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * UPDATE DXF Server Component Parameters
 *
 * @param {number} disciplineId - Unique discipline identifier
 * @param {number} componentId - Unique component identifier
 * @param {object} data - Contains all form data in a key-value pair
 * @param {Function} cb - Callback function
 *
 * @returns - Update DXF Server Component Parameters and get updated data
 */
export const updateDXFServerComponentParameters = ({
	disciplineId,
	componentId,
	data,
	cb = () => {},
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/dashboard/discipline-component-category-parameter-category/${componentId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					getDXFServerComponentParameters({
						disciplineId,
						componentId,
						cb: () => {
							cb();
							dispatch(
								administrationActionSuccess(
									localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.PARAMETERS)])
								)
							);
						},
					})
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.PARAMETERS)])
					)
				);
			}
		);
	};
};

/**
 * DELETE DXF Server Component Parameters
 * @param {number} id - Unique parameter identifier
 * @param {Function} cb - Callback function
 *
 */
export const deleteDXFServerComponentParameters = (id, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/dashboard/discipline-component-category-parameter-category/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					administrationActionSuccess(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.PARAMETER)])
					)
				);
			},
			(err) => {
				dispatch(
					administrationActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [localize(LocKeys.PARAMETER)])
					)
				);
			}
		);
	};
};

//------------------------------------COUNTRY------------------------------------------

/**
 * Get all countries endpoint
 * @returns
 */
export const getAllCountry = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/country`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: administrationConstants.GET_ALL_COUNTRY,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(administrationActionFailure(err.response.data.message));
			}
		);
	};
};

//------------------------------------STATE ACTIONS------------------------------------------

/**
 * CLEAR DXF Server Components object and TEMP DXF Server Components object in the state
 */
export const clearDXFServerComponents = () => {
	return (dispatch) => {
		dispatch({
			type: administrationConstants.CLEAR_DXF_SERVER_COMPONENTS,
		});
		dispatch(clearTempDXFServerComponents());
	};
};

/**
 * Clear all manuals text editor content
 * @returns
 */
export const clearManuals = () => {
	return (dispatch) => {
		dispatch({
			type: administrationConstants.CLEAR_MANUALS,
		});
	};
};

/**
 * Clear snackbar request state
 * @returns
 */
export const clearAdministrationRequestState = () => {
	return {
		type: administrationConstants.CLEAR_ADMINISTRATION_REQUEST_STATE,
	};
};

/**
 * Clear modal request state
 * @returns
 */
export const clearAdministrationModalRequestState = () => {
	return {
		type: administrationConstants.CLEAR_ADMINISTRATION_MODAL_REQUEST_STATE,
	};
};

/**
 * Snackbar Action Failure
 * @param {string} message
 * @returns
 */
const administrationActionFailure = (message) => {
	return {
		type: administrationConstants.ADMINISTRATION_ACTION_FAILURE,
		data: message,
	};
};

/**
 * Modal Action Failure
 * @param {string} message
 * @returns
 */
const administrationModalActionFailure = (message) => {
	return {
		type: administrationConstants.ADMINISTRATION_MODAL_ACTION_FAILURE,
		data: message,
	};
};

/**
 * Snackbar Action Success
 * @param {string} message
 * @returns
 */
const administrationActionSuccess = (message) => {
	return {
		type: administrationConstants.ADMINISTRATION_ACTION_SUCCESS,
		data: message,
	};
};

//------------------------------------TEMPORARY ACTIONS------------------------------------------

export const addNewTempParameter = (categoryId, parameters) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_NEW_TEMP_PARAMETER,
			data: { categoryId, parameters },
		});
	};
};

export const removeTempParameter = (parameterId, categoryId) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_TEMP_PARAMETER,
			data: { parameterId, categoryId },
		});
	};
};

export const resetLodParameterCategories = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.RESET_LOD_PARAMETER_CATEGORIES,
		});
	};
};

export const updateLodParameterCategories = ({
	fieldKey,
	value,
	lodId,
	parameterId,
	categoryId,
}) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_LOD_PARAMETER_CATEGORIES,
			data: { fieldKey, value, lodId, parameterId, categoryId },
		});
	};
};

export const sortLodParameterCategories = (dragId, overId, categoryId) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SORT_LOD_PARAMETER_CATEGORIES,
			data: { dragId, overId, categoryId },
		});
	};
};

export const setContentImageTempFile = ({
	publicUrl,
	fileId,
	id,
	threeDFile,
	twoDFile,
}) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_CONTENT_IMAGE_TEMP_FILE,
			data: { publicUrl, id, fileId, threeDFile, twoDFile },
		});
	};
};

export const deleteContentTempImage = ({ lodId, threeDFile, twoDFile }) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.DELETE_CONTENT_IMAGE_TEMP_FILE,
			data: { lodId, threeDFile, twoDFile },
		});
	};
};

export const resetTempLodContent = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.RESET_TEMP_LOD_CONTENT,
		});
	};
};

export const resetLodContent = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.RESET_LOD_CONTENT,
		});
	};
};

export const clearComponentCategoryContent = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.CLEAR_COMPONENT_CATEGORY_CONTENT,
		});
	};
};

export const updateTempLodContentSummary = (lodId, summary) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_LOD_CONTENT_SUMMARY,
			data: { lodId, summary },
		});
	};
};
